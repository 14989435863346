<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title
        style="display: flex;
        align-items: center;
        justify-content: space-between;"
      >
        <span>
          Cuadrante de guardias - {{ viewSelected ? 'Personales' : 'Medios' }}
        </span>
        <v-switch v-model="viewSelected" hide-details inset class="switch-button" />
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0 ma-0">
      <v-container class="pa-1 ma-0" fluid>
        <vx-guardias-medio v-if="!viewSelected" />
        <vx-guardias-personal v-if="viewSelected" />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import GuardiasPorMedio from './GuardiasPorMedio.vue'
import GuardiasPorPersonal from './GuardiasPorPersonal.vue'

export default {
  components: {
    'vx-guardias-medio': GuardiasPorMedio,
    'vx-guardias-personal': GuardiasPorPersonal
  },
  data () {
    return {
      viewSelected: true
    }
  }
}
</script>

<style scoped>
.switch-button {
  margin: 0 16px;
}
</style>
