<template>
  <v-fade-transition>
    <div id="maptip_item" class="maptip_item elevation-6" v-show="showPopup">
      <div v-show="!isOnTop" class="top-arrow" />
      <v-dialog v-model="showDialogTimes" max-width="300px">
        <v-card>
          <v-card-title class="headline">Seleccione tiempo</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-for="time in [15, 30, 45, 60]"
                    :key="time"
                    v-model="selectedTimes"
                    :label="`${time} minutos`"
                    :value="time"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="red" text @click="showDialogTimes = false">Cancelar</v-btn>
            <v-btn color="primary" text @click="confirmIsochrone">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card elevation="6">
        <v-toolbar color="primaryLight" dark height="32px">
          <v-toolbar-title class="text-body-2">{{ properties.titulo }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="openDialogTimes" v-if="showButtonTimeSelectorIsochrone">
            <v-icon>{{ selectedTimes.length !== 0 ? 'mdi-close' : 'mdi-map-clock' }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-1 pb-0 px-0">
          <v-simple-table dense style="max-height: 20vh; overflow: auto">
            <template #default>
              <tbody>
                <tr v-for="attribute in attributes" :key="attribute.name">
                  <td class="black--text">{{ attribute.name }}</td>
                  <td>{{ attribute.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions v-show="buttons.length > 0">
          <v-container class="pa-0">
            <v-row dense align="center" justify="center">
              <v-col v-for="button in buttons" :key="button.id" v-show="button.visible" align="center">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn fab icon small v-on="on" @click="clickBtn(button)" v-show="button.visible">
                      <v-icon>{{ button.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ button.label }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>

      <div v-show="isOnTop" class="bottom-arrow" />
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    showPopup: Boolean,
    datosPopup: Object,
    showButtonTimeSelectorIsochrone: Boolean
  },

  data: () => ({
    properties: {},
    attributes: [],
    buttons: [],

    isochroneTime: 15,

    showDialogTimes: false, // TODO: CAMBIAR NOMBRE
    selectedTimes: [],

    isOnTop: false
  }),

  computed: {
  },

  watch: {
    datosPopup () {
      if (this.datosPopup) {
        // console.log(this.datosPopup)
        this.properties = this.datosPopup.properties

        this.attributes = this.datosPopup.attributes.filter(x => x.value && x.value !== '')
        this.buttons = this.datosPopup.buttons

        // this.moveTooltip()
      }
    },
    showPopup (val, oldVal) {
      if (val !== oldVal && val) {
        this.moveTooltip()
      }
    }
  },

  methods: {
    moveTooltip () {
      let doc = document.getElementById('maptip_item')

      let x = this.properties.x
      let y = this.properties.y

      // Get screen size
      // let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      // let midWidth = screenWidth / 2
      let midHeight = screenHeight / 2

      // let tamHeight = doc.offsetHeight
      let tamWidth = 300 // doc.offsetWidth

      // Place popup on top / under the graphic depending on the position
      let isTop = y < midHeight
      this.isOnTop = !isTop
      // let isLeft = x < midWidth

      if (isTop) {
        doc.style.top = (y + 25) + 'px'
        doc.style.bottom = ''
      } else {
        doc.style.top = ''
        doc.style.bottom = (screenHeight - y - 40) + 'px'
      }

      /* let newLeft = x + (tamWidth / 2)
      if (isLeft) {
        newLeft = x - (tamWidth / 2)
      } */

      // doc.style.top = newTop + 'px'
      doc.style.left = x - (tamWidth / 2) + 'px'
    },
    openDialogTimes () {
      if (this.selectedTimes.length) {
        this.selectedTimes = []

        this.$emit('clickBtn', 'hideRoutingIsocrona')
      } else {
        this.$emit('clickBtn', 'showRoutingIsocrona')

        this.showDialogTimes = true
      }
    },
    confirmIsochrone () {
      this.showDialogTimes = false
      this.$emit('isochroneClick', this.selectedTimes)
    },

    clickBtn (btn) {
      this.$emit('clickBtn', btn.id)
    }
  }
}
</script>

<style scoped>
  .maptip_item {
    width: 300px;
    height: auto;
    position: absolute !important;
  }

  .top-arrow:before {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    top: -25px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-bottom: 25px solid var(--v-primaryLight-base);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  .bottom-arrow:after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
</style>
