import api from '@/api'
import constants from '../../helpers/constants'
import * as ArcGIS from '../../helpers/ArcGIS'

import DateService from '../../helpers/DateService'
import UUIDService from '../../helpers/UUIDService'
import * as Utils from '../../helpers/Utils'

import Vue from 'vue'

let Q = require('q')

const state = {
  emergencias: [],

  incendio: [],

  tiposIncendioNivel: [],

  emergenciasTipo: [],

  emergenciaEstadoTipo: []
}

const getters = {
  emergencias: (state) => state.emergencias,

  emergenciasTipo: (state) => state.emergenciasTipo,

  emergenciaEstadoTipo: (state) => state.emergenciaEstadoTipo,

  emergenciasFiltradas: (state) => {
    return state.emergencias
      .filter((e) => !e.OCULTO && !e.ELIMINADO)
      .sort((a, b) => {
        let aFecha = new Date(a.FECHA_CREACION)
        let bFecha = new Date(b.FECHA_CREACION)
        return aFecha > bFecha ? -1 : aFecha < bFecha ? 1 : 0
      })
  },

  tiposIncendioNivel: (state) => state.tiposIncendioNivel,

  getEmergenciaByID: (state) => (idEmergencia) => {
    let emergencia = null

    if (idEmergencia) {
      emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(idEmergencia))
    }
    return emergencia
  },

  getEmergenciasFiltradasByID: (state) => (idEmergencia) => {
    let emergencia = null

    if (idEmergencia) {
      emergencia = state.emergenciasFiltradas.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(idEmergencia))
    }
    return emergencia
  },

  getIncendioByID: (state) => (idIncendio) => {
    let incendio = null

    if (idIncendio) {
      incendio = state.emergencias.find(x => x.INCENDIO.ID_INCENDIO.equalsIgnoreCase(idIncendio))
    }
    return incendio
  },

  isEmergenciaFinalizada: (state) => (idEmergencia) => {
    let estadoFinalizado = null

    if (idEmergencia) {
      let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(idEmergencia))

      if (emergencia && emergencia.ESTADOS) {
        estadoFinalizado = emergencia.ESTADOS.find(x => x.EMERGENCIA_ESTADO_TIPO.VALUE === 4)
      }
    }

    return !!estadoFinalizado
  },

  getEmergenciaEstadoTipoByValue: (state) => (idEmergenciaTipo, value) => {
    if (!state.emergenciaEstadoTipo || state.emergenciaEstadoTipo.length === 0) {
      return null
    }
    return state.emergenciaEstadoTipo.find(
      x => x.ID_EMERGENCIA_TIPO === idEmergenciaTipo && x.VALUE === value
    )
  },

  getEstadoTipoById: (state) => (idEstadoTipo) => {
    let emergenciaEstadoTipo = null

    if (idEstadoTipo) {
      emergenciaEstadoTipo = state.tiposEmergenciaEstado.find(x => x.ID_EMERGENCIA_ESTADO_TIPO === idEstadoTipo)
    }
    return emergenciaEstadoTipo
  }
}

const mutations = {
  SOCKET_updateEmergencia (state, data) {
    let emergenciaIndex = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergenciaIndex !== -1) {
      let emergencia = state.emergencias[emergenciaIndex]

      emergencia = {
        ...emergencia,
        OCULTO: data.OCULTO,
        ID_TIPO_EMERGENCIA: data.ID_TIPO_EMERGENCIA,
        ELIMINADO: data.ELIMINADO,
        LATITUD: data.LATITUD,
        LONGITUD: data.LONGITUD,
        FECHA_CREACION: data.FECHA_CREACION,
        COD_PROV: data.COD_PROV,
        COD_CCAA: data.COD_CCAA,
        MUNICIPIO: data.MUNICIPIO,
        LOCALIDAD: data.LOCALIDAD,
        OBSERVACIONES: data.OBSERVACIONES,
        FECHASTAMP: data.FECHASTAMP,
        USUARIOSTAMP: data.USUARIOSTAMP
      }

      if (emergencia.INCENDIO && data.INCENDIO) {
        emergencia.INCENDIO.NOMBRE = data.INCENDIO.NOMBRE
        emergencia.INCENDIO.LOCALIDAD = data.INCENDIO.LOCALIDAD
        emergencia.INCENDIO.LATITUD = data.INCENDIO.LATITUD
        emergencia.INCENDIO.LONGITUD = data.INCENDIO.LONGITUD
        emergencia.INCENDIO.ID_TIPO_DETECCION = data.INCENDIO.ID_TIPO_DETECCION
        emergencia.INCENDIO.OBSERVACIONES = data.INCENDIO.OBSERVACIONES

        this.notify('Incendio ' + emergencia.LOCALIDAD, 'Se han actualizado los datos')
      }

      Vue.set(state.emergencias, emergenciaIndex, emergencia)
    }
  },

  SET_EMERGENCIAS (state, emergencias) {
    // state.emergencias = emergencias
    for (let i = 0; i < emergencias.length; i++) {
      let emergencia = emergencias[i]

      emergencia.SECTORES.sort((a, b) => {
        return a.SECTOR === 'GENERAL' ? -1 : b.SECTOR === 'GENERAL' ? 1 : a.SECTOR.localeCompare(b.SECTOR)
      })

      if (emergencia.PLANES_OPERACIONES) {
        for (let j = 0; j < emergencia.PLANES_OPERACIONES.length; j++) {
          let plan = emergencia.PLANES_OPERACIONES[j]

          if (plan.GRAFICOS) {
            for (let z = 0; z < plan.GRAFICOS.length; z++) {
              let oldGrafico = plan.GRAFICOS[z]

              emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].coordenadas = oldGrafico.GEOMETRIA ? JSON.parse(oldGrafico.GEOMETRIA) : oldGrafico.coordenadas
              emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].symbol = oldGrafico.SIMBOLO ? JSON.parse(oldGrafico.SIMBOLO) : oldGrafico.symbol
              emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].attributes = oldGrafico.ATRIBUTOS ? JSON.parse(oldGrafico.ATRIBUTOS) : oldGrafico.attributes
              emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].type = oldGrafico.TIPO ? oldGrafico.TIPO : oldGrafico.type

              delete emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].GEOMETRIA
              delete emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].SIMBOLO
              delete emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].ATRIBUTOS
              delete emergencias[i].PLANES_OPERACIONES[j].GRAFICOS[z].TIPO
            }
          }
        }
      }
      if (emergencia.PERIMETRO) {
        for (let j = 0; j < emergencia.PERIMETRO.length; j++) {
          let perimetro = emergencia.PERIMETRO[j]

          if (perimetro.GRAFICOS_PERIMETRO) {
            for (let z = 0; z < perimetro.GRAFICOS_PERIMETRO.length; z++) {
              let oldGrafico = perimetro.GRAFICOS_PERIMETRO[z]

              try {
                emergencias[i].PERIMETRO[j].GRAFICOS_PERIMETRO[z].geometry = oldGrafico.GEOMETRIA ? JSON.parse(oldGrafico.GEOMETRIA) : oldGrafico.coordenadas
                emergencias[i].PERIMETRO[j].GRAFICOS_PERIMETRO[z].symbol = oldGrafico.SIMBOLO ? JSON.parse(oldGrafico.SIMBOLO) : oldGrafico.symbol
                emergencias[i].PERIMETRO[j].GRAFICOS_PERIMETRO[z].attributes = oldGrafico.ATRIBUTOS ? JSON.parse(oldGrafico.ATRIBUTOS) : oldGrafico.attributes
                emergencias[i].PERIMETRO[j].GRAFICOS_PERIMETRO[z].type = oldGrafico.TIPO ? oldGrafico.TIPO : oldGrafico.type
              } catch (error) {
                delete emergencias[i].PERIMETRO[j].GRAFICOS_PERIMETRO[z]
                this.notify('Error', 'Error al realizar la carga de perimetros', 'error')
              }
            }
          }
        }
      }
    }

    // this.commit('SET_INCENDIOS', emergencias.map(x => x.INCENDIO), { root: true })
    this.dispatch('incendio/setIncendios', emergencias.map(x => x.INCENDIO), { root: true })
    state.emergencias = emergencias
  },

  SET_EMERGENCIAS_TIPO (state, tipos) {
    state.emergenciasTipo = tipos
  },

  SET_EMERGENCIAS_ESTADO_TIPO (state, tipos) {
    state.emergenciaEstadoTipo = tipos.toSorted((estado1, estado2) => {
      return estado1.VALUE - estado2.VALUE
    })
  },

  SET_TIPOS_INCENDIO_NIVEL (state, tiposIncendioNivel) {
    state.tiposIncendioNivel = tiposIncendioNivel
  },

  ADD_EMERGENCIA (state, data) {
    const emergencia = data.emergencia || {}
    let sector = data.sector
    let estado = data.emergenciaEstado

    estado.EMERGENCIA_ESTADO_TIPO = state.emergenciaEstadoTipo.find(x => x.ID_EMERGENCIA_ESTADO_TIPO === estado.ID_EMERGENCIA_ESTADO_TIPO)

    sector.MEDIOS = []

    emergencia.SECTORES = [data.sector] || []
    emergencia.PLANES_OPERACIONES = []
    emergencia.PERIMETRO = []
    emergencia.ESTADOS = [data.emergenciaEstado] || []
    emergencia.INCENDIO = data.incendio || null

    state.emergencias.push(emergencia)

    this.notify('Nuevo incendio', `Se ha iniciado un incendio en ${emergencia.LOCALIDAD}`)
  },

  SOCKET_addIncendio (state, data) {
    state.emergencias.push(data)

    this.notify('Nuevo incendio', `Se ha iniciado un incendio en ${data.LOCALIDAD}`)
  },

  SOCKET_changeSectoresEmergencia (state, data) {
    let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergencia) {
      emergencia.SECTORES = data.SECTORES

      this.notify('Incendio ' + emergencia.LOCALIDAD, 'Sectores actualizados')
    }
  },

  SOCKET_addEstadoEmergencia (state, data) {
    let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergencia) {
      data.EMERGENCIA_ESTADO_TIPO = state.emergenciaEstadoTipo.find(x => x.ID_EMERGENCIA_ESTADO_TIPO === data.ID_EMERGENCIA_ESTADO_TIPO)

      emergencia.ESTADOS.unshift(data)

      this.notify('Incendio ' + emergencia.LOCALIDAD, `Pasa a estado ${data.EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO}`)
    }
  },

  SOCKET_editEstadoEmergencia (state, data) {
    let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergencia) {
      for (let i = 0; i < emergencia.ESTADOS.length; i++) {
        let estado = emergencia.ESTADOS[i]

        if (estado.ID_EMERGENCIA_ESTADO_TIPO.equalsIgnoreCase(data.ID_EMERGENCIA_ESTADO_TIPO)) {
          emergencia.ESTADOS[i].FECHA = data.FECHA

          if (data.CODIGO_TIPO_ESTADO === 0) { // Fecha inicio
            emergencia.FECHA_CREACION = data.FECHA
          }

          this.notify('Emergencia ' + emergencia.LOCALIDAD, `Estado '${estado.EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO}' actualizado`)
          break
        }
      }
    }
  },

  SOCKET_addMediosEmergencia (state, data) {
    let indexEmergencia = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (indexEmergencia !== -1) {
      let emergencia = state.emergencias[indexEmergencia]
      let indexSector = emergencia.SECTORES.findIndex(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))

      if (indexSector !== -1) {
        let sector = emergencia.SECTORES[indexSector]
        let mediosMsg = []

        data.MEDIOS.forEach((medioAdd) => {
          // Actualizar sector
          sector.MEDIOS.push(medioAdd)

          // Actualizar estado del medio
          let newEstado = constants.estadosMedioEmergencia.DISPONIBLE
          if (medioAdd.FECHA_AVISO) newEstado = constants.estadosMedioEmergencia.AVISO
          if (medioAdd.FECHA_LLEGADA) newEstado = constants.estadosMedioEmergencia.ACTUANDO
          if (medioAdd.FECHA_SALIDA) newEstado = constants.estadosMedioEmergencia.REGRESO
          if (medioAdd.FECHA_LLEGADA_BASE) newEstado = constants.estadosMedioEmergencia.DISPONIBLE

          let medio = this.getters['medio/getMedioByID'](medioAdd.ID_MEDIO)
          if (medio) {
            medio.ESTADO = newEstado
            medio.ID_EMERGENCIA = emergencia.ID_EMERGENCIA
            mediosMsg.push(medio.MEDIO)
          }
        })

        Vue.set(state.emergencias[indexEmergencia].SECTORES, indexSector, sector)

        this.notify(`Incendio ${emergencia.LOCALIDAD}`, `Medios [${mediosMsg}] asignados`)
      }
    }
  },

  SOCKET_editMedioEmergencia (state, data) {
    let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergencia) {
      /* let positionSector = App.getPositionSector(data.ID_SECTOR, incendio.SECTORES)
      let positionMedioSector = App.getPositionMedioSector(data.ID_MEDIO_SECTOR, incendio.SECTORES[positionSector].MEDIOS)
      // incendio.SECTORES[positionSector].MEDIOS[positionMedioSector] = data
      incendio.SECTORES[positionSector].MEDIOS.splice(positionMedioSector, 1)
      incendio.SECTORES[positionSector].MEDIOS.push(data) */

      // Sacar del sector previo
      for (let i = 0; i < emergencia.SECTORES.length; i++) {
        let sector = emergencia.SECTORES[i]
        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medioSector = sector.MEDIOS[j]

          if (medioSector.ID_MEDIO_SECTOR.equalsIgnoreCase(data.ID_MEDIO_SECTOR.toUpperCase())) {
            emergencia.SECTORES[i].MEDIOS.splice(j, 1)
            break
          }
        }
      }

      // Add al sector nuevo
      let sector = emergencia.SECTORES.find(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))
      sector.MEDIOS.push(data)

      let medio = this.getters['medio/getMedioByID'](data.ID_MEDIO)
      medio.ESTADO = data.ESTADO
      medio.ID_EMERGENCIA = data.ID_EMERGENCIA
    }
  },

  SOCKET_deleteEmergencia (state, data) {
    let index = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))
    const incendio = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))
    if (index !== -1 && incendio) {
      this.notify('Incendio ' + state.emergencias[index].LOCALIDAD, ' ha sido eliminado')
      state.emergencias.splice(index, 1)
    }
  },

  SOCKET_deleteMedioEmergencia (state, data) {
    let indexEmergencia = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (indexEmergencia !== -1) {
      let emergencia = state.emergencias[indexEmergencia]

      let indexSector = emergencia.SECTORES.findIndex(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))
      if (indexSector !== -1) {
        let sector = emergencia.SECTORES[indexSector]
        let medio = this.getters['medio/getMedioByID'](data.ID_MEDIO)

        let positionMedioSector = sector.MEDIOS.findIndex(x => x.ID_MEDIO_SECTOR.equalsIgnoreCase(data.ID_MEDIO_SECTOR))
        sector.MEDIOS.splice(positionMedioSector, 1)

        if (data.IS_ACTUALIZAR_ESTADO) {
          medio.ESTADO = constants.estadosMedioEmergencia.DISPONIBLE
          medio.ID_EMERGENCIA = null
        }

        Vue.set(state.emergencias, indexEmergencia, emergencia)
        Vue.set(emergencia.SECTORES, indexSector, sector)

        this.notify('Incendio ' + emergencia.LOCALIDAD, `El medio ${medio.MEDIO} se ha eliminado`)
      }
    }
  },

  SOCKET_editPlanificacionMedio (state, data) {
    let indexEmergencia = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (indexEmergencia !== -1) {
      let emergencia = state.emergencias[indexEmergencia]

      for (let i = 0; i < emergencia.SECTORES.length; i++) {
        let sector = emergencia.SECTORES[i]
        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medioSector = sector.MEDIOS[j]

          if (medioSector.ID_MEDIO_SECTOR.equalsIgnoreCase(data.PLANIFICACION.ID_MEDIO_SECTOR.toUpperCase())) {
            emergencia.SECTORES[i].MEDIOS[j].PLANIFICACION = data.PLANIFICACION
          }
        }
      }

      Vue.set(state.emergencias, indexEmergencia, emergencia)
    }
  },

  SOCKET_deleteEstadoIncendio (state, data) {
    let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergencia) {
      emergencia.ESTADOS.splice(0, 1)

      this.notify('Incendio ' + emergencia.LOCALIDAD, `Pasa a estado ${emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO}`)
    }
  },

  SOCKET_addPlanOperaciones (state, data) {
    let position = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (position !== -1) {
      state.emergencias[position].PLANES_OPERACIONES.unshift(data)
      this.notify('Nuevo plan de operaciones', `Se ha creado un plan de operaciones para el incendio en ${state.emergencias[position].LOCALIDAD}`)
    }
  },

  SOCKET_addPerimetro (state, data) {
    let position = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (position !== -1) {
      state.emergencias[position].PERIMETRO.unshift(data)
      this.notify('Nuevo perímetro', `Se ha creado un perímetro para el incendio en ${state.emergencias[position].LOCALIDAD}`)
    }
  },

  SOCKET_editPerimetro (state, data) {
    let emergenciaIndex = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergenciaIndex !== -1) {
      let perimetroIndex = state.emergencias[emergenciaIndex].PERIMETRO.findIndex(x => x.ID_PERIMETRO.equalsIgnoreCase(data.ID_PERIMETRO))

      if (perimetroIndex !== -1) {
        state.emergencias[emergenciaIndex].PERIMETRO[perimetroIndex].GRAFICOS_PERIMETRO = data.GRAFICOS_PERIMETRO // Hay que acceder a los datos de los gráficos
        state.emergencias[emergenciaIndex].PERIMETRO[perimetroIndex].measure = data.measure
        Vue.set(state.emergencias, emergenciaIndex, state.emergencias[emergenciaIndex])
        this.notify('Perímetro modificado', `Se ha modificado un perímetro para el incendio en ${state.emergencias[emergenciaIndex].LOCALIDAD}`)
      }
    }
  },

  SOCKET_deletePerimetro (state, data) {
    let emergenciaIndex = state.emergencias.findIndex(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

    if (emergenciaIndex !== -1) {
      let perimetroIndex = state.emergencias[emergenciaIndex].PERIMETRO.findIndex(x => x.ID_PERIMETRO.equalsIgnoreCase(data.ID_PERIMETRO))

      if (perimetroIndex !== -1) {
        state.emergencias[emergenciaIndex].PERIMETRO.splice(perimetroIndex, 1)
        this.notify('Perímetro eliminado', `Se ha eliminado un perímetro para el incendio en ${state.emergencias[emergenciaIndex].LOCALIDAD}`)
      }
    }
  }
}

const actions = {
  async fetchEmergencias ({ commit }) {
    try {
      const response = await api.emergencia.getEmergencias()

      for (let i = 0; i < response.data.length; i++) {
        for (let j = 0; j < response.data[i].PERIMETRO.length; j++) {
          response.data[i].PERIMETRO[j].measure = await ArcGIS.measurePerimetros(
            response.data[i].PERIMETRO[j].GRAFICOS_PERIMETRO
          )
        }
      }

      if (Utils.getIsCancelRefresh()) {
        return
      }

      commit('SET_EMERGENCIAS', response.data)
      return response.data
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchEmergenciasTipo ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.emergencia.getEmergenciasTipo()
      commit('SET_EMERGENCIAS_TIPO', response.data)
      deferred.resolve(response.data)
    } catch (err) {
      console.error('Error al obtener tipos de emergencia:', err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async fetchEmergenciaEstadoTipo ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.emergencia.getEmergenciaEstadoTipo()
      commit('SET_EMERGENCIAS_ESTADO_TIPO', response.data)
      deferred.resolve(response.data)
    } catch (err) {
      console.error('Error al obtener tipos de emergencia:', err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async crearEmergencia ({ commit }, nuevaEmergencia) {
    let deferred = Q.defer()
    try {
      const response = await api.emergencia.crearEmergencia(nuevaEmergencia)
      commit('ADD_EMERGENCIA', nuevaEmergencia)
      deferred.resolve(response.data)
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async updateEmergencia ({ commit, state }, emergenciaActualizada) {
    try {
      if (Utils.getIsCancelRefresh()) {
        return
      }
      const response = await api.emergencia.updateEmergencia(emergenciaActualizada)

      commit('SOCKET_updateEmergencia', response.data)
    } catch (error) {
      console.error('Error al actualizar emergencia:', error)
      throw error
    }
  },

  async addEstadoEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      Utils.cancelRefresh(true)

      const response = await api.emergencia.addEstadoEmergencia(data)

      if (response.status === 200) {
        commit('SOCKET_addEstadoEmergencia', data)
        this._vm.$socket.emit('addEstadoEmergencia', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async editEstadoEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      Utils.cancelRefresh(true)

      const response = await api.emergencia.updateEstadoEmergencia(data)

      if (response.status === 200) {
        commit('SOCKET_editEstadoEmergencia', data)
        this._vm.$socket.emit('editEstadoEmergencia', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      commit('SOCKET_deleteEmergencia', data)
      const response = await api.emergencia.eliminarIncendio(data)

      if (response.status === 200) {
        this._vm.$socket.emit('deleteEmergencia', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteEstadoIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.deleteEstadoIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_deleteEstadoIncendio', data)
        this._vm.$socket.emit('deleteEstadoIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async editPlanificacionMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.editPlanificacionMedio(data)

      if (response.status === 200) {
        commit('SOCKET_editPlanificacionMedio', data)
        this._vm.$socket.emit('editPlanificacionMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // SECTORES
  async changeSectoresEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.changeSectoresEmergencia(data)

      if (response.status === 200) {
        commit('SOCKET_changeSectoresEmergencia', data)
        this._vm.$socket.emit('changeSectoresEmergencia', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async asignarMedios ({ commit }, data) {
    try {
      const response = await api.emergencia.addMediosEmergencia(data.MEDIOS)
      if (response.status === 200) {
        data.MEDIOS = response.data
        commit('SOCKET_addMediosEmergencia', data)
        this._vm.$socket.emit('addMediosEmergencia', data)
      }

      this._vm.$socket.emit('asignarMedios', data)
    } catch (error) {
      console.error('Error al asignar medios:', error)
    }
  },

  async editMedioEmergencia ({ commit, dispatch }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.editMedioEmergencia(data)

      if (response.status === 200) {
        let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))

        let estado = constants.estadosMedioEmergencia.DISPONIBLE
        if (!data.FECHA_AVISO) {
          estado = constants.estadosMedioEmergencia.DISPONIBLE
        } else if (!data.FECHA_LLEGADA) {
          estado = constants.estadosMedioEmergencia.AVISO
        } else if (!data.FECHA_SALIDA) {
          estado = constants.estadosMedioEmergencia.ACTUANDO
        } else if (!data.FECHA_LLEGADA_BASE) {
          estado = constants.estadosMedioEmergencia.REGRESO
        }

        data.ESTADO = estado

        commit('SOCKET_editMedioEmergencia', data)
        this._vm.$socket.emit('editMedioEmergencia', data)

        // ChangeEstadoIncendio. Añadir o quitar Extinción
        let mediosActuando = false
        for (let i = 0; i < emergencia.SECTORES.length; i++) {
          let sector = emergencia.SECTORES[i]
          for (let j = 0; j < emergencia.SECTORES[i].MEDIOS.length; j++) {
            let medioSector = sector.MEDIOS[j]
            if (medioSector.FECHA_LLEGADA && !medioSector.FECHA_SALIDA) {
              mediosActuando = true
              break
            }
          }
        }

        if (emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE === state.emergenciaEstadoTipo[0].VALUE) { // Si esta en 'INICIO'
          if (!mediosActuando && estado === 2) { // Y pasamos un medio a 'LLEGADA INCENDIO', pasar el incendio a 'EXTINCION'
            let estadoIncendio = {
              ID_EMERGENCIA_ESTADO: UUIDService.createUUID(),
              ID_EMERGENCIA: emergencia.ID_EMERGENCIA,
              ID_EMERGENCIA_ESTADO_TIPO: state.emergenciaEstadoTipo[1].ID_EMERGENCIA_ESTADO_TIPO,
              FECHA: DateService.now(),
              EMERGENCIA_ESTADO_TIPO: state.emergenciaEstadoTipo[1]
            }

            dispatch('addEstadoEmergencia', estadoIncendio)
          }
        } else if (emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE === state.emergenciaEstadoTipo[1].VALUE) { // Si esta en 'EXTINCION'
          if (!mediosActuando) { // Y no quedan medios, pasar a 'INICIO'
            let data = {
              ID_EMERGENCIA_ESTADO: emergencia.ESTADOS[0].ID_EMERGENCIA_ESTADO,
              ID_EMERGENCIA: emergencia.ESTADOS[0].ID_EMERGENCIA,
              NUEVO_ESTADO: emergencia.ESTADOS[1].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO
            }
            dispatch('deleteEstadoIncendio', data)
          }
        }
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteMedioEmergencia ({ commit, dispatch }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.deleteMedioEmergencia(data)

      if (response.status === 200) {
        data.IS_ACTUALIZAR_ESTADO = response.data.IS_ACTUALIZAR_ESTADO
        commit('SOCKET_deleteMedioEmergencia', data)
        this._vm.$socket.emit('deleteMedioEmergencia', data)

        // Si no quedan medios y el incendio está en 'EXTINCION', pasar a 'INICIO'
        let emergencia = state.emergencias.find(x => x.ID_EMERGENCIA.equalsIgnoreCase(data.ID_EMERGENCIA))
        let mediosActuando = false
        if (emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE === state.emergenciaEstadoTipo[1].VALUE) {
          for (let i = 0; i < emergencia.SECTORES.length; i++) {
            let sector = emergencia.SECTORES[i]

            for (let j = 0; j < emergencia.SECTORES[i].MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.FECHA_LLEGADA && !medioSector.FECHA_SALIDA) {
                mediosActuando = true
                break
              }
            }
          }
          if (!mediosActuando) {
            let data = {
              ID_EMERGENCIA_ESTADO: emergencia.ESTADOS[0].ID_EMERGENCIA_ESTADO,
              ID_EMERGENCIA: emergencia.ESTADOS[0].ID_EMERGENCIA,
              NUEVO_ESTADO: emergencia.ESTADOS[1].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO
            }
            dispatch('deleteEstadoIncendio', data)
          }
        }
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // PLAN OPERACIONES
  async addPlanOperaciones ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.addPlanOperaciones(data)

      if (response.status === 200) {
        commit('SOCKET_addPlanOperaciones', data)
        this._vm.$socket.emit('addPlanOperaciones', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // PERIMETRO
  async addPerimetro ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.addPerimetro(data)

      if (response.status === 200) {
        commit('SOCKET_addPerimetro', data)
        this._vm.$socket.emit('addPerimetro', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async editPerimetro ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.editPerimetro(data)

      if (response.status === 200) {
        commit('SOCKET_editPerimetro', data)
        this._vm.$socket.emit('editPerimetro', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deletePerimetro ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.emergencia.deletePerimetro(data)

      if (response.status === 200) {
        commit('SOCKET_deletePerimetro', data)
        this._vm.$socket.emit('deletePerimetro', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }

}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
