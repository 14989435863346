import request from '../request'

export default {
  getMedios: function () {
    return request.get('medio')
  },

  getClasesMedio: function () {
    return request.get('medio_clase')
  },

  getCategoriasMedio: function () {
    return request.get('medio_categoria')
  },

  getTiposMedio: function () {
    return request.get('medio_tipo')
  },

  getZonasMedio: function () {
    return request.get('medio_zona')
  },

  addMedio: function (data) {
    return request.post('medio', data)
  },
  updateMedio: function (data) {
    return request.put(`medio/${data.ID_MEDIO}`, data)
  },
  enableMedio: function (data) {
    return request.put(`medio/enable/${data.ID_MEDIO}`, data)
  },
  disableMedio: function (data) {
    return request.put(`medio/disable/${data.ID_MEDIO}`, data)
  },
  deleteMedio: function (data) {
    return request.delete(`medio/${data.ID_MEDIO}`)
  },
  getIdDispositivo: function (idMedio) {
    return request.get(`medio/${idMedio}`)
  },

  getEmergencias: function () {
    return request.get('emergencia_medio')
  },
  setEmergenciaMedio: function (data) {
    return request.post('emergencia_medio', data)
  },

  getTrackMedio: function (idMedio, timeBack) {
    return request.get(`medio_posicion/${idMedio}/${timeBack}`)
  },

  getTrackFechasMedio: function (idDispositivo, dateInit, dateEnd) {
    return request.get(`medio_posicion/${idDispositivo}/${dateInit}/${dateEnd}`)
  },
  getTrackFechasInPolygon: function (data) {
    return request.post('medios_posicion_polygon/', data)
  },
  getMedioPersonal: function (data) {
    return request.get(`medio_personal/${data}`)
  },
  // Expected array en data
  setMedioPersonal: function (data) {
    return request.post('medio_personal', data)
  },
  deleteMedioPersonal: function (data) {
    return request.delete(`medio_personal/${data}`)
  },
  getPersonal: function () {
    return request.get('personal')
  },
  getTurnos: function () {
    return request.get('turno')
  },
  setTurno: function (data) {
    return request.post('turno', data)
  },
  deleteTurno: function (data) {
    return request.delete(`turno/${data.ID_TURNO}`)
  },
  updateTurno: function (data) {
    return request.put(`turno/${data.ID_TURNO}`, data)
  },
  getEstadosMedios: function () {
    return request.get('medio_estado')
  },
  addMedioEstadoHist: function (data) {
    return request.post('medio_estado_hist', data)
  },
  getPosActualesSinMedio: function () {
    return request.get('geo_pos_actual_no_medio')
  }
}
