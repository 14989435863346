<template>
  <v-dialog v-model="showDialog" persistent width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Añadir incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="municipio" label="Municipio" placeholder="Municipio" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="localidad" label="Localidad" placeholder="Localidad" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <vx-date-text-edit v-model="fechaInicio" label="Fecha de inicio" icon="esri-icon-calendar" outlined required /> <!-- not future quitado -->
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="latitud" label="Latitud" dense outlined prepend-icon="mdi-map-marker" @blur="updatedLatLon()" v-mask="'##.#####'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="longitud" label="Longitud" dense outlined @blur="updatedLatLon()" v-mask="'-#.#####'" hide-details /> <!-- TODO: en alguna ocasion el - no sera obligatorio -->
              </v-col>

              <v-col sm="1">
                <v-btn icon color="blue" @click="editLocation">
                  <v-icon>mdi-map-marker-star</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="xETRS89" label="X" dense outlined prepend-icon="mdi-map-marker" @blur="updatedXY()" v-mask="'######'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="yETRS89" label="Y" dense outlined @blur="updatedXY()" v-mask="'#######'" hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="idTipoDeteccion" :items="ordenTiposDeteccion" label="Detección" dense outlined prepend-icon="esri-icon-hollow-eye" item-text="DETECCION" item-value="ID_TIPO_DETECCION" placeholder="Detección" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea v-model="observaciones" label="Observaciones" dense outlined placeholder="Observaciones" prepend-icon="esri-icon-comment" auto-grow hide-details />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as ArcGIS from '../../helpers/ArcGIS'

import { moment } from 'vis-timeline/standalone'

export default {
  props: {
    show: Boolean,
    pointData: Object
  },

  data: () => ({
    showDialog: false,

    isValid: true,

    municipio: null,
    localidad: null,
    codProv: null,
    codCCAA: null,
    latitud: null,
    longitud: null,
    xETRS89: null,
    yETRS89: null,

    fechaInicio: null,
    idTipoDeteccion: 5,
    observaciones: null,

    isShowNewCoordenadas: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposDeteccion'
    ]),

    ...mapGetters('emergencia', [
      'emergenciaEstadoTipo'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ]),

    ordenTiposDeteccion () {
      return [...this.tiposDeteccion].sort((a, b) => {
        return a.DETECCION.localeCompare(b.DETECCION)
      })
    }
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        this.codProv = this.pointData.COD_PROV
        this.codCCAA = this.pointData.COD_CCAA

        this.municipio = this.pointData.MUNICIPIO
        this.localidad = this.pointData.LOCALIDAD

        this.fechaInicio = this.$date.currentDate()

        this.latitud = this.pointData.LATITUD
        this.longitud = this.pointData.LONGITUD
        this.xETRS89 = this.pointData.X
        this.yETRS89 = this.pointData.Y

        this.paintEmergencia()
      }
    },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { // Volver a mostrar el dialogo tras capturar el punto
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.show) {
        this.codProv = this.newCoordenadas.COD_PROV
        this.codCCAA = this.newCoordenadas.COD_CCAA

        this.municipio = this.newCoordenadas.MUNICIPIO
        this.localidad = this.newCoordenadas.LOCALIDAD

        this.latitud = this.newCoordenadas.LATITUD
        this.longitud = this.newCoordenadas.LONGITUD

        this.xETRS89 = this.newCoordenadas.X.toFixed(2)
        this.yETRS89 = this.newCoordenadas.Y.toFixed(2)

        this.paintEmergencia()
        this.show = true
        this.isShowNewCoordenadas = false
      }
    }
  },

  methods: {
    ...mapActions('map', [
      'startNewCoordenadas'
    ]),

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    clearEmergencia () {
      ArcGIS.paintPositionEmergencia(null)
    },
    paintEmergencia () {
      ArcGIS.paintPositionEmergencia({ LATITUD: this.latitud, LONGITUD: this.longitud })
    },

    updatedLatLon () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let xy = ArcGIS.convertirLatLonToETRS89(this.latitud, this.longitud)
        this.xETRS89 = xy[0].toFixed(2)
        this.yETRS89 = xy[1].toFixed(2)

        this.paintEmergencia()
      }
    },

    updatedXY () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let latLon = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.longitud = latLon[0].toFixed(5)
        this.latitud = latLon[1].toFixed(5)

        this.paintEmergencia()
      }
    },

    cancelar () {
      this.clearEmergencia()
      this.$emit('cancelar')

      this.idTipoDeteccion = 5
      this.observaciones = null
    },

    aceptar () {
      let emergenciaTipo = this.$store.getters['emergencia/emergenciasTipo'][0]
      let emergenciaEstadoTipo = this.$store.getters['emergencia/getEmergenciaEstadoTipoByValue'](emergenciaTipo.ID_EMERGENCIA_TIPO, 0)

      let currentUsuario = this.$store.getters['auth/idUsuario']

      this.clearEmergencia()

      let fechaCreacion = this.$date.parseDate(this.fechaInicio)

      let emergencia = {
        ID_EMERGENCIA: this.$uuid.createUUID(),
        ID_EMERGENCIA_TIPO: emergenciaTipo.ID_EMERGENCIA_TIPO,
        ELIMINADO: 0,
        LATITUD: this.latitud,
        LONGITUD: this.longitud,
        FECHA_CREACION: fechaCreacion,
        COD_PROV: this.codProv,
        COD_CCAA: this.codCCAA,
        MUNICIPIO: this.municipio,
        LOCALIDAD: this.localidad,
        OBSERVACIONES: this.observaciones,
        OCULTO: 0,
        FECHASTAMP: moment(),
        USUARIOSTAMP: currentUsuario
      }

      let emergenciaEstado = {
        ID_EMERGENCIA_ESTADO: this.$uuid.createUUID(),
        ID_EMERGENCIA_ESTADO_TIPO: emergenciaEstadoTipo.ID_EMERGENCIA_ESTADO_TIPO,
        ID_EMERGENCIA: emergencia.ID_EMERGENCIA,
        FECHA: fechaCreacion,
        FECHASTAMP: moment(),
        USUARIOSTAMP: currentUsuario
      }

      let incendio = {
        ID_INCENDIO: this.$uuid.createUUID(),
        ID_TIPO_DETECCION: this.idTipoDeteccion,
        NOMBRE: null,
        ELIMINADO: 0,
        ID_EMERGENCIA: emergencia.ID_EMERGENCIA
      }

      let sector = {
        ID_SECTOR: this.$uuid.createUUID(),
        ID_EMERGENCIA: emergencia.ID_EMERGENCIA,
        SECTOR: 'GENERAL'
      }

      this.$emit('aceptar', {
        emergencia: emergencia,
        emergenciaEstado: emergenciaEstado,
        incendio: incendio,
        sector: sector
      })
    }
  }
}
</script>

<style scoped>
</style>
