import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/components/Login'

import MainLayout from '@/components/layout/MainLayout'
import MainMap from '@/components/map/Main'

import Actuaciones from '@/components/actuaciones/Actuaciones'
import Medios from '@/components/medios/Medios'
import PlanificacionMMTT from '@/components/planificacion_mmtt/PlanificacionMMTT'
import PlanificacionMMAA from '@/components/planificacion_mmaa/PlanificacionMMAA'

// import Guardias from '@/components/guardias/GuardiasPorMedio'
import Guardias from '@/components/guardias/Guardias'

import Infraestructuras from '@/components/infraestructuras/Infraestructuras'

import Personal from '@/components/personal/Personal'

import NotFound from '@/components/NotFound'

import ResumenMedios from '@/components/map/ResumenMedios.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Map',
        component: MainMap
      },
      {
        path: 'actuaciones',
        name: 'Actuaciones',
        component: Actuaciones
      },
      {
        path: 'medios',
        name: 'Medios',
        component: Medios
      },
      {
        path: 'planificacion_mmtt',
        name: 'Planificacion MMTT',
        component: PlanificacionMMTT
      },
      {
        path: 'planificacion_mmaa',
        name: 'Planificacion MMAA',
        component: PlanificacionMMAA
      },
      {
        path: 'guardias',
        name: 'Guardias',
        component: Guardias
      },
      {
        path: 'infraestructuras',
        name: 'Infraestructuras',
        component: Infraestructuras
      },
      {
        path: 'personal',
        name: 'Personal',
        component: Personal
      },
      {
        path: 'resumen_medios',
        name: 'Resumen de medios',
        component: ResumenMedios
      }
    ]
  },
  { // Capturar rutas no existentes
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let token = store.getters['auth/accessToken']
  if (!token && to.name !== 'Login') {
    router.push('/login')
  } else if (token && to.name === 'Login') {
    router.push('/')
  }

  next()
})

export default router
