<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Editar turno</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-container>
          <v-alert type="warning" dense>
            Al modificar este turno, se actualizarán todas las guardias que lo contengan.
          </v-alert>
          <v-form ref="formEditTurno" v-model="isValid" @submit.prevent>
            <v-row>
              <v-col class="pa-1" cols="12">
                <v-text-field v-model="nombre" placeholder="Nombre del turno" outlined dense required :rules="[v => v !== '' || 'Campo obligatorio.']" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-1" cols="6">
                <vx-time-text-edit v-model="horaEntrada" label="Hora de entrada" outlined dense required />
              </v-col>
              <v-col class="pa-1" cols="6">
                <vx-time-text-edit v-model="horaSalida" label="Hora de salida" outlined dense required />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text small color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text small color="green" @click="save" type="submit" :disabled="!isValid">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    show: Boolean,
    turno: Object
  },
  data () {
    return {
      isValid: false,
      nombre: this.turno?.NOMBRE || '',
      horaEntrada: this.turno?.HORA_ENTRADA || null,
      horaSalida: this.turno?.HORA_SALIDA || null
    }
  },
  watch: {
    turno: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.nombre = newVal.NOMBRE
          this.horaEntrada = newVal.HORA_ENTRADA
          this.horaSalida = newVal.HORA_SALIDA
        }
      }
    }
  },
  methods: {
    ...mapActions('medioPersonal', ['updateTurno']),
    async save () {
      if (this.nombre !== '') {
        await this.updateTurno({
          ID_TURNO: this.turno.ID_TURNO,
          NOMBRE: this.nombre,
          HORA_ENTRADA: this.horaEntrada,
          HORA_SALIDA: this.horaSalida
        })
        this.cancelar()
      }
    },
    cancelar () {
      this.$emit('cancelar')
    }
  }
}
</script>

  <style scoped>
  </style>
