<template>
  <v-dialog v-model="show" persistent width="700px">
    <v-card style="height: 50vh;" class="d-flex flex-column">
      <v-toolbar color="primaryLight" dark dense style="max-height: min-content !important;">
        <v-toolbar-title>Cambio de estado de medios</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="flex-grow-1 overflow-y-auto pt-2 pb-0">
        <v-data-table
          :headers="headers"
          :items="mediosCambioEstado"
          fixed-header
          density="compact"
          :items-per-page="-1"
          hide-default-footer
          class="tabla"
          group-by="ID_EMERGENCIA"
          style="overflow-y: auto !important;height: 100%;"
          :sortBy="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>

          <template #item="{ item }">
            <tr>
              <td v-for="head in headers" :key="head.key" :class="['ESTADO', 'NUEVO_ESTADO'].includes(head.value) ? getCellColor(item, head.value): ''" :style="{width: getCellWidth(head)}">
                <div v-if="head.value === 'ESTADO'">
                  <span>{{ nombreEstado(item.ESTADO) }}</span>
                </div>
                <div v-else-if="head.value === 'ARROW'">
                  <span><v-icon>mdi-arrow-right</v-icon></span>
                </div>
                <div v-else-if="head.value === 'MEDIO'">
                  <span>{{ item.MEDIO || '-' }}</span>
                </div>
                <div v-else-if="head.value === 'NUEVO_ESTADO'">
                  <span>{{ nombreEstado(item.ESTADO +1) }}</span>
                </div>
                <div v-else>
                  <span>{{ '-' }}</span>
                </div>
              </td>
            </tr>
          </template>

          <template #[`group.header`]="{ group, toggle, isOpen }">
            <td @click="toggle" :colspan="headers.length" style="cursor: pointer; background-color: #ffffff;">
              <v-btn x-small text :style="getGroupHeaderColor(group)">
                <v-icon v-if="!isOpen" style="font-size: 16px !important;">mdi-plus</v-icon>
                <v-icon v-else style="font-size: 16px !important;">mdi-minus</v-icon>
              </v-btn>
              <span class="px-2" style="font-size: 0.875rem !important;">{{ $store.getters['emergencia/getEmergenciaByID'](group) ? $store.getters['emergencia/getEmergenciaByID'](group).MUNICIPIO : 'Sin asignar' }}</span>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/helpers/constants'
export default {
  props: {
    show: Boolean,
    mediosCambioEstado: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    sortBy: 'ESTADO',
    sortDesc: true,
    headers: [
      { text: 'Medio', value: 'MEDIO' },
      { text: 'Estado', value: 'ESTADO' },
      { text: '', value: 'ARROW' },
      { text: 'Nuevo estado', value: 'NUEVO_ESTADO' }
    ]
  }),
  watch: {
    show () {
    //   if (this.show) {
    //   }
    }
  },
  methods: {
    cancelar () {
      this.$emit('cancelar')
    },
    aceptar () {
      this.$emit('aceptar')
    },
    getCellColor (medio, head) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (head === 'ESTADO' ? medio.ESTADO : medio.ESTADO + 1) {
        case constants.estadosMedioEmergencia.AVISO:
          rowClass = 'medioAvisoResumen'
          break
        case constants.estadosMedioEmergencia.ACTUANDO:
          rowClass = 'medioLlegadaIncResumen'
          break
        case constants.estadosMedioEmergencia.REGRESO:
          rowClass = 'medioSalidaResumen'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescansoResumen'
      }

      return rowClass
    },
    getCellWidth (head) {
      let width = ''
      switch (head.value) {
        case 'ESTADO':
          width = '175px'
          break
        case 'NUEVO_ESTADO':
          width = '175px'
          break
        case 'ARROW':
          width = '24px'
          break
      }

      return width
    },
    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },
    getGroupHeaderColor (incendio) {
      let estilo = {
        cursor: 'pointer',
        'background-color': this.$store.getters['emergencia/getEmergenciaByID'](incendio) ? this.$store.getters['emergencia/getEmergenciaByID'](incendio).ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR : 'green',
        color: 'white'
      }

      return estilo
    },
    nombreEstado (estado) {
      switch (estado) {
        case 0:
          return 'No asignado'

        case 1:
          return 'Avisado'

        case 2:
          return 'Llegada a incendio'

        case 3:
          return 'Salida del incendio'

        case 4:
          return 'Llegada a base'
      }
    }
  }
}
</script>

<style scoped>
  .medioAvisoResumen {
    background-color: #8EB4E3 !important;
  }
  .medioLlegadaIncResumen {
    background-color: #F14D5A !important;
  }
  .medioSalidaResumen {
    background-color: #FFC000 !important;
  }
  .medioDescansoResumen {
    background-color: #AAAAAA !important;
  }
  .tabla {
    font-size: 0.8rem !important;
    overflow: auto;
  }
  .tabla >>> .v-selection-control__input {
    width: fit-content;
  }
  .tabla >>> .v-data-table__wrapper {
    /* Header fijo */
    overflow: unset;
  }
  .tabla >>> th {
    vertical-align: middle;
  }
  .tabla >>> th,
  .tabla >>> td {
    font-size: 0.9rem !important;
    padding: 0 8px !important;
  }
</style>
