<template>
  <v-navigation-drawer
    ref="drawer"
    v-model="show"
    :width="navigation.width"
    color="#AAAAAACC"
    absolute
    hide-overlay
    stateless
    style="z-index: 2"
  >
    <v-toolbar color="primaryLight" dark dense>
      <v-btn dark icon @click="exit">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{
          emergencia ? (emergencia.INCENDIO.NOMBRE ? emergencia.INCENDIO.NOMBRE : emergencia.LOCALIDAD) : ""
        }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Track medios -->
      <v-tooltip bottom v-for="button in buttons" :key="button.id">
        <template #activator="{ on, attrs }">
          <v-btn
            v-show="button.visible"
            v-bind="attrs"
            v-on="on"
            fab
            small
            color="secondary"
            bottom
            right
            class="me-3"
            @click="clickBtn(button.id)"
          >
            <v-icon color="primary">{{ button.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ button.label }}</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn fab small v-bind="attrs" v-on="on" v-show = "noEstaFinalizado" color="secondary" bottom right class="mr-3" @click="changeVisibilityNivelIncendio">
            <v-icon color="primary">mdi-list-status</v-icon>
          </v-btn>
        </template>
        <span>Cambiar</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            :disabled="isButtonDisabled"
            @click="generateInformePosiciones"
          >
            <v-icon color="primary">mdi-file-chart-outline</v-icon>
          </v-btn>
        </template>
        <span>Informe posiciones</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            @click="generateExcelRecursos"
          >
            <v-icon color="primary">mdi-file-excel-box</v-icon>
          </v-btn>
        </template>
        <span>Informe de recursos</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            @click="generatePDFEmergencia"
          >
            <v-icon color="primary">mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <span>Resumen del incendio</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            @click="showDialogImagen = true"
          >
            <v-icon color="primary">mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Subir imágenes</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            @click="showDialogDeleteEmergencia = true"
          >
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Eliminar incendio</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid style="height: calc(100vh - 112px); overflow-y: auto">
      <v-snackbar v-model="showSnackBar" color="error" bottom :timeout="5000">
        {{ msgSnackBar }}
      </v-snackbar>
      <v-snackbar v-model="isSnackbarLoading" bottom :timeout="-1">
        {{ msgSnackbarLoading }}
        <template #action="{ attrs }">
          <v-progress-circular v-on="attrs" indeterminate />
        </template>
      </v-snackbar>
      <vx-datos-incendio class="mt-4" :idEmergencia="idEmergenciaSelected" />
      <vx-datos-sectores
        class="mt-4"
        :idEmergencia="idEmergenciaSelected"
        @changeSectoresSelected="changeSectoresSelected"
      />
      <vx-datos-medios
        class="mt-4"
        :idEmergencia="idEmergenciaSelected"
        :sectoresSelected="sectoresSelected"
      />
      <vx-datos-planes-operaciones
        class="mt-4"
        :idEmergencia="idEmergenciaSelected"
      />
      <!--desde aquí visualizamos la nueva clase MenuPerimetro-->
      <vx-datos-perimetros
        class="mt-4"
        :idEmergencia="idEmergenciaSelected"
      />
      <vx-imagen-satelite class="mt-4" :idEmergencia="idEmergenciaSelected" />
      <vx-dialog-track-fechas
        :show="showDialogTrackAllMedios"
        :idEmergencia="idEmergenciaSelected"
        @aceptar="obtainAllTrackMedios"
        @cancelar="cancelTrackMedio"
      />
      <vx-menu-alineaciones-campbell class="mt-4" :idIncendio="idIncendioEmergencia" />
      <vx-dialog-add-imagenes class="mt-4" :idEmergencia="idEmergenciaSelected" :show="showDialogImagen" @cancelar="cancelAddImagenes" />
      <vx-dialog-delete-incendio class="mt-4" :emergencia="emergencia" :show="showDialogDeleteEmergencia" @eliminar="deletedEmergencia" @cancelar="cancelDeleteEmergencia" />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import DatosIncendio from './DatosIncendio'
import DatosPlanesOperaciones from './DatosPlanesOperaciones'
import DatosSectores from './DatosSectores'
import DatosMedios from './DatosMedios'
import constants from '../../../helpers/constants'
import ImagenSatelite from './ImagenSatelite'
import DialogTrackAllMedios from './DialogTrackAllMedios'
import MenuAlineacionesCampbell from './MenuAlineacionesCampbell.vue'
import MenuPerimetro from './MenuPerimetro.vue' // importamos la nueva clase
import DialogAddImagenes from './DialogAddImagenes.vue'
import DialogDeleteIncendio from './DialogDeleteIncendio.vue'

// import IncendioGestion from './IncendioGestion'

import * as ArcGIS from '@/helpers/ArcGIS'
// import fs from 'fs'
import api from '@/api'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import medio from '@/api/medio'
import { thenResolve } from 'q'
import incendio from '@/api/incendio'
import { mapGetters } from 'vuex'
import equipo from '@/api/equipo'
import * as PDFHelper from '../../../helpers/PDFHelper'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const GUION_EMPTY = '-'

export default {
  components: {
    'vx-datos-incendio': DatosIncendio,
    'vx-datos-planes-operaciones': DatosPlanesOperaciones,
    'vx-datos-sectores': DatosSectores,
    'vx-datos-perimetros': MenuPerimetro, // añadimos su componente
    'vx-datos-medios': DatosMedios,
    'vx-imagen-satelite': ImagenSatelite,
    'vx-dialog-track-fechas': DialogTrackAllMedios,
    'vx-menu-alineaciones-campbell': MenuAlineacionesCampbell,
    'vx-dialog-add-imagenes': DialogAddImagenes,
    'vx-dialog-delete-incendio': DialogDeleteIncendio
    // 'vx-incendio-gestion': IncendioGestion,
  },

  props: {
    show: Boolean,
    showIncendiosCards: Boolean,
    idEmergenciaSelected: String
  },

  data: () => ({
    showDialogDeleteEmergencia: false,
    isButtonDisabled: false,
    showDialogImagen: false,
    isSnackbarLoading: false,
    msgSnackbarLoading: '',
    msgSnackBar: '',
    showSnackBar: false,
    sectoresSelected: [],
    mediostracked: false,
    buttons: [
      {
        id: 'showTrack',
        icon: 'mdi-map-marker',
        label: 'Track de los medios asociados',
        visible: true
      },
      {
        id: 'hideTrack',
        icon: 'mdi-map-marker-off',
        label: 'Ocultar track',
        visible: false
      }
    ],
    dispositivosAllEmergencias: new Map(),
    arrayDispositivosEmergencia: [],
    showDialogTrackAllMedios: false,
    idIncendioEmergencia: null,

    navigation: {
      width: 710,
      borderSize: 3,
      minWidth: 650
    }
  }),
  watch: {
    idEmergenciaSelected (idNuevaEmergencia) {
      if (idNuevaEmergencia != null) {
        this.showSnackBar = false
        this.showSnackBar = ''
        this.arrayDispositivosEmergencia = this.dispositivosAllEmergencias.has(
          idNuevaEmergencia
        )
          ? this.dispositivosAllEmergencias.get(idNuevaEmergencia)
          : []
        // Determinamos la visibilidad del boton de hide traks
        this.visibilidadBotones()
      }
    },
    emergencia () {
      this.idIncendioEmergencia = this.emergencia?.INCENDIO?.ID_INCENDIO
    }
  },

  computed: {
    ...mapGetters('informe', ['getImagenByLabel']),
    emergencia () {
      return this.$store.getters['emergencia/getEmergenciaByID'](
        this.idEmergenciaSelected
      )
    },
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](
        this.idIncendioSelected
      )
    },
    deteccion () {
      let deteccion = this.emergencia
        ? this.$store.getters['emergencia/getEstadoTipoById'](
          this.emergencia.ID_EMERGENCIA_ESTADO_TIPO
        )
        : null
      return deteccion || {}
    },
    medios () {
      return this.$store.getters['medio/medios']
    },
    noEstaFinalizado () {
      return this.emergencia == null
        ? true
        : this.emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO !==
            'Finalizado'
    }
  },

  methods: {

    // Funciones auxiliares
    getFecha (id, medio) {
      switch (id) {
        case 0:
          return medio.FECHA_AVISO
        case 1:
          return medio.FECHA_LLEGADA
        case 2:
          return medio.FECHA_SALIDA
        case 3:
          return medio.FECHA_LLEGADA_BASE
        default:
          return null
      }
    },
    formatFechaFin (fechaFin) {
      if (fechaFin == null) return this.$date.now()
      return this.$date.parseDate(fechaFin)
    },
    formatFechaInicio (fechaInicio) {
      return this.$date.parseDate(fechaInicio)
    },

    visibilidadBotones () {
      let visibilidad = this.arrayDispositivosEmergencia.length > 0

      this.buttons[0].visible = !visibilidad
      this.buttons[1].visible = visibilidad
    },

    clickBtn (btn) {
      if (btn === 'showTrack') {
        this.showDialogTrackAllMedios = true
        // this.obtainAllTrackMedios()
      } else if (btn === 'hideTrack') {
        this.hideTrack()
      }
    },
    changeSectoresSelected (sectoresSelected) {
      this.sectoresSelected = sectoresSelected
    },

    exit () {
      this.$el.scrollTop = 0
      this.$emit('exit')
    },

    snackbarLoadingOn (msg) {
      this.isSnackbarLoading = true
      this.msgSnackbarLoading = msg
    },

    snackbarLoadingOff () {
      this.isSnackbarLoading = false
    },

    // Funciones para localizar los medios
    async obtainAllTrackMedios (fechaInicio, fechaFin) {
      this.showDialogTrackAllMedios = false
      let emergencia = this.emergencia
      let idEmergencia = emergencia.ID_EMERGENCIA
      this.msgSnackBar = ''
      let tieneMedios = false

      this.snackbarLoadingOn('Descargando datos del track')
      for (let i = 0; i < emergencia.SECTORES.length; i++) {
        for (let j = 0; j < emergencia.SECTORES[i].MEDIOS.length; j++) {
          let medio = this.emergencia.SECTORES[i].MEDIOS[j]
          tieneMedios = true
          await this.loadTrackFechas(medio, fechaInicio, fechaFin)
        }
      }
      this.snackbarLoadingOff()

      if (this.arrayDispositivosEmergencia) {
        this.dispositivosAllEmergencias.set(
          idEmergencia,
          this.arrayDispositivosEmergencia
        )
      }

      // this.arrayDispositivosIncendio !== null ? this.dispositivosAllIncendios.set(idIncendio, this.arrayDispositivosIncendio) : {}
      if (!tieneMedios) {
        this.msgSnackBar = 'El incendio no tiene medios asociados.'
      }
      if (this.msgSnackBar.length !== 0) {
        this.showSnackBar = true
      }
      this.visibilidadBotones()
    },
    async loadTrackFechas (medio, fechaInicio, fechaFin) {
      let infoMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

      // Si la fecha de inicio no es nula
      if (this.getFecha(fechaInicio, medio) !== null) {
        // let fechaInit = this.formatFechaInicio(this.getFecha(fechaInicio, medio))
        // let fechaEnd = this.formatFechaFin(this.getFecha(fechaFin, medio))

        let dateInit = this.getFecha(fechaInicio, medio)
          ? new Date(this.getFecha(fechaInicio, medio)).toISOString()
          : new Date().toISOString()
        let dateEnd = this.getFecha(fechaFin, medio)
          ? new Date(this.getFecha(fechaFin, medio)).toISOString()
          : new Date().toISOString()
        // Date format: 2023-03-17T11:13:00.000Z
        try {
          let idDispositivo = await api.medio.getIdDispositivo(medio.ID_MEDIO)

          if (idDispositivo.data === null) {
            this.msgSnackBar +=
                'El medio ' + infoMedio.MEDIO + ' no dispone de track.\n'
          } else {
            let response = await api.medio.getTrackFechasMedio(
              idDispositivo.data.ID_GEO_DISPOSITIVO,
              dateInit,
              dateEnd
            )
            if (response.status === 200) {
              if (response.data.length >= 2) {
                this.showTrack(response.data)
              }
            }

            if (response.status === 204) {
              this.msgSnackBar +=
                  'El medio ' + infoMedio.MEDIO + ' no dispone de track.\n'
            }
          }
        } catch (err) {
          this.$log.error(
            'Error al obtener el track' + infoMedio.ID_MEDIO,
            err
          )
          this.msgSnackBar +=
            'Error al obtener el track del dispositivo del medio ' +
            infoMedio.MEDIO +
            '\n'
        }
      } else {
        this.msgSnackBar +=
          'Fecha de inicio invalida del medio ' + infoMedio.MEDIO + '\n'
      }
    },
    // Funcion para cancelar el trackeo en el dialog
    cancelTrackMedio () {
      this.showDialogTrackAllMedios = false
    },
    showTrack (posiciones) {
      let maxTimeDiference = constants.track.timeDiferenceTrackMinutes
      let maxPositions = constants.track.maxPositions
      let positions = []
      let nameTrack = ''

      // console.log(posiciones)

      for (let i = 0; i < posiciones.length; i++) {
        if (nameTrack === '' && posiciones[i].MEDIO) {
          nameTrack = posiciones[i].MEDIO
        }
        if (i === 0 || i === posiciones.length - 1) {
          positions.push(posiciones[i])
        } else {
          let dateLastPos = this.$date.parseDate(posiciones[i - 1].FECHA)
          let date = this.$date.parseDate(posiciones[i].FECHA)
          let difMinutes = dateLastPos.diff(date, 'minutes')

          if (difMinutes <= maxTimeDiference && positions.length <= maxPositions) { // this.track.positions
            // this.track.positions.push(posiciones[i])
            positions.push(posiciones[i])
          } else {
            continue
          }
        }
      }
      /**
       * Distinguir entre dispositivo o medio
       */
      let idTrack = '' //

      ArcGIS.drawTrack(idTrack, nameTrack, positions, true)
      this.arrayDispositivosEmergencia.push(idTrack)
    },
    // Ocultar los tracks
    hideTrack () {
      let idEmergencia = this.emergencia.ID_EMERGENCIA
      let dispositivosEliminar = this.dispositivosAllEmergencias.get(idEmergencia)
      for (let i = 0; i <= dispositivosEliminar.length; i++) {
        let dispositivo = dispositivosEliminar.pop()
        ArcGIS.hideTrack(dispositivo)
      }
      this.visibilidadBotones()
      this.dispositivosAllEmergencias.delete(idEmergencia)
    },

    async generateInformePosiciones () {
      this.isButtonDisabled = true

      try {
        let base64Img = await ArcGIS.captureMap()

        let municipio = this.emergencia.MUNICIPIO ? this.emergencia.MUNICIPIO : ''
        let localidad = this.emergencia.LOCALIDAD ? this.emergencia.LOCALIDAD : ''

        const data = {
          municipio: municipio
        }

        this.$emit('marcoCaptura', data)
      } catch (error) {
        console.error('Error al generar el informe:', error)
      } finally {
        this.isButtonDisabled = false
      }
    },

    generatePDFEmergencia () {
      let tieneMedios = false
      let tableEstados = []
      let tableMedios = []
      let listaMedios = []

      // Creo la tabla de estados
      let estados = this.emergencia.ESTADOS
      for (let i = 0; i < estados.length; i++) {
        let estado = []
        estado.push(
          this.$date.formatDate(estados[i].FECHA, 'DD/MM/YYYY HH:mm')
        )
        estado.push(estados[i].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO)
        tableEstados.unshift(estado)
      }

      tableEstados.unshift(['Fecha', 'Estado'])
      tableMedios.push([
        'Medio',
        'Nombre',
        'Categoría',
        'Aviso',
        'Llegada',
        'Salida',
        'Base',
        'Horas',
        'Total'
      ])

      // Ordeno la lista de medios por fecha
      for (let i = 0; i < this.emergencia.SECTORES.length; i++) {
        for (let j = 0; j < this.emergencia.SECTORES[i].MEDIOS.length; j++) {
          tieneMedios = true
          listaMedios.push(this.emergencia.SECTORES[i].MEDIOS[j])
        }
      }

      listaMedios.sort(function (a, b) {
        let aAviso = a.FECHA_AVISO
        let bAviso = b.FECHA_AVISO
        let aLlegada = a.FECHA_LLEGADA
        let bLlegada = b.FECHA_LLEGADA
        let aSalida = a.FECHA_SALIDA
        let bSalida = b.FECHA_SALIDA
        let aBase = a.FECHA_LLEGADA_BASE
        let bBase = b.FECHA_LLEGADA_BASE

        if (aAviso === bAviso) {
          if (aSalida === bSalida) {
            if (aBase === bBase) {
              return aBase < bBase ? -1 : aBase > bBase ? 1 : 0
            } else {
              return aSalida < bSalida ? -1 : aSalida > bSalida ? 1 : 0
            }
          } else {
            return aLlegada < bLlegada ? -1 : aLlegada > bLlegada ? 1 : 0
          }
        } else {
          return aAviso < bAviso ? -1 : 1
        }
      })

      let medioTotalList = []
      for (let i = 0; i < listaMedios.length; i++) {
        let medio = listaMedios[i]
        let horaMedio = 0
        let findMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

        if (medio.FECHA_AVISO && medio.FECHA_LLEGADA_BASE) {
          let diff = 0
          let fechaInicio = new Date(medio.FECHA_AVISO).getTime()
          let fechaFin = new Date(medio.FECHA_LLEGADA_BASE).getTime()
          diff = fechaFin - fechaInicio
          horaMedio = diff / (1000 * 60 * 60)
        }

        let medioTotalIndex = medioTotalList.findIndex(
          (x) => x.MEDIO === findMedio.MEDIO
        )
        if (medioTotalIndex > -1) {
          medioTotalList[medioTotalIndex].TOTAL =
            medioTotalList[medioTotalIndex].TOTAL + horaMedio
        } else {
          medioTotalList.push({ MEDIO: findMedio.MEDIO, TOTAL: horaMedio })
        }
      }

      // Creo la tabla de medios

      for (let i = 0; i < listaMedios.length; i++) {
        let medioTable = []
        let medio = listaMedios[i]
        let findMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

        let findMedioPersonal = this.$store.getters['medioPersonal/getPersonalAsignado'](medio.ID_MEDIO, medio.FECHA_AVISO)
        let findPersonal = findMedioPersonal ? this.$store.getters['personal/getPersonalByID'](findMedioPersonal.ID_PERSONAL) : null

        medioTable.push({
          text: findMedio.MEDIO,
          style: 'wrapText'
        })

        medioTable.push({
          text: findPersonal ? `${findPersonal.NOMBRE} ${findPersonal.APELLIDOS}` : 'Sin guardia',
          style: 'WrapText'
        })

        medioTable.push(findMedio.CATEGORIA)
        medioTable.push(
          this.$date.formatDate(medio.FECHA_AVISO, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_LLEGADA, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_SALIDA, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_LLEGADA_BASE, 'DD/MM/YYYY HH:mm')
        )

        // Calculo las horas de actuacion si el ciclo esta cerrado
        let horaMedio2 = 0
        if (medio.FECHA_AVISO && medio.FECHA_LLEGADA_BASE) {
          let diff2 = 0
          let fechaInicio2 = new Date(medio.FECHA_AVISO).getTime()
          let fechaFin2 = new Date(medio.FECHA_LLEGADA_BASE).getTime()
          diff2 = fechaFin2 - fechaInicio2
          horaMedio2 = diff2 / (1000 * 60 * 60)
        }
        // console.log(diff / (1000 * 60 * 60))

        // let totalMedio = 0
        medioTable.push(horaMedio2.toFixed(2))
        medioTable.push(
          medioTotalList
            .find((x) => x.MEDIO === findMedio.MEDIO)
            .TOTAL.toFixed(2)
        )

        tableMedios.push(medioTable)
      }

      let docDefinition = {
        // pageOrientation: 'landscape',
        content: [],
        defaultStyle: {
          fontSize: 9
        }
      }

      docDefinition.content.push(constants.headerPDF)
      docDefinition.content.push(
        {
          text: [
            { text: 'Fecha/hora informe: ', bold: true },
            this.$date.currentDate()
          ],
          margin: [0, 0, 0, 15]
        },
        {
          text: [
            {
              text: 'Datos del incendio',
              bold: true,
              fontSize: 15,
              alignment: 'center'
            }
          ],
          margin: [0, 0, 0, 15]
        },
        {
          ul: [
            {
              text: [
                { text: 'Localidad: ', bold: true },
                this.emergencia.LOCALIDAD
              ]
            },
            {
              text: [
                { text: 'Coordenadas: ', bold: true },
                this.emergencia.LATITUD + ', ' + this.emergencia.LONGITUD
              ]
            },
            {
              text: [
                { text: 'Detección: ', bold: true },
                this.deteccion.DETECCION
              ]
            },
            {
              text: [
                { text: 'Observaciones: ', bold: true },
                this.emergencia.OBSERVACIONES
              ]
            }
          ]
        },
        /* {
            columns: [
              { width: '*', text: '' },
              {
                width: 'auto',
                table: {
                  body: tableEstados
                }
              },
              { width: '*', text: '' }
            ]
          }, */
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            widths: [120, 120],
            headerRows: 1,
            body: tableEstados
          },
          margin: [120, 20, 75, 20],
          alignment: 'center'
        },
        {
          text: [
            { text: 'Medios', bold: true, fontSize: 15, alignment: 'center' }
          ],
          margin: [0, 0, 0, 15]
        } /*,
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              body: tableMedios
            },
            margin: [0, 15, 0, 20],
            alignment: 'center',
            width: '100%'
          } */
      )

      if (tieneMedios) {
        docDefinition.content.push({
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: tableMedios
          },
          layout: {
            hLineWidth: () => 0.5,
            vLineWidth: () => 0.5,
            paddingLeft: () => 5,
            paddingRight: () => 5,
            paddingTop: () => 5,
            paddingBottom: () => 5
          },
          margin: [0, 15, 0, 20],
          alignment: 'center',
          width: '*'
        })
      } else {
        docDefinition.content.push({
          text: [
            { text: 'No existen medios', bold: false, alignment: 'center' }
          ],
          margin: [0, 0, 0, 15]
        })
      }
      pdfMake
        .createPdf(docDefinition)
        .download(
          'Incendio_' +
            this.emergencia.LOCALIDAD +
            '_' +
            this.$date.now().format('YYYY_MM_DD_hh_mm')
        )
    },

    async generateExcelRecursos () {
      try {
        this.snackbarLoadingOn('Descargando informe de recursos...')
        const emergenciaBD = this.emergencia

        // this.$store.dispatch('medio/setEmergenciaMedio', emergencia)
        // console.log('incendio store', this.incendio)
        // console.log('generate Excel - incendio bd', incendioBD)

        const dateExport = this.$date.now()
        const dayExport = dateExport.startOf('day').format('DD/MM/YYYY')
        // console.log('medioPersonal', dayExport, dateExport)
        const mediosGuardiasArray = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(dayExport, 'DD/MM/YYYY'))
        const mediosGuardias = Object.fromEntries(mediosGuardiasArray.map(key => [key.ID_MEDIO, key]))
        // console.log('mediosGuardias', mediosGuardias)

        // Creo el excel de recursos
        const workbook = new ExcelJS.Workbook()

        workbook.creator = 'Wildfire Murcia'
        // workbook.lastModifiedBy = 'Wildfire Murcia'
        workbook.created = dateExport
        workbook.modified = dateExport
        workbook.lastPrinted = dateExport

        /** CONSTANTES FILAS */
        const filas = {
          titulo: 2,
          subtitulo: 3,
          fechaIncendio: 4,
          estado: 6,
          coordenadas: 7,
          deteccion: 6,
          observaciones: 7,
          emisionInforme: 9,
          tablaRecursos: 11
        }

        const tablas = {
          RECURSOS_1: 'TableRecursos1',
          RECURSOS_2: 'TableRecursos2',
          BRITEC: 'BRITEC',
          BRITEC_Guardia: 'BRITEC_Guardia'
        }

        /**********************************************/
        /** PRIMERA HOJA: Medios asignados a incendio */
        /**********************************************/
        const sheet = workbook.addWorksheet('Asignado', { properties: { tabColor: { argb: 'FFC0000' } }, pageSetup: { paperSize: 9, orientation: 'landscape' } })

        const worksheet = workbook.getWorksheet(sheet.id)

        /*******************************************************/
        /** ULT HOJA: Medios asignados a incendio con guardias */
        /*******************************************************/
        const sheet4 = workbook.addWorksheet('Asignado - guardias', { properties: { tabColor: { argb: 'FFC0000' } }, pageSetup: { paperSize: 9, orientation: 'landscape' } })

        const worksheet4 = workbook.getWorksheet(sheet4.id)

        /*******************************************************/

        this.addHeaderElements(worksheet, filas, workbook, dateExport)
        this.addHeaderElements(worksheet4, filas, workbook, dateExport)

        const listaMedios = []
        const listaMediosG = []
        const listaTablaMedios = []
        const listaTablaMediosG = []
        const listaTangos = []
        const listaTangosG = []
        const listaBritecs = []
        const listaTablaBritecs = []
        const listaTablaBritecsG = []
        const listaBritecMedio = []
        const listaBritecMedioG = []
        let sector = null

        if (Array.isArray(emergenciaBD.SECTORES)) {
          for (let i = 0; i < emergenciaBD.SECTORES.length; i++) {
            sector = emergenciaBD.SECTORES[i].SECTOR
            for (let j = 0; j < emergenciaBD.SECTORES[i].MEDIOS.length; j++) {
              const objMedio = emergenciaBD.SECTORES[i].MEDIOS[j].MEDIO
              let medioElemento = { ...emergenciaBD.SECTORES[i].MEDIOS[j], ...objMedio, ...objMedio.MEDIO_TIPO, ...objMedio.MEDIO_TIPO.CATEGORIA }
              const equipoObj = objMedio.MEDIO_EQUIPO.length > 0 ? objMedio.MEDIO_EQUIPO[0].EQUIPO : null

              const responsable = medioElemento.TIPO === constants.maestras.TIPO ? medioElemento.MEDIO : null

              const guardiaHoy = mediosGuardias[medioElemento.ID_MEDIO]
              medioElemento = {
                ...medioElemento,
                EQUIPO: equipoObj != null ? equipoObj.NOMBRE : null,
                EQUIPOS: objMedio.MEDIO_EQUIPO,
                SECTOR: sector,
                IS_GUARDIA: guardiaHoy,
                NUMERO_COMPONENTES_GUARDIA: guardiaHoy ? guardiaHoy.NUMERO_COMPONENTES : null,
                RESPONSABLE: responsable
              }

              if (medioElemento.CATEGORIA === constants.maestras.CATEGORIA.BRIGADA_TECNICA) {
                listaBritecMedio.push(medioElemento.MEDIO)
                listaBritecs.push(medioElemento)
                if (guardiaHoy) {
                  listaBritecMedioG.push(medioElemento.MEDIO)
                }
              } else if (medioElemento.TIPO === constants.maestras.TIPO.TANGO) {
                medioElemento.NUMERO_COMPONENTES = 0
                listaTangos.push(medioElemento)
              } else {
                listaMedios.push(medioElemento)
                if (guardiaHoy) {
                  listaMediosG.push(medioElemento)
                }
              }
            }
          }
        } else {
          console.error(emergenciaBD.SECTORES)
        }

        // console.log('listaMedios', listaMedios)
        // console.log('listaTablaBritecs', listaTablaBritecs)
        // console.log('listaTablaMedios', listaTablaMedios)

        const listaEquipos = this.mediosGroupBy(listaMedios, 'EQUIPO')
        const listaEquiposG = this.mediosGroupBy(listaMediosG, 'EQUIPO')
        // console.log('listaEquipos', listaEquipos)

        listaTangos.forEach(tangoElement => {
          const isInMediosEquipos = tangoElement.EQUIPOS.filter(equipoTango => Object.prototype.hasOwnProperty.call(listaEquipos, equipoTango.EQUIPO.NOMBRE))
          const isInMediosEquiposG = tangoElement.EQUIPOS.filter(equipoTango => Object.prototype.hasOwnProperty.call(listaEquipos, equipoTango.EQUIPO.NOMBRE))

          if (isInMediosEquipos && isInMediosEquipos.length === 1) {
            // Asignamos el equipo del que está asignado al incendio
            tangoElement.EQUIPO = isInMediosEquipos[0].EQUIPO.NOMBRE
            listaEquipos[tangoElement.EQUIPO].push(tangoElement)

            if (tangoElement.IS_GUARDIA && isInMediosEquiposG && isInMediosEquiposG.length === 1) {
              if (!listaEquiposG[tangoElement.EQUIPO]) {
                listaEquiposG[tangoElement.EQUIPO] = [] // inicializa por si es undefined (igual ha sido una chapuza)
              }
              listaEquiposG[tangoElement.EQUIPO].push(tangoElement)
            }
          } else if (isInMediosEquipos && isInMediosEquipos.length > 1) {
            console.warn(tangoElement.MEDIO, ' está en más de un equipo de los asignados: ', tangoElement.EQUIPOS)
          } else {
            console.warn(tangoElement.MEDIO, ' está en más de un equipo, lo asignamos al primero ', tangoElement.EQUIPO)
            listaEquipos[tangoElement.EQUIPO] = [tangoElement]
            if (tangoElement.IS_GUARDIA) {
              listaEquiposG[tangoElement.EQUIPO] = [tangoElement]
            }
          }
          // añadimos a lista de medios para formar tablas

          listaMedios.push(tangoElement)
          if (tangoElement.IS_GUARDIA) {
            listaMediosG.push(tangoElement)
          }
        })

        /** Generación de contenido de tabla de detalle medios */
        listaMedios.forEach(medioElemento => {
          const elementoTabla = [
            medioElemento.EQUIPO,
            medioElemento.MEDIO,
            medioElemento.NOMBRE,
            medioElemento.ISSI, // TETRA
            medioElemento.NUMERO_COMPONENTES_GUARDIA ? medioElemento.NUMERO_COMPONENTES_GUARDIA : medioElemento.NUMERO_COMPONENTES,
            null,
            medioElemento.TIPO === constants.maestras.TIPO.MOTOBOMBA ? 1 : null,
            medioElemento.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : null,
            medioElemento.TIPO === constants.maestras.TIPO.TANGO ? medioElemento.MEDIO : null,
            medioElemento.SECTOR,
            medioElemento.RESPONSABLE,
            this.$date.formatDate(medioElemento.FECHA_AVISO, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_LLEGADA, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_SALIDA, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_LLEGADA_BASE, constants.DATE_FORMAT_INFORME_NOBAR)
          ]

          listaTablaMedios.push(elementoTabla)
          if (medioElemento.IS_GUARDIA) {
            listaTablaMediosG.push(elementoTabla)
          }
        })

        /** Generación de contenido de tabla de detalle britecs */
        listaBritecs.forEach(medioElemento => {
          const elementoTabla = [
            medioElemento.EQUIPO,
            medioElemento.MEDIO,
            medioElemento.ISSI, // TETRA
            medioElemento.NUMERO_COMPONENTES_GUARDIA ? medioElemento.NUMERO_COMPONENTES_GUARDIA : medioElemento.NUMERO_COMPONENTES,
            null,
            medioElemento.TIPO === constants.maestras.TIPO.MOTOBOMBA ? 1 : null,
            medioElemento.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : null,
            medioElemento.TIPO === constants.maestras.TIPO.TANGO ? medioElemento.MEDIO : null,
            medioElemento.SECTOR,
            medioElemento.RESPONSABLE,
            this.$date.formatDate(medioElemento.FECHA_AVISO, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_LLEGADA, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_SALIDA, constants.DATE_FORMAT_INFORME_NOBAR),
            this.$date.formatDate(medioElemento.FECHA_LLEGADA_BASE, constants.DATE_FORMAT_INFORME_NOBAR)
          ]

          listaTablaBritecs.push(elementoTabla)
          if (medioElemento.IS_GUARDIA) {
            listaTablaBritecsG.push(elementoTabla)
          }
        })

        const listaTablaEquiposMedios = []
        const listaTablaEquiposMediosG = []

        for (const [key, medioItems] of Object.entries(listaEquipos)) {
          if (key !== 'null') {
            const listaTangos = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.TANGO).map(x => x.MEDIO).join(', ')
            const listaBravos = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.BRAVO).map(x => x.MEDIO).join(', ')
            const numAutobombas = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.AUTOBOMBA).length
            const numMotobombas = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.MOTOBOMBA).length

            const listaTetras = medioItems.map(x => x.ISSI).filter((item, pos, arr) => arr.indexOf(item) === pos).join(', ')

            const elementoEquipo = [
              key,
              medioItems.map(x => x.MEDIO).join(', '),
              listaTetras, // TETRA
              this.getNumComponentes(medioItems),
              numAutobombas !== 0 ? numAutobombas : GUION_EMPTY, // cuenta 1 conductor si hay autobomba
              numMotobombas !== 0 ? numMotobombas : GUION_EMPTY,
              numAutobombas !== 0 ? numAutobombas : GUION_EMPTY,
              listaTangos.length !== 0 ? listaTangos : GUION_EMPTY,
              medioItems.map(x => x.SECTOR).filter((item, pos, arr) => arr.indexOf(item) === pos).join(', '),
              listaBravos.length !== 0 ? listaBravos : GUION_EMPTY,
              this.getMinDate(medioItems, 'FECHA_AVISO'),
              this.getMinDate(medioItems, 'FECHA_LLEGADA'),
              this.getMaxDate(medioItems, 'FECHA_SALIDA'),
              this.getMaxDate(medioItems, 'FECHA_LLEGADA_BASE')
            ]
            listaTablaEquiposMedios.push(elementoEquipo)
          } else {
            const elementosEquipoNull = medioItems.map(x => [
              'Sin equipo',
              x.MEDIO,
              x.ISSI,
              this.getNumComponentes([x]),
              x.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : GUION_EMPTY, // cuenta 1 conductor si hay autobomba
              x.TIPO === constants.maestras.TIPO.MOTOBOMBA ? 1 : GUION_EMPTY,
              x.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : GUION_EMPTY,
              x.TIPO === constants.maestras.TIPO.TANGO ? x.MEDIO : GUION_EMPTY,
              x.SECTOR,
              x.TIPO === constants.maestras.TIPO.BRAVO ? x.MEDIO : GUION_EMPTY,
              this.getMinDate([x], 'FECHA_AVISO'),
              this.getMinDate([x], 'FECHA_LLEGADA'),
              this.getMaxDate([x], 'FECHA_SALIDA'),
              this.getMaxDate([x], 'FECHA_LLEGADA_BASE')
            ])
            elementosEquipoNull.forEach(element => {
              listaTablaEquiposMedios.push(element)
            })
          }
        }

        for (const [key, medioItems] of Object.entries(listaEquiposG)) {
          if (key !== 'null') {
            const listaTangos = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.TANGO).map(x => x.MEDIO).join(', ')
            const listaBravos = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.BRAVO).map(x => x.MEDIO).join(', ')
            const numAutobombas = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.AUTOBOMBA).length
            const numMotobombas = medioItems.filter(x => x.TIPO === constants.maestras.TIPO.MOTOBOMBA).length

            const listaTetras = medioItems.map(x => x.ISSI).filter((item, pos, arr) => arr.indexOf(item) === pos).join(', ')

            const elementoEquipo = [
              key,
              medioItems.map(x => x.MEDIO).join(', '),
              listaTetras, // TETRA
              this.getNumComponentes(medioItems),
              numAutobombas !== 0 ? numAutobombas : GUION_EMPTY, // cuenta 1 conductor si hay autobomba
              numMotobombas !== 0 ? numMotobombas : GUION_EMPTY,
              numAutobombas !== 0 ? numAutobombas : GUION_EMPTY,
              listaTangos.length !== 0 ? listaTangos : GUION_EMPTY,
              medioItems.map(x => x.SECTOR).filter((item, pos, arr) => arr.indexOf(item) === pos).join(', '),
              listaBravos.length !== 0 ? listaBravos : GUION_EMPTY,
              this.getMinDate(medioItems, 'FECHA_AVISO'),
              this.getMinDate(medioItems, 'FECHA_LLEGADA'),
              this.getMaxDate(medioItems, 'FECHA_SALIDA'),
              this.getMaxDate(medioItems, 'FECHA_LLEGADA_BASE')
            ]
            listaTablaEquiposMediosG.push(elementoEquipo)
          } else {
            const elementosEquipoNull = medioItems.map(x => [
              'Sin equipo',
              x.MEDIO,
              x.ISSI,
              this.getNumComponentes([x]),
              x.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : GUION_EMPTY, // cuenta 1 conductor si hay autobomba
              x.TIPO === constants.maestras.TIPO.MOTOBOMBA ? 1 : GUION_EMPTY,
              x.TIPO === constants.maestras.TIPO.AUTOBOMBA ? 1 : GUION_EMPTY,
              x.TIPO === constants.maestras.TIPO.TANGO ? x.MEDIO : GUION_EMPTY,
              x.SECTOR,
              x.TIPO === constants.maestras.TIPO.BRAVO ? x.MEDIO : GUION_EMPTY,
              this.getMinDate([x], 'FECHA_AVISO'),
              this.getMinDate([x], 'FECHA_LLEGADA'),
              this.getMaxDate([x], 'FECHA_SALIDA'),
              this.getMaxDate([x], 'FECHA_LLEGADA_BASE')
            ])
            elementosEquipoNull.forEach(element => {
              listaTablaEquiposMediosG.push(element)
            })
          }
        }
        // console.log('listaTablaEquiposMedios', listaTablaEquiposMedios)

        // Fill britec lists by types

        const indexRowTablaRecursos = filas.tablaRecursos

        this.addRecursosTable(tablas.RECURSOS_1, worksheet, listaMedios, indexRowTablaRecursos, listaTablaEquiposMedios)
        this.addRecursosTable(tablas.RECURSOS_2, worksheet4, listaMediosG, indexRowTablaRecursos, listaTablaEquiposMediosG)

        const refCellNextTable = indexRowTablaRecursos + 3 + listaMedios.length
        const refCellNextTableG = indexRowTablaRecursos + 3 + listaMediosG.length

        const rowNumBritecTotales = refCellNextTable + 5 + 1
        const rowNumBritecTotalesG = refCellNextTableG + 5 + 1

        this.addBritecTable(tablas.BRITEC, listaBritecMedio, worksheet, refCellNextTable, indexRowTablaRecursos, listaTablaEquiposMedios, rowNumBritecTotales)
        this.addBritecTable(tablas.BRITEC_Guardia, listaBritecMedioG, worksheet4, refCellNextTableG, indexRowTablaRecursos, listaTablaEquiposMediosG, rowNumBritecTotalesG)

        this.addObsAndCounter(refCellNextTable, worksheet, rowNumBritecTotales, tablas.RECURSOS_1, tablas.BRITEC)
        this.addObsAndCounter(refCellNextTableG, worksheet4, rowNumBritecTotalesG, tablas.RECURSOS_2, tablas.BRITEC_Guardia)
        // ++++++++++++++++++++++++++++++++++++++++++++
        this.addCommonStyleHojasRecursos(worksheet, indexRowTablaRecursos)
        this.addCommonStyleHojasRecursos(worksheet4, indexRowTablaRecursos)
        // --------------------------------------
        // --------------------------------------
        // SEGUNDA HOJA: MEDIOS
        // create a sheet with red tab colour
        const sheet2 = workbook.addWorksheet('Listado medios', { properties: { tabColor: { argb: 'FFC0000' } }, pageSetup: { paperSize: 9, orientation: 'landscape' } })

        const worksheet2 = workbook.getWorksheet(sheet2.id)

        worksheet2.addTable({
          name: 'TableHistorico',
          ref: 'B3',
          headerRow: true,
          totalsRow: true,
          style: {
            theme: 'TableStyleMedium18',
            showRowStripes: true
          },
          columns: [
            { name: 'Equipo', totalsRowLabel: 'TOTAL:', filterButton: true },
            { name: 'Medio', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Nombre', filterButton: true },
            { name: 'TETRA', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Componentes/n(+ conductor)', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Conductor', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Motobomba', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Autobomba', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Jefe Recurso', totalsRowFunction: 'count', filterButton: false },
            { name: 'Sector', filterButton: true },
            { name: 'Responsable', totalsRowFunction: 'count', filterButton: false },
            { name: 'Fecha Aviso', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Llegada', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Salida', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Llegada Base', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } }
          ],
          rows: listaTablaMedios// listaTablaMedios
          /* [
          [1, 'BRIFOR Jumilla', 888, 5, 1, null, null, 'TANGO JUMILLA', 'E']] */
        })

        worksheet2.getColumn('B').width = 20
        worksheet2.getColumn('C').width = 20
        // worksheet.getColumn('D').width = 20 // TETRA
        worksheet2.getColumn('E').width = 20
        worksheet2.getColumn('F').width = 10 // Conductor
        // worksheet.getColumn('G').width = 20 // Motobomba
        // worksheet.getColumn('H').width = 20 // Autobomba
        worksheet2.getColumn('I').width = 20
        worksheet2.getColumn('J').width = 20
        worksheet2.getColumn('K').width = 20

        worksheet2.getColumn('L').width = 20
        worksheet2.getColumn('M').width = 20
        worksheet2.getColumn('N').width = 20
        worksheet2.getColumn('O').width = 20

        // --------------------------------------
        // TERCERA HOJA: BRITECS
        // create a sheet with red tab colour
        const sheet3 = workbook.addWorksheet('Listado britecs', { properties: { tabColor: { argb: 'FFC0000' } }, pageSetup: { paperSize: 9, orientation: 'landscape' } })

        const worksheet3 = workbook.getWorksheet(sheet3.id)

        worksheet3.addTable({
          name: 'TableBritecs',
          ref: 'B3',
          headerRow: true,
          totalsRow: true,
          style: {
            wrapText: {
              alignment: 'left',
              fontSize: 9,
              margin: [5, 2, 5, 2]
            },
            theme: 'TableStyleMedium18',
            showRowStripes: true
          },
          columns: [
            { name: 'Equipo', totalsRowLabel: 'TOTAL:', filterButton: true },
            { name: 'Medio', totalsRowFunction: 'sum', filterButton: false },
            { name: 'TETRA', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Componentes/n(+ conductor)', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Conductor', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Motobomba', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Autobomba', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Jefe Recurso', totalsRowFunction: 'count', filterButton: false },
            { name: 'Sector', filterButton: true },
            { name: 'Responsable', totalsRowFunction: 'count', filterButton: false },
            { name: 'Fecha Aviso', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Llegada', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Salida', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
            { name: 'Fecha Llegada Base', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } }
          ],
          rows: listaTablaBritecs
          /* [
          [1, 'BRIFOR Jumilla', 888, 5, 1, null, null, 'TANGO JUMILLA', 'E']] */
        })

        worksheet3.getColumn('B').width = 20
        worksheet3.getColumn('C').width = 20
        // worksheet.getColumn('D').width = 20 // TETRA
        worksheet3.getColumn('E').width = 20
        worksheet3.getColumn('F').width = 10 // Conductor
        // worksheet.getColumn('G').width = 20 // Motobomba
        // worksheet.getColumn('H').width = 20 // Autobomba
        worksheet3.getColumn('I').width = 20
        worksheet3.getColumn('J').width = 20
        worksheet3.getColumn('K').width = 20

        worksheet3.getColumn('L').width = 20
        worksheet3.getColumn('M').width = 20
        worksheet3.getColumn('N').width = 20
        worksheet3.getColumn('O').width = 20

        // --------------------------------------
        // --------------------------------------

        // Finished the workbook.

        const exportFilename = 'Incendio_' + this.emergencia.LOCALIDAD + '_' + dateExport.format('YYYY_MM_DD')// this.$date.now().format('YYYY_MM_DD_hh_mm')

        workbook.xlsx.writeBuffer().then(function (data) {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          FileSaver.saveAs(blob, exportFilename)
        // 'fileName.xlsx'
        })
      } catch (error) {
        console.error('Error generación excel recursos: ', error)
        this.$notify({
          title: 'Generación de excel errónea',
          text: 'Ha ocurrido un error en la generación del informe: ' + error.message
        })
      } finally {
        this.snackbarLoadingOff()
      }
    },

    setLineBorder (worksheet, start, end, borderWidth, borderColor, onlyTop) {
      const borderStyle = {
        style: borderWidth,
        color: { argb: borderColor }
      }
      for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i)
        const bottomBorderCell = worksheet.getCell(end.row, i)
        topBorderCell.border = {
          ...topBorderCell.border,
          top: borderStyle
        }
        if (!onlyTop) {
          bottomBorderCell.border = {
            ...bottomBorderCell.border,
            bottom: borderStyle
          }
        }
      }
    },

    addHeaderElements (worksheet, filas, workbook, dateExport) {
      const cellTitle = worksheet.getCell('B' + filas.titulo)
      cellTitle.value = 'Recursos participantes IF'
      cellTitle.font = {
        name: 'Helvetica',
        family: 4,
        size: 60,
        bold: true,
        color: { argb: constants.cincolorVerdeMurcia }
      }

      const cellSubitle = worksheet.getCell('B' + filas.subtitulo)
      cellSubitle.value = 'IF: ' + (incendio?.NOMBRE != null ? incendio?.NOMBRE : '') + this.emergencia.LOCALIDAD + ' (' + this.emergencia.MUNICIPIO + ')'
      cellSubitle.font = {
        name: 'Helvetica',
        family: 4,
        size: 28,
        bold: true,
        color: { argb: constants.colorGray1 }
      }

      const cellFechaIncendio = worksheet.getCell('B' + filas.fechaIncendio)
      cellFechaIncendio.value = this.$date.formatDate(this.emergencia.FECHA_CREACION, constants.DATE_FORMAT_INFORME) + ' h'
      cellFechaIncendio.font = {
        name: 'Helvetica',
        family: 4,
        size: 24,
        bold: true,
        ...constants.styleColorGray2
      }

      // add image to workbook by filename
      const regMurciaImg = this.getImagenByLabel('region_murcia')
      const imageId1 = workbook.addImage({
        base64: regMurciaImg.base64,
        extension: regMurciaImg.extension
      })

      const udifMurciaImg = this.getImagenByLabel('udif_murcia')
      const imageId2 = workbook.addImage({
        base64: udifMurciaImg.base64,
        extension: udifMurciaImg.extension
      })
      // insert an image over area
      worksheet.addImage(imageId1, 'L2:M3')
      worksheet.addImage(imageId2, {
        tl: { col: 13, row: 1.2 },
        ext: { width: 120, height: 120 }
      })

      this.setLineBorder(worksheet, { row: filas.estado, col: 2 }, { row: filas.estado + 1, col: 14 }, 'medium', constants.colorVerdeMurcia, false)

      const estadoActualEmergencia = (this.emergencia.ESTADOS != null && this.emergencia.ESTADOS.length > 0 ? this.emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO : '')

      const cellEstadoEmergencia = worksheet.getCell('B' + filas.estado)
      cellEstadoEmergencia.value = 'Estado: ' + estadoActualEmergencia
      cellEstadoEmergencia.font = {
        ...constants.styleWith12NoBold,
        ...constants.styleColorGray2
      }
      const cellCoordenadas = worksheet.getCell('B' + filas.coordenadas)
      cellCoordenadas.value = 'Coordenadas: ' + this.emergencia.LATITUD + ', ' + this.emergencia.LONGITUD
      cellCoordenadas.font = {
        ...constants.styleWith12NoBold,
        ...constants.styleColorGray2
      }

      const cellDeteccion = worksheet.getCell('D' + filas.deteccion)
      cellDeteccion.value = 'Detección: ' + (this.deteccion.DETECCION ? this.deteccion.DETECCION : '')
      cellDeteccion.font = {
        ...constants.styleWith12NoBold,
        ...constants.styleColorGray2
      }

      const cellObservaciones = worksheet.getCell('D' + filas.observaciones)
      cellObservaciones.value = 'Observaciones: ' + (this.emergencia.OBSERVACIONES ? this.emergencia.OBSERVACIONES : '')
      cellObservaciones.font = {
        ...constants.styleWith12NoBold,
        ...constants.styleColorGray2
      }

      const cellEmisionInforme = worksheet.getCell('B' + filas.emisionInforme)
      cellEmisionInforme.value = 'Emisión informe: ' + dateExport.format(constants.DATE_FORMAT_INFORME) + ' h'
      cellEmisionInforme.font = {
        ...constants.styleWith12NoBold,
        ...constants.styleWithBold,
        color: { argb: constants.colorBlue1 }
      }
    },

    addRecursosTable (nameTable, worksheet, listaMedios, indexRowTablaRecursos, listaTablaEquiposMedios) {
      const formulaTotalJefeRecurso = '=COUNTIF(' + nameTable + '[[Jefe Recurso]],"<>' + GUION_EMPTY + '")'
      const formulaTotalResponsable = '=COUNTIF(' + nameTable + '[[Responsable]],"<>' + GUION_EMPTY + '")'
      const formulaTotalEmptyGuion = '="-"'
      const formulaTotalRecursos = '="' + (listaMedios.length) + '"'

      worksheet.addTable({
        name: nameTable,
        ref: 'B' + indexRowTablaRecursos.toString(),
        headerRow: true,
        totalsRow: true,
        style: {
          theme: 'TableStyleMedium18',
          showRowStripes: true,
          width: 20
        },
        columns: [
          { name: 'Equipo', totalsRowLabel: 'TOTAL:', filterButton: true },
          { name: 'Recursos', totalsRowFunction: 'custom', totalsRowFormula: formulaTotalRecursos, filterButton: false },
          { name: 'TETRA', totalsRowFunction: 'custom', totalsRowFormula: formulaTotalEmptyGuion, filterButton: false },
          { name: 'Componentes/n(+ conductor)', totalsRowFunction: 'sum', filterButton: false },
          { name: 'Conductor', totalsRowFunction: 'sum', filterButton: false },
          { name: 'Motobomba', totalsRowFunction: 'sum', filterButton: false },
          { name: 'Autobomba', totalsRowFunction: 'sum', filterButton: false },
          { name: 'Jefe Recurso', totalsRowFunction: 'custom', totalsRowFormula: formulaTotalJefeRecurso, filterButton: false }, // '=COUNTIF([Jefe Recurso];"<>-")' '=SUBTOTAL(103;[Jefe Recurso])'
          { name: 'Sector', totalsRowFunction: 'custom', totalsRowFormula: formulaTotalEmptyGuion, filterButton: true },
          { name: 'Responsable', totalsRowFunction: 'custom', totalsRowFormula: formulaTotalResponsable, filterButton: false },
          { name: 'Fecha Aviso', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
          { name: 'Fecha Llegada', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
          { name: 'Fecha Salida', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } },
          { name: 'Fecha Llegada Base', style: { numFmt: constants.DATE_FORMAT_INFORME_NOBAR } }
        ],
        rows: listaTablaEquiposMedios
        /* [
          [1, 'BRIFOR Jumilla', 888, 5, 1, null, null, 'TANGO JUMILLA', 'E']] */
      })

      for (let index = indexRowTablaRecursos; index <= indexRowTablaRecursos + listaTablaEquiposMedios.length; index++) {
        worksheet.getCell('K' + index.toString()).border = {
          right: { style: 'thick', color: { argb: constants.cincolorVerdeMurcia } }
        }
        // worksheet.getRow(index).height = 30
      }

      const fechaAvisoCol = worksheet.getColumn('K')
      fechaAvisoCol.width = 20
      worksheet.getColumn('B').width = 20
      worksheet.getColumn('C').width = 20
      // worksheet.getColumn('D').width = 20 // TETRA
      worksheet.getColumn('E').width = 20
      worksheet.getColumn('F').width = 10 // Conductor
      // worksheet.getColumn('G').width = 20 // Motobomba
      // worksheet.getColumn('H').width = 20 // Autobomba
      worksheet.getColumn('I').width = 20
      worksheet.getColumn('J').width = 20
      worksheet.getColumn('K').width = 20

      worksheet.getColumn('L').width = 20
      worksheet.getColumn('M').width = 20
      worksheet.getColumn('N').width = 20
      worksheet.getColumn('O').width = 20
    },

    addBritecTable (tableName, listaBritecMedio, worksheet, refCellNextTable, indexRowTablaRecursos, listaTablaEquiposMedios, rowNumBritecTotales) {
      const golfElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.GOLF)
      const mikeElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.MIKE)
      const zuluElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.ZULU)
      const unaifElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.UNAIF)
      const coaElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.COA)
      const entaElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.ENTA)
      const brilogElements = this.getBritecElements(listaBritecMedio, constants.maestras.TIPO.BRILOG)

      // add a table to a sheet
      worksheet.addTable({
        name: tableName,
        ref: 'B' + refCellNextTable.toString(),
        headerRow: true,
        totalsRow: true,
        style: {
          theme: 'TableStyleMedium18',
          showRowStripes: true
        },
        columns: [
          { name: 'BRITEC', totalsRowLabel: 'TOTAL:', filterButton: false },
          { name: 'GOLF', totalsRowFunction: 'count', filterButton: false },
          { name: 'ZULU', totalsRowFunction: 'count', filterButton: false },
          { name: 'UNAIF', totalsRowFunction: 'count', filterButton: false },
          { name: 'MIKE', totalsRowFunction: 'count', filterButton: false },
          { name: 'COA', totalsRowFunction: 'count', filterButton: false },
          { name: 'ENTA', totalsRowFunction: 'count', filterButton: false },
          { name: 'BRILOG', totalsRowFunction: 'count', filterButton: false }
        ],
        rows: [
          [null, golfElements[0], zuluElements[0], unaifElements[0], mikeElements[0], coaElements[0], entaElements[0], brilogElements[0]],
          [null, golfElements[1], zuluElements[1], unaifElements[1], mikeElements[1], coaElements[1], entaElements[1], brilogElements[1]],
          [null, golfElements[2], zuluElements[2], unaifElements[2], mikeElements[2], coaElements[2], entaElements[2], brilogElements[2]],
          [null, golfElements[3], zuluElements[3], unaifElements[3], mikeElements[3], coaElements[3], entaElements[3], brilogElements[3]],
          [null, golfElements[4], zuluElements[4], unaifElements[4], mikeElements[4], coaElements[4], entaElements[4], brilogElements[4]]
        ]
      })

      const rowHeaderTablaRecursos = worksheet.getRow(indexRowTablaRecursos) // headers tabla recursos

      rowHeaderTablaRecursos.height = 44
      // Iterate over all non-null cells in a row
      rowHeaderTablaRecursos.eachCell(function (cell, colNumber) {
        cell.alignment = constants.styleAlignment1
        cell.font = constants.styleFont13NoBold
      })
      const rowTotalesRecursos = worksheet.getRow(indexRowTablaRecursos + listaTablaEquiposMedios.length + 1) // totales

      rowTotalesRecursos.height = 40
      rowTotalesRecursos.eachCell(function (cell, colNumber) {
        cell.alignment = constants.styleAlignment1
        cell.font = { ...constants.styleFont13NoBold, bold: true }
        cell.fill = constants.styleFillSolidVerdeMurcia
      })

      const rowHeaderTablaBritec = worksheet.getRow(refCellNextTable) // headers tabla britec
      rowHeaderTablaBritec.height = 44
      rowHeaderTablaBritec.eachCell(function (cell, colNumber) {
        cell.alignment = constants.styleAlignment1
        cell.font = constants.styleFont13NoBold
      })

      const rowTotalesBritec = worksheet.getRow(rowNumBritecTotales) // totales
      rowTotalesBritec.height = 40
      rowTotalesBritec.eachCell(function (cell, colNumber) {
        cell.alignment = constants.styleAlignment1
        cell.font = { ...constants.styleFont13NoBold, bold: true }
        cell.fill = constants.styleFillSolidVerdeMurcia
      })
    },

    addCommonStyleHojasRecursos (worksheet, indexRowTablaRecursos) {
      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          if (rowNumber >= indexRowTablaRecursos) { cell.alignment = constants.styleAlignment1 }
        })
      })
    },

    addObsAndCounter (refCellNextTable, worksheet, rowNumBritecTotales, tableNameRecursos, tableNameBritec) {
      /**
         * OBSERVACIONES
         */
      const cellKeyObservaciones = 'K' + refCellNextTable
      const cellKeyObservacionesEnd = 'L' + refCellNextTable
      const cellObservacionesManual = worksheet.getCell(cellKeyObservaciones)
      cellObservacionesManual.value = 'Observaciones'
      cellObservacionesManual.font = {
        ...constants.styleFont13NoBold,
        ...constants.styleWithBold
        // ...constants.styleColorGray2
      }
      cellObservacionesManual.alignment = constants.styleAlignment1
      cellObservacionesManual.fill = constants.styleFillSolidVerdeMurcia

      worksheet.mergeCells(cellKeyObservaciones, cellKeyObservacionesEnd)
      const cellKeyObservacionesInput = 'K' + (refCellNextTable + 1)
      const cellKeyObservacionesInputEnd = 'L' + rowNumBritecTotales
      worksheet.mergeCells(cellKeyObservacionesInput, cellKeyObservacionesInputEnd)
      /**
         * PERSONAL INTERVINIENTE
         */
      const cellKeyPersonal = 'M' + refCellNextTable
      const cellKeyPersonalEnd = 'N' + refCellNextTable
      const cellPersonalInterviniente = worksheet.getCell(cellKeyPersonal)
      cellPersonalInterviniente.value = 'Personal interviniente'
      cellPersonalInterviniente.font = {
        ...constants.styleFont13NoBold,
        ...constants.styleWithBold
      }
      cellPersonalInterviniente.alignment = constants.styleAlignment1
      cellPersonalInterviniente.fill = constants.styleFillSolidVerdeMurcia

      worksheet.mergeCells(cellKeyPersonal, cellKeyPersonalEnd)
      const cellKeyPersonalCounter = 'M' + (refCellNextTable + 1)
      const cellKeyPersonalCounterEnd = 'N' + rowNumBritecTotales
      const cellPersonalCounter = worksheet.getCell(cellKeyPersonalCounter)
      cellPersonalCounter.font = {
        ...constants.styleFont13NoBold,
        ...constants.styleWithBold,
        size: 75
      }
      cellPersonalCounter.alignment = constants.styleAlignment1
      cellPersonalCounter.fill = {
        ...constants.styleFillSolidVerdeMurcia,
        fgColor: { argb: constants.colorRojo } // Override color a rojo
      }
      cellPersonalCounter.value = {
        formula: '=SUM(' + tableNameRecursos + '[[#Totals],[Componentes/n(+ conductor)]],' + tableNameRecursos + '[[#Totals],[Jefe Recurso]],' + tableNameRecursos + '[[#Totals],[Responsable]],' + tableNameBritec + '[[#Totals],[GOLF]:[BRILOG]])'
      }
      worksheet.mergeCells(cellKeyPersonalCounter, cellKeyPersonalCounterEnd)
    },

    getBritecElements (listaBritecMedio, tipoBritec) {
      const matchingElements = listaBritecMedio.filter(x => x.includes(tipoBritec))
      return matchingElements.map(elem => elem.replace(tipoBritec + ' ', ''))
    },

    mediosGroupBy (array, key) {
      return array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key]
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
        return objectsByKeyValue
      }, {})
    },

    // method to add more conditions if necessary
    getNumComponentes (arrayMedios) {
      return arrayMedios.map(x => x.NUMERO_COMPONENTES_GUARDIA ? x.NUMERO_COMPONENTES_GUARDIA : x.NUMERO_COMPONENTES).reduce((sum, num) => sum + num, 0)
    },

    getMinDate (array, field) {
      const res = Math.min.apply(null, array.map(x => new Date(x[field])))
      if (res === 0) {
        return GUION_EMPTY
      } else {
        return new Date(res)
      }
    },

    getMaxDate (array, field) {
      const res = Math.max.apply(null, array.map(x => new Date(x[field])))
      if (res === 0) {
        return GUION_EMPTY
      } else {
        return new Date(res)
      }
    },

    cancelAddImagenes () {
      this.showDialogImagen = false
    },
    deletedEmergencia () {
      this.showDialogDeleteEmergencia = false
      this.exit()
    },
    cancelDeleteEmergencia () {
      this.showDialogDeleteEmergencia = false
    },

    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = 'transparent'
    },
    setEvents () {
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize (e) {
        if (e.clientX < vm.navigation.minWidth) return
        document.body.style.cursor = 'ew-resize'
        let f =
          direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }
  },

  mounted () {
    this.setBorderWidth()
    this.setEvents()
  }
}
</script>

<style scoped></style>
