import request from '../request'

export default {
  deleteImagenEmergencia: function (data) {
    return request.delete('emergencia_imagen/' + data.ID_EMERGENCIA + '/' + data.ID_EMERGENCIA_IMAGEN)
  },
  getImagenesEmergencia: function (data) {
    return request.get('emergencia_imagen/' + data)
  },
  setImagenesEmergencia: function (data) {
    return request.post('emergencia_imagen/' + data.ID_EMERGENCIA, data.imagenes)
  }
}
