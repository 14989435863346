<template>
  <v-card tile height="100%" width="100%">
    <vx-dialog-add-turno :show="showAddTurno" @cancelar="showAddTurno = false" />
    <vx-dialog-edit-turno :show="showEditTurno" :turno="turnoSeleccionado" @cancelar="showEditTurno = false" />
    <vx-dialog-disable-turno :show="showDisableTurno" :turno="turnoSeleccionado" @cancelar="showDisableTurno = false" />

    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Turnos</v-toolbar-title>
      <v-spacer />
      <v-checkbox v-model="mostrarDeshabilitados" label="Deshabilitados" dense hide-details class="mr-4" />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn x-small v-bind="attrs" v-on="on" fab color="secondary" @click="showAddTurno = true">
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir Turno</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row style="height: calc(50vh - 138px); overflow-y:auto;">
          <v-container>
            <v-row class="pa-0 ma-0" cols="12" v-for="turno in turnosFiltrados" :key="turno.ID_TURNO">
              <v-col class="pa-1 ma-0" cols="5">{{ turno.NOMBRE }}</v-col>
              <v-col class="pa-1 ma-0" cols="4">{{ turno.HORA_ENTRADA }} - {{ turno.HORA_SALIDA }}</v-col>
              <v-col class="pa-1 ma-0" cols="1">
                <v-btn icon small color="blue" @click="editarTurno(turno)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col class="pa-1 ma-0" cols="1">
                <v-btn icon small :color="turno.BORRADO ? 'green' : 'grey'" @click="toggleTurno(turno)">
                  <v-icon>{{ turno.BORRADO ? 'mdi-check-circle' : 'mdi-cancel' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DialogAddTurno from './DialogAddTurno.vue'
import DialogEditTurno from './DialogEditTurno.vue'
import DialogDisableTurno from './DialogDisableTurno.vue'

export default {
  components: {
    'vx-dialog-add-turno': DialogAddTurno,
    'vx-dialog-edit-turno': DialogEditTurno,
    'vx-dialog-disable-turno': DialogDisableTurno
  },
  data () {
    return {
      showAddTurno: false,
      showEditTurno: false,
      showDisableTurno: false,
      turnoSeleccionado: null,
      mostrarDeshabilitados: false
    }
  },
  computed: {
    ...mapGetters('medioPersonal', ['turnos']),

    turnosFiltrados () {
      return this.mostrarDeshabilitados ? this.turnos.filter(turno => turno.BORRADO) : this.turnos
    }
  },
  methods: {
    ...mapActions('medioPersonal', ['removeTurno', 'updateTurno']),

    editarTurno (turno) {
      this.turnoSeleccionado = turno
      this.showEditTurno = true
    },

    toggleTurno (turno) {
      const nuevoEstado = turno.BORRADO ? 0 : 1
      this.updateTurno({ ...turno, BORRADO: nuevoEstado }).then(() => {
        this.$store.dispatch('medioPersonal/fetchTurnos')
      })
    }

  }
}
</script>
