<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Deshabilitar turno</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-container>
          <v-alert type="warning" dense>
            Al deshabilitar este turno, no podrá ser asignado a nuevas guardias, pero las existentes no se verán afectadas.
          </v-alert>
          <p class="text-center">¿Está seguro de que desea deshabilitar el turno <strong>{{ turno && turno.NOMBRE }}</strong>?</p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text small color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text small color="green" @click="disableTurno">Deshabilitar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    show: Boolean,
    turno: Object
  },
  methods: {
    ...mapActions('medioPersonal', ['removeTurno']),
    async disableTurno () {
      if (this.turno) {
        await this.removeTurno({
          ID_TURNO: this.turno.ID_TURNO,
          BORRADO: 1
        })
        await this.$store.dispatch('medioPersonal/fetchTurnos')
        this.cancelar()
      }
    },
    cancelar () {
      this.$emit('cancelar')
    }
  }
}
</script>

  <style scoped>
  </style>
