import request from '../request'

export default {
  getAllIncendios: function () {
    return request.get('lista_incendios')
  },

  getIncendios: function (daysBack, codCCAA, codProv) {
    return request.get(`incendio/${daysBack}/${codCCAA}/${codProv}`)
  },

  getTiposIncendioEstado: function () {
    return request.get('tipo_incendio_estado')
  },
  getTiposIncendioNivel: function () {
    return request.get('tipo_incendio_nivel')
  },

  getTiposDeteccion: function () {
    return request.get('tipo_deteccion')
  },

  addIncendio: function (data) {
    return request.post('incendio', data)
  },

  getIncendio: function (data) {
    return request.get(`incendio_id/${data.ID_INCENDIO}`, data)
  },

  addNivelIncendio: function (data) {
    return request.post('incendio_nivel', data)
  },

  deleteNivelIncendio: function (data) {
    return request.delete('incendio_nivel', { data: data })
  },
  editNivelIncendio: function (data) {
    return request.put('incendio_nivel', data)
  },

  editAlineacionesCampbell: function (data) {
    return request.put(`alineaciones_campbell/${data.ID_ALINEACIONES_CAMPBELL}`, data)
  },

  addImportedPerimetro: function (data) {
    return request.post('perimetro/addImportedPerimetro', data)
  }
}
