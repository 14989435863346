<template>
  <v-dialog v-model="show" persistent max-width="420px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Nivel del incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-container fluid>
            <!-- Aviso -->
            <v-row class="pt-2">
              <v-col>
                <v-select
                  :items="tiposIncendioNivel" item-text="INCENDIO_NIVEL" v-model="nivel"
                  label="Nivel del incendio" outlined hide-details dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <vx-date-text-edit
                  v-model="fechaInicio" :min="fechaInicioMin" :max="fechaInicioMax" @focus="setCurrentDate(0)" :disabled="!nivel" label="Fecha de inicio" required not-future outlined dense
                />
              </v-col>

              <v-col sm="6">
                <vx-date-text-edit v-model="fechaFin" :min="fechaFinMin" @focus="setCurrentDate(1)" :disabled="!nivel || !fechaInicio || fechaInicio.length !== mask.length" label="Fecha de fin" not-future outlined dense />
              </v-col>
            </v-row>
            <v-row />
          </v-container>
        </v-form> <!-- Llegada -->
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid " @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import emergencias from '@/api/emergencias'
import { mapGetters } from 'vuex'
/* eslint-disable */

export default {
  props: {
    show: Boolean,
    idIncendio: String,
    emergencia: Object
  },

  data: () => ({
    //valid: true,
    //dateFormat: 'DD/MM/YYYY HH:mm',
    //fechaFinMin : null,
    nivelesPosibles: ["Nivel 1", "Nivel 2"],
    nivel: null,
    fechaInicio: null,
    fechaFin: null,
    nivelIncendio: null,

    fechaInicioMin: null,
    fechaInicioMax: null,
    fechaFinMin: null,
    mask: '##/##/#### ##:##'

  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    ...mapGetters ('incendio', [
      'tiposIncendioNivel'
    ]),
    /*fechaInicioMin (){
      let fechaMin = null
      if(this.incendio){
        fechaMin = this.incendio.NIVELES.length == 0 ? this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA) : this.$date.parseDate(this.incendio.NIVELES[0].FECHA_FIN)
        this.fechaFinMinima = fechaMin.add(1, 'minute')
      }
      return fechaMin
    },
    acceptDisable (){
      return (!this.nivel || !this.fechaInicio) || !this.valid
    },
    fechaInicioDisabled(){
      return !this.nivel
    },
    fechaFinDisabled(){
      return !this.valid && !this.fechaFin

    }*/
    isValid(){
      if(!this.nivel || !this.fechaInicio || this.fechaInicio.length !== this.mask.length) {
        return false
      }

      if(this.fechaFin && this.fechaFin.length !== this.mask.length) {
        return false
      }

      if(this.$date.parseDate(this.fechaInicio).format('DD/MM/YYYY HH:mm') >= this.$date.parseDate(this.fechaFin).format('DD/MM/YYYY HH:mm')) {
        return false
      }

      return true
    }

  },

  watch: {

    show (value) {
      if (value) {
        /*this.fechaInicioMinima = this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA)
        this.fechaFinMinima = this.fechaInicioMinima.add(1, 'minute')*/
        this.resetData()
      }
    },
    fechaInicio () {
      if(this.fechaInicio && this.fechaInicio.length === this.mask.length){
        this.getFechaFinMin()
      }
    },
    fechaFin() {
      if(this.fechaFin && this.fechaFin.length === this.mask.length){
        this.getFechaInicioMax()
      }
    }
    /*valid(newVal){
      if(newVal){
        let fechaInicioParsed = this.$date.parseDate(this.fechaInicio)
        this.fechaFinMin = fechaInicioParsed.add(1, 'minute')

      }
    },*/
  },

  methods: {
    resetData() {
      this.nivel = null
      this.fechaInicio = null
      this.fechaFin = null
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    getFechaInicioMin() {
      if(this.incendio && this.emergencia && this.nivel){
        const prevNivel = this.incendio.NIVELES.length > 0
          ? this.$date.parseDate(this.incendio.NIVELES[this.incendio.NIVELES.length -1 ].FECHA_FIN).format('DD/MM/YYYY HH:mm')
          : this.$date.parseDate(this.emergencia.ESTADOS[this.emergencia.ESTADOS.length - 1].FECHA).format('DD/MM/YYYY HH:mm')

        this.fechaInicioMin = prevNivel ? prevNivel : null
      }
    },
    getFechaInicioMax() {
      this.fechaInicioMax = this.fechaFin
        ? this.$date
            .parseDate(this.fechaFin)
            .add(-1, 'minute')
            .format('DD/MM/YYYY HH:mm')
            : null
    },
    getFechaFinMin() {
      this.fechaFinMin = this.fechaInicio
        ? this.$date
            .parseDate(this.fechaInicio)
            .add(1, 'minute')
            .format('DD/MM/YYYY HH:mm')
            : null
    },
    setCurrentDate (pos) {
      const currentDate = this.$date.currentDate()
      if (pos === 0 && (!this.fechaInicio || this.fechaInicio === '')) {
        this.getFechaInicioMin()
        this.getFechaInicioMax()
        this.fechaInicio = currentDate
      } else if (pos === 1  && (!this.fechaFin || this.fechaFin === '')) {
        this.getFechaFinMin()
        this.fechaFin = currentDate
      }
    },
    cancelar () {
      this.resetData()
      this.$emit('cancelar')
    },

    aceptar () {
      let estadoAdd
      for (let i = 0; i < this.tiposIncendioNivel.length; i++) {
        if (this.nivel === this.tiposIncendioNivel[i].INCENDIO_NIVEL) {
          estadoAdd = this.tiposIncendioNivel[i]
          break
        }
      }
      this.nivelIncendio = {
        ID_INCENDIO_NIVEL: this.$uuid.createUUID(),
        ID_INCENDIO: this.idIncendio,
        ID_TIPO_INCENDIO_NIVEL: estadoAdd.ID_TIPO_INCENDIO_NIVEL,
        FECHA_INICIO: this.$date.parseDate(this.fechaInicio),
        FECHA_FIN: this.$date.parseDate(this.fechaFin),
        TIPO_INCENDIO_NIVEL: estadoAdd
      }
      this.$store.dispatch('incendio/addNivelIncendio', this.nivelIncendio)

      this.resetData()
      this.$emit('redrawIncendio')
      this.$emit('aceptar')

    }
  }
}
</script>

<style scoped>

</style>
