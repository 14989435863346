<template>
  <v-dialog v-model="show" persistent width="700px" height="90vh">
    <v-card style="height: 80vh;" class="d-flex flex-column">
      <v-toolbar color="primaryLight" dark dense style="max-height: min-content !important;">
        <v-toolbar-title>Añadir medios</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="flex-grow-1 overflow-y-auto pa-0">
        <v-stepper v-model="step" style="box-shadow { display: none}; height: 100% !important;" class="d-flex flex-column">
          <v-stepper-header style="z-index: 1;">
            <v-stepper-step :complete="step > 1" step="1"> Emergencia </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="step > 2" step="2"> Medios </v-stepper-step>
            <v-divider />
            <v-stepper-step step="3"> Resumen </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="flex-grow-1 overflow-y-auto">
            <!-- Paso 1 -->
            <v-stepper-content step="1" class="py-0">
              <v-data-table
                :headers="headersEmergencia"
                :items="idEmergencia ? [emergencia] : emergencias"
                item-key="ID_EMERGENCIA"
                :show-select="!idEmergencia"
                single-select
                v-model="emergenciaSeleccionada"
                hide-default-footer
                :items-per-page="-1"
              >
                <template #[`item.ESTADO`]="{ item }">
                  <v-chip
                    :color="item ? item.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR : ''"
                    style="color: white"
                    class="d-flex justify-center align-center"
                  >
                    {{ item ? item.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO : '' }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-stepper-content>

            <!-- Paso 2 -->
            <v-stepper-content step="2" class="py-0 grow-class" style="height: 100% !important;">
              <div class="d-flex flex-column" style="height: 100% !important;">
                <v-text-field v-model="search" class="mt-2 buscador" label="Buscar" outlined dense append-icon="mdi-magnify" clearable />
                <v-btn-toggle v-model="categoriasSelecionadas" color="primary" multiple class="w-100 my-2">
                  <v-btn :key="tipo.index" v-for="tipo in categoriasMedios" small class="flex-grow-1">{{ tipo.text }}</v-btn>
                </v-btn-toggle>
                <div
                  class="flex-grow-1 overflow-y-auto"
                >
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="mediosAsignar ? mediosAsignar : mediosOrder"
                    item-key="ID_MEDIO"
                    :item-class="getRowColor"
                    :show-select="!mediosAsignar"
                    v-model="mediosSelected"
                    hide-default-footer
                    fixed-header
                    :items-per-page="-1"
                    style=" height: 100%;white-space: normal; "
                    class="tabla"
                  >
                    <template #no-data>
                      <p>Datos no disponibles.</p>
                    </template>

                    <template #[`header.data-table-select`] />

                    <template #[`item.data-table-select`]="{ item, isSelected, select }">
                      <v-simple-checkbox :value="isSelected" @input="select($event)" v-if="!disabledPorFinDescanso(item)" :disabled="item.ESTADO == 1 || item.ESTADO == 2" :ripple="false" />

                      <v-tooltip bottom v-else>
                        <template #activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="#c00d0d">mdi-sleep</v-icon>
                        </template>
                        <span>El descanso finaliza: {{ $date.formatDate(item.FECHA_FIN_DESCANSO, 'DD/MM/YYYY HH:mm') }}</span>
                      </v-tooltip>
                    </template>

                    <template #[`item.MEDIO`]="{ item }">
                      <v-tooltip bottom v-if="(item.ESTADO == 1 || item.ESTADO == 2) && item.ID_EMERGENCIA">
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ item.MEDIO }}</span>
                        </template>
                        <span>Asignado a incendio: {{ $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA) ? $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA).MUNICIPIO : null }}</span>
                      </v-tooltip>
                      <span v-else>{{ item.MEDIO }}</span>
                    </template>
                  </v-data-table>
                </div>
                <v-container fluid>
                  <v-form v-model="isValid">
                    <v-row>
                      <v-col>
                        <vx-date-text-edit v-model="fechaAviso" label="Fecha aviso" icon="esri-icon-calendar" :min="dateMin" required />
                      </v-col>
                      <v-col>
                        <vx-date-text-edit v-model="inicioJornada" label="Inicio de jornada" icon="esri-icon-calendar" required />
                      </v-col>
                      <v-col>
                        <v-select v-model="idSector" :items="sectores" label="Sector" prepend-icon="esri-icon-description" item-text="SECTOR" item-value="ID_SECTOR" :rules="[globalRules.required]" />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </div>
            </v-stepper-content>

            <!-- Paso 3: Resumen -->
            <v-stepper-content step="3" class="grow-class" style="height: 100% !important;">
              <div class="d-flex flex-column" style="height: 100% !important;">
                <p class="d-flex justify-center font-weight-bold">RESUMEN SELECCIÓN</p>
                <v-card class="pt-3 flex-grow-1 overflow-y-auto d-flex">
                  <div class=" pr-2 overflow-y-auto" style="min-width: 50% !important;">
                    <p class="pa-0 ma-0"><strong>EMERGENCIA:</strong></p>
                    <v-card
                      v-if="idEmergencia || emergenciaSeleccionada.length > 0"
                      elevation="6" dark
                      :color="idEmergencia
                        ? emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR
                        : emergenciaSeleccionada.length > 0
                          ? emergenciaSeleccionada[0].ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR
                          : ''"
                      style="color: white"
                    >
                      <v-card-title
                        class="text-h7 pt-0 pl-3"
                      >
                        {{ idEmergencia ? emergencia.LOCALIDAD : emergenciaSeleccionada.map(emergencia => emergencia.LOCALIDAD).join(', ') }}
                      </v-card-title>
                      <v-card-subtitle :class="{'text-right': $vuetify.breakpoint.smAndUp, 'pb-0': true}">
                        {{ idEmergencia ? emergencia.FECHA_CREACION : emergenciaSeleccionada[0].FECHA_CREACION | formatDate }}
                      </v-card-subtitle>
                    </v-card>
                  </div>
                  <div class="pl-2 overflow-y-auto" style="min-width: 50% !important;">
                    <p class="pa-0 ma-0"><strong>MEDIOS:</strong></p>
                    <v-simple-table class="tabla">
                      <template #default>
                        <tbody>
                          <tr
                            v-for="item in mediosSelected"
                            :key="item.id"
                          >
                            <td>{{ item.MEDIO }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn v-show="step !== 1" text color="primary" @click="prevStep">Atrás</v-btn>
        <v-btn v-show="step !== 3" text color="primary" @click="nextStep">Siguiente</v-btn>
        <v-btn v-show="step === 3" text color="green" @click="aceptar" :disabled="disableAdd">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constants from '../../../helpers/constants'

export default {
  props: {
    show: Boolean,
    idEmergencia: {
      type: String,
      default: null
    },
    mediosAsignar: {
      type: Array,
      default: null
    }
  },

  data: () => ({
    isValid: false,

    search: '',

    categoriasMedios: [],

    fechaAviso: null,
    inicioJornada: null,

    dateMin: null,

    mediosSelected: [],

    idSector: null,

    mediosOrder: [],

    estadoMedios: [],

    categoriasSelecionadas: [],

    headersEmergencia: [
      { text: 'Estado', value: 'ESTADO' },
      { text: 'Nombre', value: 'NOMBRE' },
      { text: 'Localidad', value: 'LOCALIDAD' },
      { text: 'Municipio', value: 'MUNICIPIO' }
    ],

    step: 1,
    emergenciaSeleccionada: []
  }),

  computed: {
    ...mapGetters('medio', [
      'clasesMedio'
    ]),

    emergencias () {
      let emergencias = JSON.parse(JSON.stringify(this.$store.getters['emergencia/emergenciasFiltradas']))
      return emergencias
    },

    disableAdd () {
      return this.mediosSelected.length === 0 || !this.isValid
    },

    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    emergencia () {
      if (this.idEmergencia || (this.emergenciaSeleccionada && this.emergenciaSeleccionada.length > 0)) {
        return this.$store.getters['emergencia/getEmergenciaByID'](this.idEmergencia || this.emergenciaSeleccionada[0].ID_EMERGENCIA)
      }
      return null
    },

    medios () {
      let medios = JSON.parse(JSON.stringify(this.$store.getters['medio/medios']))
      // Solo es posible añadir medios habilitados y activos
      medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)

      let emergencias = this.$store.getters['emergencia/emergencias'] // TODO: esta chapuza puede dar problemas aun mayores si se oculta el incendio antes de que acabe el descanso
      // console.log('MEDIOS', medios.filter(x => x.MEDIO === '113'))
      /** TODO: Esta cosa super cutre se hace para tener la fecha de fin descanso mas tardia en DialogAddMedios */
      medios.forEach((m) => { // Añadir descanso a cada medio de la lista
        let fechaDescanso = this.$date.parseDate('2018-01-01 00:00:00.000')
        emergencias.forEach((emergencia) => {
          for (let i = 0; i < emergencia.SECTORES.length; i++) {
            let sector = emergencia.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.ID_MEDIO.equalsIgnoreCase(m.ID_MEDIO)) {
                if (medioSector.FECHA_FIN_DESCANSO) {
                  let newFechaDescanso = this.$date.parseDate(medioSector.FECHA_FIN_DESCANSO)

                  if (newFechaDescanso > fechaDescanso) {
                    fechaDescanso = newFechaDescanso
                  }
                }
              }
            }
          }
        })

        if (fechaDescanso.format('YYYY/MM/DD HH:mm') !== '2018/01/01 00:00') {
          m.FECHA_FIN_DESCANSO = fechaDescanso
        }
      })

      return medios
    },

    sectores () {
      let sectores = []

      if (this.emergencia) {
        sectores = this.emergencia.SECTORES
      }

      return sectores
    },

    headers () {
      return [
        { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true },
        { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true },
        { text: 'Entrada', value: 'HORA_ENTRADA', align: 'left', sortable: true },
        { text: 'Salida', value: 'HORA_SALIDA', align: 'left', sortable: true },
        {
          text: 'Clase',
          value: 'CLASE',
          filter: value => {
            let categoria = this.categoriasMedios.find(element => element.value === value)

            return categoria && this.categoriasSelecionadas.includes(categoria.index)
          },
          align: 'left',
          sortable: true
        }
      ]
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.fechaAviso = this.$date.currentDate()
        this.inicioJornada = this.$date.currentDate()
        this.setCategoriasMedio()
        this.idSector = this.sectores.length > 0 ? this.sectores[0].ID_SECTOR : null

        this.dateMin = this.emergencia ? this.$date.parseDate(this.emergencia.FECHA_CREACION) : null

        this.orderMedios()
      }
    },

    medios () {
      this.orderMedios()
    },
    sectores () {
      if (this.sectores.length > 0) {
        this.idSector = this.sectores[0].ID_SECTOR
      }
    },
    emergencia () {
      if (this.emergencia) {
        this.dateMin = this.$date.parseDate(this.emergencia.FECHA_CREACION)
      }
    }
  },

  methods: {
    nextStep () {
      if (this.step < 3) this.step++
      if (this.step === 3 && this.mediosAsignar) {
        this.mediosSelected = this.mediosAsignar
      }
    },
    prevStep () {
      if (this.step > 1) this.step--
    },
    setCategoriasMedio () {
      this.categoriasMedios = []
      this.categoriasSelecionadas = []

      this.clasesMedio.forEach((element, index) => {
        let categoria = {
          text: element.NOMBRE, value: element.NOMBRE, id: element.ID_MEDIO_CLASE, align: 'left', sortable: true, index: index
        }

        this.categoriasMedios.push(categoria)

        this.categoriasSelecionadas.push(index)
      })
    },

    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },

    getRowColor (medio) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (medio.ESTADO) {
        case constants.estadosMedioEmergencia.AVISO:
          rowClass = 'medioAviso'
          break
        case constants.estadosMedioEmergencia.ACTUANDO:
          rowClass = 'medioLlegadaInc'
          break
        case constants.estadosMedioEmergencia.REGRESO:
          rowClass = 'medioSalida'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescanso'
      }

      return rowClass
    },

    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))
      /* for (let i = 0; i < mediosOrder.length; i++) { // TODO: revisar que es esto
        let m = mediosOrder[i]
        if (m.ESTADO === 3) {
          m.ESTADO = 0
        }
      } */

      mediosOrder.sort(function (a, b) {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      this.mediosOrder = mediosOrder
    },

    cancelar () {
      this.$emit('cancelar')
      this.step = 1
      this.emergenciaSeleccionada = []
      this.mediosSelected = []
    },

    aceptar () {
      let mediosIncendio = {
        ID_EMERGENCIA: this.idEmergencia || this.emergenciaSeleccionada[0].ID_EMERGENCIA,
        ID_SECTOR: this.idSector,
        MEDIOS: []
      }

      for (let i = 0; i < this.mediosSelected.length; i++) {
        let idMedioSector = this.$uuid.createUUID()

        let tiempos = {
          tiempoJornada: this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'),
          tiempoDescanso: this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
        }
        if (this.mediosSelected[i].TIPO === 'Retén Tragsa' || this.mediosSelected[i].TIPO === 'Cuadrilla Helitransportada') {
          tiempos = constants.tiemposTrabajoMediosReducido
        }

        let fechaAviso = this.$date.parseDate(this.fechaAviso)

        let inicioJornada = this.$date.parseDate(this.inicioJornada) // this.$date.parseDate(fechaAviso.format('DD/MM/YYYY') + ' ' + this.mediosSelected[i].HORA_ENTRADA) // TODO: Y que pasa si el inicio jornada fue el dia antes?

        let planificacion = {
          ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
          ID_MEDIO_SECTOR: idMedioSector,
          INICIO_JORNADA: inicioJornada,
          TIEMPO_OPERATIVO: tiempos.tiempoJornada,
          TIEMPO_DESCANSO: tiempos.tiempoDescanso
        }

        let medioSector = {
          ID_MEDIO_SECTOR: idMedioSector,
          ID_SECTOR: this.idSector,
          ID_MEDIO: this.mediosSelected[i].ID_MEDIO,
          FECHA_AVISO: fechaAviso,
          PLANIFICACION: planificacion
        }
        mediosIncendio.MEDIOS.push(medioSector)
      }

      this.$emit('aceptar', mediosIncendio)
      this.step = 1
      this.emergenciaSeleccionada = []
      this.mediosSelected = []
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }
}
</script>

<style scoped>
.buscador {
max-height: min-content !important;
}
.buscador .v-text-field__details {
  display: none !important;
}

.buscador >>> .v-input__slot {
  margin: 0px;
}

.grow-class >>> .v-stepper__wrapper {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.tabla {
  display: grid;
  min-width: 100%;
  font-size: 0.8rem !important;
}
.tabla .v-input--horizontal {
  grid-template-columns: auto;
}
.tabla .v-selection-control__input {
  width: fit-content;
}
.tabla th {
  vertical-align: top;
}
.tabla th,
.tabla td {
  font-size: 0.8rem !important;
  padding: 0 8px !important;
}
</style>
