var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Perímetros")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","absolute":"","disabled":_vm.disabledByRol},on:{"click":_vm.openAddPerimeterDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir perímetro")])])],1),_c('v-card-text',[_c('v-list',{attrs:{"expand":"","subheader":""}},[_vm._l((_vm.perimetros),function(perimetro){return _c('v-list-item',{key:perimetro.ID_PERIMETRO},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(perimetro.NOMBRE))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatDate(perimetro.FECHA))+" - Área: "+_vm._s(perimetro.measure ? perimetro.measure.area :'0')+"ha - Perímetro "+_vm._s(perimetro.measure ? perimetro.measure.perimetro : '0')+"km")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.showDialog},on:{"click":function($event){_vm.idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? _vm.mostrarPerimetro('remove',perimetro) : _vm.mostrarPerimetro('add',perimetro)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? 'mdi-eye-off' : 'mdi-eye'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? 'Ocultar' : 'Visualizar'))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.showDialog || _vm.disabledByRol},on:{"click":function($event){return _vm.openEditPerimeterDialog(perimetro)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1)}),(_vm.perimetros && _vm.perimetros.length <= 0)?_c('p',{staticStyle:{"margin":"16px 10px 10px 10px"},attrs:{"align":"center"}},[_vm._v("No hay ningún perímetro creado.")]):_vm._e()],2)],1)],1),_c('vx-dialog-menu-perimetro',{attrs:{"show":_vm.showDialog,"idEmergencia":_vm.idEmergencia,"perimetroSelected":_vm.perimetroSelected,"isEdit":_vm.isEdit},on:{"close":function($event){_vm.showDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }