<template>
  <v-card tile :style="{ height: drawer ? '100%' : 'calc(100vh - 64px)' }" class="d-flex flex-column">
    <v-toolbar color="primaryLight" dark dense style="max-height: fit-content;">
      <v-toolbar-title style="width: 100%">Resumen de medios</v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom v-if="drawer">
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon x-large class="mx-0" @click="abrirEnVentana()">
            <v-icon size="25">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Abrir en pestaña</span>
      </v-tooltip>
      <v-tooltip bottom v-if="drawer">
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon x-large class="mx-0" @click="close()">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Cerrar</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="flex-grow-1 overflow-y-auto pt-0">
      <div style="height: 100%;" class=" d-flex flex-column">
        <v-container class="ma-0" fluid>
          <v-row class="mt-0 no-spacing-row" dense>
            <v-col>
              <v-text-field v-model="search" class="my-0 py-0 buscador" style="text-overflow: ellipsis; min-width: 270px" label="Buscar" outlined dense append-icon="mdi-magnify" clearable />
            </v-col>
            <v-col style="max-width: fit-content;">
              <v-btn outlined style="background-color:#00759e; color:white; height: 40px;border: none;" :disabled="isAsignable" @click="showDialogAddMedios = true">
                <v-icon left color="white">mdi-fire</v-icon>
                Asignar emergencia
              </v-btn>
            </v-col>
            <v-col style="max-width: fit-content;">
              <v-btn outlined style="background-color: #00759e; color:white; height: 40px;border: none;" :disabled="isCambiarEstado" @click="showDialogAddEstado = true">
                <v-icon left color="white">mdi-clock-outline</v-icon>
                Cambiar estado
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0 no-spacing-row">
            <v-col>
              <v-btn-toggle v-model="categoriasSelecionadas" color="primary" multiple class="d-flex flex-wrap" density="compact">
                <v-btn :key="tipo.index" v-for="tipo in categoriasMedios" small class="flex-grow-1">{{ tipo.text }}</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="py-0 ma-0 flex-grow-1 overflow-y-auto" fluid
        >
          <v-data-table
            :headers="headers"
            :items="mediosFiltrados"
            :search="search"
            fixed-header
            density="compact"
            :items-per-page="-1"
            hide-default-footer
            class="tabla"
            group-by="emergenciaData.ID_EMERGENCIA"
            style="overflow-y: auto !important;height: 100%;"
            @click:row="zoomMedio"
            :sortBy="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #no-data>
              <span>Datos no disponibles.</span>
            </template>
            <template #[`header.isSelected`]>
              <v-checkbox class="my-0 py-0 checkboxFit" v-model="isSelectedAll" @click.stop="selectAll()" value :indeterminate="isSelectedAll === undefined" />
            </template>
            <template #item="{ item }">
              <tr :class="getRowColor(item)">
                <td v-for="head in headers" :key="head.key">
                  <div v-if="head.value === 'isSelected'">
                    <v-checkbox class="my-0 py-0 checkboxFit" v-model="item.isSelected" @click.stop="selectItem(item)" />
                  </div>
                  <div v-else-if="head.value === 'MEDIO'">
                    <span>{{ item.MEDIO.substr(0,15) + (item.MEDIO.length > 15 ? '.' : '') }}</span>
                  </div>
                  <div v-else-if="head.value === 'ULT_POSICION'">
                    <span>{{ item.ULT_POSICION ? item.ULT_POSICION.FECHA : '' | formatDate }}</span>
                  </div>
                  <div v-else-if="head.value === 'ESTADO'">
                    <span>{{ nombreEstado(item.ESTADO) }}</span>
                  </div>
                  <div v-else-if="head.value === 'FECHA'">
                    <span>{{ item.FECHA ? item.FECHA : item.FECHA_BASE | formatDate }}</span>
                  </div>
                  <div v-else-if="head.value === 'ID_EMERGENCIA_ANTERIOR'">
                    <span>{{ $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA_ANTERIOR) ? $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA_ANTERIOR).MUNICIPIO : 'No ha actuado' }}</span>
                  </div>
                  <div v-else-if="head.value === 'ACCIONES'">
                    <v-layout>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn fab icon small v-bind="attrs" v-on="on" @click="editMedio(item)" :disabled="disabledByRol() || item.ESTADO === 0">
                            <v-icon color="black">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar medio</span>
                      </v-tooltip>
                    </v-layout>
                  </div>
                </td>
              </tr>
            </template>
            <template #[`group.header`]="{ group, toggle, isOpen }">
              <td @click="toggle" :colspan="headers.length" style="cursor: pointer; background-color: #ffffff;">
                <v-btn x-small text :style="getGroupHeaderColor(group)">
                  <v-icon v-if="!isOpen" style="font-size: 16px !important;">mdi-plus</v-icon>
                  <v-icon v-else style="font-size: 16px !important;">mdi-minus</v-icon>
                </v-btn>
                <span class="px-2" style="font-size: 0.875rem !important;">{{ $store.getters['emergencia/getEmergenciaByID'](group) ? $store.getters['emergencia/getEmergenciaByID'](group).MUNICIPIO : 'Sin asignar' }}</span>
              </td>
            </template>
          </v-data-table>
        </v-container>
      </div>
    </v-card-text>

    <vx-dialog-edit-medio v-if="showDialogEditMedio" :show="showDialogEditMedio" :emergencia="emergenciaMedio" :medioSector="medioEdit" @aceptar="acceptEditMedioIncendio" @cancelar="cancelEditMedioIncendio" @deleteMedioEmergencia="deleteMedioEmergencia" />
    <vx-dialog-add-medios :show="showDialogAddMedios" :mediosAsignar="mediosSelected" @aceptar="acceptAddMediosEmergencia" @cancelar="cancelAddMediosEmergencia" />
    <vx-dialog-add-estado :show="showDialogAddEstado" :mediosCambioEstado="mediosSelected" @aceptar="acceptAddEstado" @cancelar="cancelAddEstado" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constants from '../../helpers/constants'
import * as ArcGIS from '../../helpers/ArcGIS'

import DialogAddMedios from './leftmenu/DialogAddMedios'
import DialogEditMedio from './leftmenu/DialogEditMedio'
import DialogAddEstado from './leftmenu/DialogAddEstado.vue'

export default {

  components: {
    'vx-dialog-add-medios': DialogAddMedios,
    'vx-dialog-edit-medio': DialogEditMedio,
    'vx-dialog-add-estado': DialogAddEstado
  },

  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isValid: false,

    sortBy: 'ULT_POSICION',
    sortDesc: true,

    headers: [
      { text: '', value: 'isSelected', align: 'left', sortable: false },
      { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true },
      { text: 'Clase', value: 'CLASE', align: 'left', sortable: true },
      {
        text: 'Fecha ult. posición',
        value: 'ULT_POSICION',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        }
      },
      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true },
      {
        text: 'Fecha ult. estado',
        value: 'FECHA',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a) return -1
          if (!b) return 1
          return new Date(a) - new Date(b)
        }
      },
      { text: 'Ultima emergencia', value: 'ID_EMERGENCIA_ANTERIOR', align: 'left', sortable: true },
      { text: '', value: 'ACCIONES', align: 'center', sortable: false }
    ],

    search: '',

    categoriasMedios: [],

    mediosSelected: [],

    mediosOrder: [],

    mediosFiltrados: [],

    estadoMedios: [],

    categoriasSelecionadas: [],

    emergenciaMedio: null,

    medioEdit: {},

    showDialogEditMedio: false,
    showDialogAddMedios: false,
    showDialogAddEstado: false,

    isSelectedAll: false,

    selectedCount: 0
  }),

  computed: {
    ...mapGetters('medio', [
      'clasesMedio'
    ]),

    medios () {
      let medios = JSON.parse(JSON.stringify(this.$store.getters['medio/medios']))

      medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)

      let emergencias = this.$store.getters['emergencia/emergencias'] // TODO: esta chapuza puede dar problemas aun mayores si se oculta el incendio antes de que acabe el descanso

      /** TODO: Esta cosa super cutre se hace para tener la fecha de fin descanso mas tardia en DialogAddMedios */
      medios.forEach((m) => { // Añadir descanso a cada medio de la lista
        let fechaDescanso = this.$date.parseDate('2018-01-01 00:00:00.000')
        emergencias.forEach((emergencia) => {
          for (let i = 0; i < emergencia.SECTORES.length; i++) {
            let sector = emergencia.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.ID_MEDIO.equalsIgnoreCase(m.ID_MEDIO)) {
                if (medioSector.FECHA_FIN_DESCANSO) {
                  let newFechaDescanso = this.$date.parseDate(medioSector.FECHA_FIN_DESCANSO)

                  if (newFechaDescanso > fechaDescanso) {
                    fechaDescanso = newFechaDescanso
                  }
                }
              }
            }
          }
        })

        if (fechaDescanso.format('YYYY/MM/DD HH:mm') !== '2018/01/01 00:00') {
          m.FECHA_FIN_DESCANSO = fechaDescanso
        }
      })

      return medios
    },

    sectores () {
      let sectores = []

      if (this.emergencia) {
        sectores = this.emergencia.SECTORES
      }

      return sectores
    },

    isAsignable () {
      return !this.selectedCount || this.mediosOrder.some(medio => medio.isSelected && medio.ID_EMERGENCIA)
    },

    isCambiarEstado () {
      return !this.selectedCount || this.mediosOrder.some(medio => medio.isSelected && !medio.ID_EMERGENCIA)
    }
  },

  watch: {
    clasesMedio () {
      this.setCategoriasMedio()
    },

    medios () {
      this.orderMedios()
    },

    categoriasSelecionadas () {
      this.mediosFiltrados = this.mediosOrder.filter((a) => {
        let categoria = this.categoriasMedios.find(element => element.value === a.CLASE)

        return categoria && this.categoriasSelecionadas.includes(categoria.index)
      })
    },

    selectedCount () {
      this.isSelectedAll = this.selectedCount === this.mediosOrder.length
        ? true
        : this.selectedCount > 0
          ? undefined
          : false
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },
    abrirEnVentana () {
      this.$emit('abrirEnVentana')
    },
    selectItem (item) {
      if (item.isSelected) {
        this.mediosSelected.push(item)
        this.selectedCount++
      } else {
        const index = this.mediosSelected.findIndex(medio => medio.ID_MEDIO === item.ID_MEDIO)
        if (index !== -1) {
          this.mediosSelected.splice(index, 1)
          this.selectedCount--
        }
      }
    },

    selectAll () {
      this.mediosOrder.forEach(medio => {
        medio.isSelected = this.isSelectedAll
      })
      this.mediosSelected = this.isSelectedAll ? this.mediosOrder : []
      this.selectedCount = this.isSelectedAll ? this.mediosOrder.length : 0
    },

    setCategoriasMedio () {
      this.categoriasMedios = []
      this.categoriasSelecionadas = []

      this.clasesMedio.forEach((element, index) => {
        let categoria = {
          text: element.NOMBRE, value: element.NOMBRE, id: element.ID_MEDIO_CLASE, align: 'left', sortable: true, index: index
        }

        this.categoriasMedios.push(categoria)

        this.categoriasSelecionadas.push(index)
      })
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },

    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },

    getRowColor (medio) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (medio.ESTADO) {
        case constants.estadosMedioEmergencia.AVISO:
          rowClass = 'medioAvisoResumen'
          break
        case constants.estadosMedioEmergencia.ACTUANDO:
          rowClass = 'medioLlegadaIncResumen'
          break
        case constants.estadosMedioEmergencia.REGRESO:
          rowClass = 'medioSalidaResumen'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescansoResumen'
      }

      return rowClass
    },

    getGroupHeaderColor (incendio) {
      let estilo = {
        cursor: 'pointer',
        'background-color': this.$store.getters['emergencia/getEmergenciaByID'](incendio) ? this.$store.getters['emergencia/getEmergenciaByID'](incendio).ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR : 'green',
        color: 'white'
      }

      return estilo
    },

    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))
      /* for (let i = 0; i < mediosOrder.length; i++) { // TODO: revisar que es esto
        let m = mediosOrder[i]
        if (m.ESTADO === 3) {
          m.ESTADO = 0
        }
      } */
      mediosOrder.forEach(medio => {
        medio.emergenciaData = medio.ID_EMERGENCIA ? this.$store.getters['emergencia/getEmergenciaByID'](medio.ID_EMERGENCIA) : null
        if (this.mediosOrder.length > 0) {
          const selected = this.mediosOrder.find(medioO => medioO.ID_MEDIO === medio.ID_MEDIO)
          medio.isSelected = selected ? selected.isSelected : false
        } else {
          medio.isSelected = false
        }
      })

      mediosOrder.sort(function (a, b) {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      this.mediosOrder = mediosOrder
      this.mediosFiltrados = mediosOrder
    },

    zoomMedio (value) {
      let medio = this.mediosOrder.find(x => x.ID_MEDIO === value.ID_MEDIO)
      if (medio && medio.ULT_POSICION) {
        let center = {
          target: [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
          zoom: 17
        }

        ArcGIS.setCenterMap(center)
      }
    },

    nombreEstado (estado) {
      switch (estado) {
        case 0:
          return 'No asignado'

        case 1:
          return 'Avisado'

        case 2:
          return 'Llegada a incendio'

        case 3:
          return 'Salida del incendio'

        case 4:
          return 'Llegada a base'
      }
    },

    // Dialogo editar medio
    acceptEditMedioIncendio (medioEdit) {
      this.showDialogEditMedio = false
      this.$store.dispatch('emergencia/editMedioEmergencia', medioEdit)
      this.medioEdit = {}
    },

    cancelEditMedioIncendio () {
      this.showDialogEditMedio = false
      this.medioEdit = {}
    },

    deleteMedioEmergencia (medioDelete) {
      this.showDialogEditMedio = false
      this.medioEdit = {}
      this.$store.dispatch('emergencia/deleteMedioEmergencia', medioDelete)
    },

    editMedio (medio) {
      this.emergenciaMedio = JSON.parse(JSON.stringify(this.$store.getters['emergencia/getEmergenciaByID'](medio.ID_EMERGENCIA_ANTERIOR)))

      let sectores = this.emergenciaMedio.SECTORES
      sectores.forEach(element => {
        for (let i = 0; i < element.MEDIOS.length; i++) {
          if (element.MEDIOS[i].ID_MEDIO === medio.ID_MEDIO) {
            let sector = element.MEDIOS[i]
            sector.MEDIO = sector.MEDIO?.MEDIO
            this.medioEdit = sector
          }
        }
      })

      this.showDialogEditMedio = true
    },

    acceptAddMediosEmergencia (medios) {
      this.showDialogAddMedios = false
      this.$store.dispatch('emergencia/asignarMedios', medios)
      this.isSelectedAll = false
      this.selectAll()
    },

    cancelAddMediosEmergencia () {
      this.showDialogAddMedios = false
    },

    acceptAddEstado () {
      this.showDialogAddEstado = false
      // Click btn reloj
      this.mediosSelected.forEach(async medioSelected => {
        this.emergenciaMedio = JSON.parse(JSON.stringify(this.$store.getters['emergencia/getEmergenciaByID'](medioSelected.ID_EMERGENCIA_ANTERIOR)))
        let medio = {}
        let sectores = this.emergenciaMedio.SECTORES
        sectores.forEach(element => {
          for (let i = 0; i < element.MEDIOS.length; i++) {
            if (element.MEDIOS[i].ID_MEDIO === medioSelected.ID_MEDIO) {
              let sector = element.MEDIOS[i]
              sector.MEDIO = sector.MEDIO?.MEDIO
              medio = sector
            }
          }
        })

        if (medio) {
          let now = this.$date.now()

          if (!Array.isArray(medio.ESTADO)) {
            medio.ESTADO = []
          }

          const fechasEstados = [
            { fecha: 'FECHA_LLEGADA', estado: this.$store.state.incendio.tiposIncendioEstado[0].VALUE },
            { fecha: 'FECHA_SALIDA', estado: this.$store.state.incendio.tiposIncendioEstado[1].VALUE },
            { fecha: 'FECHA_LLEGADA_BASE', estado: this.$store.state.incendio.tiposIncendioEstado[2].VALUE },
            { fecha: 'FECHA_FIN_DESCANSO', estado: this.$store.state.incendio.tiposIncendioEstado[3].VALUE },
            { fecha: 'FECHA_FINALIZACION', estado: this.$store.state.incendio.tiposIncendioEstado[4].VALUE },
            { fecha: null, estado: this.$store.state.incendio.tiposIncendioEstado[5].VALUE }
          ]

          for (const { fecha, estado } of fechasEstados) {
            if (!fecha || !medio[fecha]) {
              if (fecha) medio[fecha] = now // Establece la fecha
              if (!medio.ESTADO.includes(estado)) {
                medio.ESTADO = [...medio.ESTADO, estado] // Agregar estado
              }
              break
            }
          }
          this.$store.dispatch('emergencia/editMedioEmergencia', medio)
          try {
            if (medio.FECHA_LLEGADA && this.incendio &&
              this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE ===
              this.$store.state.incendio.tiposIncendioEstado[0].VALUE) {
              const estadoIncendio = {
                ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
                ID_INCENDIO: this.incendio.ID_INCENDIO,
                ID_TIPO_INCENDIO_ESTADO: this.$store.state.incendio.tiposIncendioEstado[1].ID_TIPO_INCENDIO_ESTADO,
                FECHA: this.$date.now(),
                TIPO_INCENDIO_ESTADO: this.$store.state.incendio.tiposIncendioEstado[1]
              }
              await this.$store.dispatch('emergencia/addEstadoEmergencia', estadoIncendio)
            }
          } catch (error) {
            console.error('Error al actualizar el medio:', error)
          }
        }
      })

      this.selectAll()
    },
    cancelAddEstado () {
      this.showDialogAddEstado = false
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  },
  mounted () {
    this.setCategoriasMedio()
    this.orderMedios()
  }

}
</script>

<style scoped>
  .medioAvisoResumen {
    background-color: #8EB4E3 !important;
  }
  .medioLlegadaIncResumen {
    background-color: #F14D5A !important;
  }
  .medioSalidaResumen {
    background-color: #FFC000 !important;
  }
  .medioDescansoResumen {
    background-color: #AAAAAA !important;
  }

  .buscador >>> .v-text-field__details {
    display: none !important;
  }

  .buscador >>>  .v-input__control {
    margin: 0px;
  }
  .buscador >>> .v-input__slot {
    margin: 0px !important;
  }

  .tabla {
    font-size: 0.8rem !important;
    overflow: auto;
  }
  .tabla >>> .v-selection-control__input {
    width: fit-content;
  }
  .tabla >>> .v-data-table__wrapper {
    /* Header fijo */
    overflow: unset;
  }
  .tabla >>> th {
    vertical-align: top;
  }
  .tabla>>>  th,
  .tabla >>> td {
    font-size: 0.8rem !important;
    padding: 0 8px !important;
  }

  .checkboxFit >>> .v-messages{
    display:none;
  }
  .checkboxFit >>> .v-input__slot{
    margin: 0px;
  }

  .no-spacing-row {
    height: fit-content;
    padding-bottom: 5px;
    margin-top: 0px;
  }
  .no-spacing-row >>> .v-text-field__details {
    display: none !important;
  }

  .no-spacing-row >>> .v-messages {
    display: none !important;
  }

  .no-spacing-row >>> .col {
    height: fit-content;
  }

</style>
