<template>
  <v-slide-x-reverse-transition>
    <v-navigation-drawer id="layerList" height="calc(100% - 42px)" :width="navigation.width" tile v-show="show" ref="drawer" class="v-navigation-drawer--right">
      <div style="height: 100%;" class="d-flex flex-column">
        <v-card-text grid-list-sm class="flex-grow-1 overflow-y-auto d-flex flex-column  pa-0 ma-0">
          <vx-resumen-medios v-if="show" :drawer="true" @close="close()" @abrirEnVentana="abrirEnVentana()" />
        </v-card-text>
      </div>
    </v-navigation-drawer>
  </v-slide-x-reverse-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import ResumenMedios from './ResumenMedios.vue'

import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  components: {
    'vx-resumen-medios': ResumenMedios
  },

  props: {
    show: Boolean
  },

  data: () => ({
    layersOrder: [],
    exageracion: 1,
    navigation: {
      width: 650,
      borderSize: 30,
      minWidth: 650
    }

  }),

  watch: {
    show () {
      if (this.show) {
        this.layersOrder = this.sortLayers()
      }
    },

    layers () { // Watch que cuando la visibilidad / opacidad cambia EN LA STORE, lo actualiza en el mapa
      for (let i = 0; i < this.layers.length; i++) {
        let layer = this.layers[i]

        ArcGIS.setVisibilityLayer(layer.id, layer.visible)
        ArcGIS.setOpacityLayer(layer.id, layer.opacity)
      }
    },
    exageracion () {
      ArcGIS.setExageration3D(this.exageracion)
    }
  },

  computed: {
    ...mapGetters('map', [
      'layers'
    ]),
    is3D () {
      return this.$store.getters['map/isMap3D']
    }
  },

  methods: {
    close () {
      this.$emit('hide')
    },

    abrirEnVentana () {
      this.$emit('hide')
      window.open(this.$router.resolve({ path: '/resumen_medios' }).href, '_blank')
    },

    sortLayers () {
      let orderLayers = JSON.parse(JSON.stringify(this.layers))
      orderLayers.sort((a, b) => {
        let aOrden = a.posicion
        let bOrden = b.posicion
        return (aOrden > bOrden) ? -1 : 1
      })

      return orderLayers.filter(x => x.addListLayer)
    },

    refreshExaggeration () {
      this.exageracion = 1
    },
    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = 'transparent'
    },
    setEvents () {
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'left'
        : 'right'

      function resize (e) {
        if (e.clientX < vm.navigation.minWidth) return
        document.body.style.cursor = 'ew-resize'
        let f =
      direction === 'left'
        ? document.body.scrollWidth - e.clientX
        : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if ((direction === 'left' && e.offsetX < minSize) || (direction === 'right' && e.offsetX > el.offsetWidth - minSize)) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }

  },

  mounted () {
    this.setBorderWidth()
    this.setEvents()
  }

}
</script>

  <style scoped>
    #layerList {
      z-index: 3;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      right: 0px;
      min-width: 950px;
    }

    .layerItem {
      background: #ebebeb;
      border-radius: 10px;
    }

  </style>
