<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2;">
      <v-toolbar-title>Planificación medios terrestres</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container fluid class="pa-0">
        <!-- Barra superior -->
        <v-row align="center" no-gutters>
          <v-col>
            <v-select v-model="emergenciaSelected" :items="emergenciasActivas" item-value="ID_EMERGENCIA" label="Emergencias" :item-text="item => item.NOMBRE ? item.NOMBRE : item.MUNICIPIO" persistent-hint return-object />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaEmergencia" label="Fecha incendio" readonly />
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="orto" label="Orto" readonly />
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="ocaso" label="Ocaso" readonly />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaActual" label="Fecha/hora actual" readonly />
          </v-col>

          <v-col sm="3">
            <v-select v-model="filtrosSelected" :items="filtros" label="Filtros" multiple return-object>
              <template #selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">(+{{ filtrosSelected.length - 1 }} otros)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- Tabs -->
        <v-toolbar color="primaryLight" dense style="z-index: 2">
          <v-tabs v-model="tabSelected" color="white" background-color="primaryLight" dark slider-color="white">
            <v-tab v-for="t in tabs" :key="t" ripple @click="cambiarTab(t)">
              {{ t }}
            </v-tab>
          </v-tabs>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="abrirDialogAddMedio()" :disabled="mediosDialog.length === 0 || disabledByRol">
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Añadir medio</span>
          </v-tooltip>
        </v-toolbar>

        <v-tabs-items v-model="tabSelected">
          <v-tab-item key="Tabla" style="height: 60vh; overflow: auto !important;">
            <v-card flat id="tabla">
              <v-card-text>
                <div style="display: inline-flex; align-items: center; width: 100%">
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" text icon color="grey" @click="capturaTabla(false)">
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" small fab icon @click="capturaTabla(true)">
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </div>

                <v-data-table :headers="headers" :items="mediosAsignados" single-expand hide-default-footer disable-pagination>
                  <template #no-data>
                    <p>No hay medios asignados.</p>
                  </template>

                  <template #item="{ item }">
                    <tr v-if="!item || !item.hide">
                      <!-- @click="expand(!isExpanded)" -->
                      <td class="text-xs">{{ item.MEDIO }}</td>
                      <td class="text-xs">{{ item.ACTUACION }}</td>
                      <td class="text-xs">{{ item.TIPO }}</td>
                      <td class="text-xs">{{ item.SECTOR.SECTOR }}</td>
                      <td class="text-xs">{{ item.NUM_COMPONENTES }}</td>
                      <td class="text-xs">{{ fechahora(item.INICIO_JORNADA) }}</td>
                      <td class="text-xs">{{ item.FECHA_INCORPORACION }}</td>
                      <td class="text-xs">{{ hhmm(item.HORA_MOVILIZACION) }}</td>
                      <td class="text-xs">{{ hhmm(item.HORA_LLEGADA_INC) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_SALIDA_INC) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_MAX_ACTUACION) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_LLEGADA_BASE) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_FIN_DESCANSO) }}</td>
                      <td class="text-xs-center">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" :disabled="isAddActuacionDisabled(item)" @click="abrirDialogAddActuacion(item)">
                              <v-icon color="teal">mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Añadir actuación</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon v-bind="attrs" v-on="on" :disabled="!item.FECHA_INCORPORACION || item.TIENE_MAS_ACTUACIONES || disabledByRol"
                              @click="abrirDialogEdit(item.ACTUACION > 1, item)"
                            >
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" :disabled="item.TIENE_MAS_ACTUACIONES || disabledByRol" @click="borrarMedioIncendio(item)">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </td>
                    </tr>

                    <tr v-else />
                  </template>

                  <!-- <template #expanded-item="{ item }">
                    <v-card flat>
                      {{ item.MEDIO }}
                      <v-card-text>
                        <div style="display: inline-flex" v-html="medioExpandedTable" />
                      </v-card-text>
                    </v-card>
                  </template> -->
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="Gráfica" eager>
            <v-card flat>
              <v-card-text>
                <div style="display: inline-flex; align-items: center; width: 100%">
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" text icon color="grey" @click="capturaGrafica()">
                    <v-icon>mdi-camera-image</v-icon>
                  </v-btn>
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" small fab icon @click="sendGraficoTelegram()">
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </div>
                <div id="timeline" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card-text>

    <vx-dialog-add-medio-planificacion
      :show="showDialogAdd"
      :editedItem="editedItem"
      :isEdit="isEdit"
      :medios="mediosDialog"
      :sectores="sectores"
      :idEmergenciaSelected="emergenciaSelected ? emergenciaSelected.ID_EMERGENCIA : null"
      @cancelar="showDialogAdd = false"
      @aceptar="addMedioEmergencia"
      @editar="editarMedioEmergencia"
    />

    <vx-dialog-add-actuacion
      :show="showDialogActuacion"
      :editedItem="editedItem"
      :isEdit="isEdit"
      :sectores="sectores"
      :periodoAnterior="periodoAnterior"
      @cancelar="showDialogActuacion = false"
      @aceptar="addActuacionMedio"
      @editar="editarActuacionMedio"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { Timeline, DataSet } from 'vis-timeline/standalone'

import DialogAddMedioPlanificacionMMTT from './DialogAddMedioPlanificacionMMTT'
import DialogAddActuacionMMTT from './DialogAddActuacionMMTT'
import api from '@/api'
import * as PH from '../../helpers/PlanificacionHelper'
import * as htmlToImage from 'html-to-image'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import constants from '@/helpers/constants'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'PlanificacionMMTT',

  props: {
    show: Boolean
  },

  components: {
    'vx-dialog-add-medio-planificacion': DialogAddMedioPlanificacionMMTT,
    'vx-dialog-add-actuacion': DialogAddActuacionMMTT
  },

  data: () => ({
    emergenciaSelected: null,

    oldEmergenciaActiva: null,

    tabs: ['Tabla', 'Gráfica'],
    tabSelected: null,

    timeout: null,
    timer: null,

    showDialogAdd: false,
    showDialogActuacion: false,

    isEdit: false,
    editedItem: {},

    firstTimeFit: false,
    fechaActual: null,

    filtros: ['Camión', 'Retroexcavadora', 'Técnico', 'A. Medioambiental', 'Brigada Forestal', 'Brigada Helitransportada', 'Brigada UME', 'Técnico Extinción', 'Terrestre', 'Técnico Comunicaciones', 'Técnico Coordinación'],
    filtrosSelected: ['Camión', 'Retroexcavadora', 'Técnico', 'A. Medioambiental', 'Brigada Forestal', 'Brigada Helitransportada', 'Brigada UME', 'Técnico Extinción', 'Terrestre', 'Técnico Comunicaciones', 'Técnico Coordinación'],

    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left' },
      { text: 'Actuación', value: 'ACTUACION', align: 'left', sortable: false },
      { text: 'Tipo', value: 'TIPO', align: 'left' },
      { text: 'Sector', value: 'SECTOR.SECTOR', align: 'left' },
      { text: 'Nº componentes', value: 'NUM_COMPONENTES', align: 'left' },
      { text: 'Inicio de jornada', value: 'INICIO_JORNADA', align: 'left' },
      { text: 'Incorporación al incendio', value: 'FECHA_INCORPORACION', align: 'left' },
      { text: 'Movilización a incendio', value: 'HORA_MOVILIZACION', align: 'left' },
      { text: 'Llegada a incendio', value: 'HORA_LLEGADA_INC', align: 'left' },
      { text: 'Fecha salida incendio', value: 'FECHA_SALIDA_INC', align: 'left' },
      { text: 'Fecha máxima de actuación', value: 'FECHA_MAX_ACTUACION', align: 'left' },
      { text: 'Llegada base', value: 'FECHA_LLEGADA_BASE', align: 'left' },
      { text: 'Fin descanso', value: 'FECHA_FIN_DESCANSO', align: 'left' },
      { text: 'Acciones', value: 'name', sortable: false, align: 'center', width: '8%' }
    ],

    mediosDialog: [],

    mediosAsignados: [],

    periodoAnterior: {},

    numDiasPasado: 3,
    numDiasFuturo: 3
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio', 'categoriasMedio', 'clasesMedio'
    ]),

    emergenciasActivas () {
      let emergencias = JSON.parse(JSON.stringify(this.$store.getters['emergencia/emergenciasFiltradas']))

      emergencias.sort((a, b) => {
        let aEstado = a.VALUE_ESTADO
        let bEstado = b.VALUE_ESTADO
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })
      return emergencias
    },

    sectores () {
      let sectores = []

      if (this.emergenciaSelected && this.emergenciaSelected.SECTORES) {
        this.emergenciaSelected.SECTORES.forEach((s) => {
          sectores.push({ ID_SECTOR: s.ID_SECTOR, SECTOR: s.SECTOR })
        })
        sectores.sort((x, y) => {
          return x.SECTOR === 'GENERAL' ? -1 : y.SECTOR === 'GENERAL' ? 1 : 0
        })
      }

      return sectores
    },

    medios () {
      let medios = this.$store.getters['medio/medios']
      let mediosFiltered = medios.filter(x => x.CLASE !== constants.maestras.CLASE.MEDIO_AEREO.NOMBRE && x.HABILITADO === true && x.ACTIVO === true).sort((a, b) => {
        return a.MEDIO - b.MEDIO
      })
      // TODO: revisar si esta linea hace falta aqui, o se puede meter en el dialog this.mediosDialog = this.medios.filter(x => x.ESTADO === 0)
      return mediosFiltered
    },

    fechaEmergencia () {
      let fecha = null
      if (this.emergenciaSelected && this.emergenciaSelected.FECHA_CREACION) {
        fecha = this.$date.formatDate(this.emergenciaSelected.FECHA_CREACION, 'DD/MM/YYYY HH:mm')
      }

      return fecha
    },

    orto () {
      let orto = null
      if (this.emergenciaSelected && this.emergenciaSelected.LATITUD) {
        let sunrise = getSunrise(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, new Date(this.emergenciaSelected.FECHA_CREACION))
        orto = this.$date.formatDate(sunrise.toISOString(), 'HH:mm')
      }

      return orto
    },

    ocaso () {
      let ocaso = null
      if (this.emergenciaSelected && this.emergenciaSelected.LATITUD) {
        let sunset = getSunset(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, new Date(this.emergenciaSelected.FECHA_CREACION))
        ocaso = this.$date.formatDate(sunset.toISOString(), 'HH:mm')
      }

      return ocaso
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  watch: { // TODO:
    emergenciasActivas (val) {
      let is = this.emergenciaSelected ? this.emergenciasActivas.find(x => x.ID_EMERGENCIA === this.emergenciaSelected.ID_EMERGENCIA) : null
      // console.log('Han cambiado los incendios', this.incendioSelected, is, this.incendioSelected === is)
      if (this.emergenciasActivas.length > 0 && !this.emergenciaSelected) {
        this.emergenciaSelected = this.emergenciasActivas[0]
        this.oldEmergenciaActiva = this.emergenciasActivas
      }

      // if (JSON.stringify(val) === JSON.stringify(this.oldIncendioActivo)) return

      if (this.emergenciaSelected) { // HACK: el refresher modifica los incendios y luego el watch de 'incendioSelected.SECTORES' no funciona
        is = this.emergenciasActivas.find(x => x.ID_EMERGENCIA === this.emergenciaSelected.ID_EMERGENCIA)
        this.emergenciaSelected = is
        this.oldEmergenciaActiva = this.emergenciasActivas
      }
    },

    medios (val, oldVal) { // Necesario para carga inicial al refrescar la web en esta ruta
      if (oldVal.length === 0) {
        this.mediosAsignadosEmergencia()
        this.actualizarDatos()
      }
    },

    emergenciaSelected () {
      if (!this.timeline) {
        this.initTimeline()
      }

      /* if (this.incendioSelected) {
        this.actualizarDatos()
      } */
    },

    'emergenciaSelected.SECTORES': { // Este watch controla cuando se añaden y/o borran medios
      handler (val) {
        // if (JSON.stringify(this.incendioSelected.SECTORES[0].MEDIOS) === JSON.stringify(val)) return

        this.mediosAsignadosEmergencia()
        this.actualizarDatos()
      },
      deep: true
    },

    filtrosSelected () {
      this.filtrarDatos()
    }
  },

  methods: {
    setFiltros () {
      this.filtros = []
      this.filtrosSelected = []

      let categoriasNoAereas = this.categoriasMedio.filter(element => element.ID_MEDIO_CLASE !== constants.maestras.CLASE.MEDIO_AEREO.ID_CLASE).map(a => a.CATEGORIA)

      this.tiposMedio.filter(elem => categoriasNoAereas.includes(elem.CATEGORIA)).forEach((element, index) => {
        this.filtros.push(element.TIPO)

        this.filtrosSelected.push(element.TIPO)
      })
    },

    async init () {
      this.fechaActual = this.$date.currentDate()
      // this.getListaMedios()

      // this.incendiosActivos = this.orderIncendios(this.$store.getters.getChangeIncendios)

      // Seleccionar primera emergencia
      if (this.emergenciasActivas.length > 0 && !this.emergenciaSelected) {
        this.emergenciaSelected = this.emergenciasActivas[0]
      }

      // this.initTimeline()

      // El timeout hace que salte exactamente en el segundo 00
      let secondsLeft = 60 - new Date().getSeconds()
      this.timeout = setTimeout(() => {
        this.fechaActual = this.$date.currentDate()
        // this.actualizarDatos()

        // No se para que sirve
        this.timer = setInterval(() => {
          this.fechaActual = this.$date.currentDate()
          if (this.timeline) {
            this.mediosAsignadosEmergencia()
            this.actualizarDatos()
          }
        }, 1 * 10000)
      }, secondsLeft * 1000)
    },

    capturaTabla (telegram) { // TODO:
      let bodyPdf = []
      bodyPdf.push(['Medio', 'Actuación', 'Tipo', 'Sector', 'Nº comp.', 'Inicio de jornada', 'Incorp. incendio', 'Movil. incendio', 'Llegada a incendio', 'Fecha máxima de actuación', 'Llegada base', 'Fin descanso'])
      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let medio = this.mediosAsignados[i]

        let datosMedio = []
        datosMedio.push({ text: medio.MEDIO, bold: true })
        datosMedio.push(medio.ACTUACION)
        datosMedio.push(medio.TIPO)
        datosMedio.push(medio.SECTOR.SECTOR)
        datosMedio.push(medio.NUM_COMPONENTES)
        datosMedio.push(this.$date.parseDate(medio.INICIO_JORNADA).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(medio.FECHA_INCORPORACION)
        datosMedio.push(this.$date.parseDate(medio.HORA_MOVILIZACION).format('HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.HORA_LLEGADA_INC).format('HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_MAX_ACTUACION).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_LLEGADA_BASE).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_FIN_DESCANSO).format('DD/MM/YYYY HH:mm'))
        bodyPdf.push(datosMedio)
      }

      let docDefinition = {
        pageOrientation: 'landscape',
        content: [

        ],
        defaultStyle: {
          fontSize: 9
        }
      }
      docDefinition.content.push(constants.headerPDF)
      docDefinition.content.push(

        {
          text: [{ text: 'Incendio: ', bold: true }, this.emergenciaSelected.MUNICIPIO, { text: '  |  Fecha/hora inicio: ', bold: true }, this.$date.parseDate(this.emergenciaSelected.FECHA_CREACION).format('DD/MM/YYYY HH:mm'),
            { text: '  |  Orto: ', bold: true }, this.orto, { text: '  |  Ocaso: ', bold: true }, this.ocaso,
            { text: '  |  Fecha/hora informe: ', bold: true }, this.fechaActual],
          margin: [0, 0, 0, 15]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            // widths: [ 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto' ],
            body: bodyPdf
          },
          margin: [0, 15, 0, 20]
        }
      )

      if (telegram) {
        pdfMake.createPdf(docDefinition).getBase64(msg => {
          let data = {
            image: msg, // .substr(22),
            user: this.$store.getters['usuario/currentUsuario'].USUARIO
          }
          api.others.sendImageTelegram(data)
        })
      } else {
        pdfMake.createPdf(docDefinition).download('MMTT_Tiempos_' + this.emergenciaSelected.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm'))
      }

      // pdfMake.createPdf(docDefinition).open() // Abrir en pestaña nueva
    },

    sendTelegram (telegramFunction) {
      let percentage = 1

      // Define the animation options
      let animationOptions = {
        duration: 1000, // Animation duration in milliseconds
        easingFunction: 'easeInOutCubic' // Easing function to use for animation
      }

      // Call the zoomOut function with the specified percentage and animation options
      this.timeline.zoomOut(percentage, animationOptions, telegramFunction)
    },

    async sendGraficoTelegram () {
      let html = document.getElementById('timeline')

      htmlToImage.toJpeg(html, { quality: 0.95, backgroundColor: '#ffffff' })
        .then((dataUrl) => {
          let msg = {
            image: dataUrl.substr(22),
            user: this.$store.getters['usuario/currentUsuario'].USUARIO
          }

          api.others.sendImageTelegram(msg)
        })
    },

    async capturaGrafica () { // TODO:
      try {
        let dataUrl = await htmlToImage.toJpeg(document.getElementById('timeline'), { quality: 0.95 })

        let link = document.createElement('a')
        link.download = 'MMTT_' + this.emergenciaSelected.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm') + '.jpeg'
        link.href = dataUrl
        link.click()
      } catch (err) {
        this.$log.error(err)
      }
    },

    isAddActuacionDisabled (medio) {
      return !medio.HORA_LLEGADA_INC || medio.FECHA_LLEGADA_BASE >= this.$date.now() || medio.TIENE_MAS_ACTUACIONES || medio.MAX_ACTUACIONES
    },

    cambiarTab (tab) {
      if (tab === 'Gráfica') {
        setTimeout(() => {
          this.mediosAsignadosEmergencia()
          this.actualizarDatos()
          this.timeline.redraw()
          // this.fitTimeline()
        }, 800)
      }
    },

    actualizarDatos () { // Actualiza los datos que se muestran en la timeline objetivo
      // console.log('--------> ACTUALIZAR_DATOS')

      // this.mediosAsignados = []
      // this.mediosAsignadosIncendio()

      // this.mediosDialog = this.medios.filter(x => !self.mediosAsignados.find(y => y.id === x.ID_MEDIO))
      this.mediosDialog = this.medios.filter(x => x.ESTADO === constants.estadosMedioEmergencia.DISPONIBLE)

      this.updateTimeLine()
      this.setFiltros()
      this.filtrarDatos()
    },

    mediosAsignadosEmergencia () { // Lista los medios asignados a la emergencia
      this.mediosAsignados = []

      let mediosEmergencia = this.getMediosEmergencia()

      for (let i = 0; i < mediosEmergencia.length; i++) {
        let p = mediosEmergencia[i]

        let s = p.sector
        let m = p.medio

        let medio = this.medios.find(x => x.ID_MEDIO === m.ID_MEDIO)

        if (medio) {
          let fechaAviso = m.FECHA_AVISO ? m.FECHA_AVISO : null
          let llegadaInc = m.FECHA_LLEGADA ? m.FECHA_LLEGADA : null
          let salidaInc = m.FECHA_SALIDA ? m.FECHA_SALIDA : null
          let llegadaBase = m.FECHA_LLEGADA_BASE ? m.FECHA_LLEGADA_BASE : null
          let finDescanso = m.FECHA_FIN_DESCANSO ? m.FECHA_FIN_DESCANSO : null

          if (/* !llegadaBase &&  */ fechaAviso /* && llegadaInc */) {
            let data = {
              ID_MEDIO: medio.ID_MEDIO,
              ID_MEDIO_SECTOR: m.ID_MEDIO_SECTOR,
              MEDIO: medio.MEDIO,
              TIPO: medio.TIPO,
              SECTOR: s,
              NUM_COMPONENTES: m.NUM_COMPONENTES,
              HORA_ENTRADA: medio.HORA_ENTRADA,
              FECHA_AVISO: fechaAviso,
              LLEGADA_INC: llegadaInc,
              SALIDA_INC: salidaInc,
              LLEGADA_BASE: llegadaBase,
              FIN_DESCANSO: finDescanso,
              PLANIFICACION: m.PLANIFICACION
            }
            this.calcularActuacion(data)
          } else {
            let data = {
              ID_MEDIO: medio.ID_MEDIO,
              ID_MEDIO_SECTOR: m.ID_MEDIO_SECTOR,
              MEDIO: medio.MEDIO,
              TIPO: medio.TIPO,
              SECTOR: s,
              NUM_COMPONENTES: m.NUM_COMPONENTES,
              HORA_ENTRADA: medio.HORA_ENTRADA,
              FECHA_AVISO: m.FECHA_AVISO_PLANIF,
              LLEGADA_INC: m.FECHA_LLEGADA_PLANIF,
              SALIDA_INC: salidaInc,
              LLEGADA_BASE: llegadaBase,
              FIN_DESCANSO: finDescanso,
              PLANIFICACION: m.PLANIFICACION
            }
            this.calcularActuacion(data)
          }
        }
      }
    },

    calcularActuacion (medio) { // Calcula los tiempos de actuacion del medio usando los datos disponibles
      let horaMovilizacion = this.$date.parseDate(medio.FECHA_AVISO)
      let actuacion = this.getNumeroActuaciones(medio.ID_MEDIO)

      let planificacion = medio.PLANIFICACION
      let inicioJornada = planificacion ? this.$date.parseDate(planificacion.INICIO_JORNADA) : this.$date.parseDate(this.orto)

      let horaLlegadaInc = medio.LLEGADA_INC ? this.$date.parseDate(medio.LLEGADA_INC) : this.$date.now()

      let fechaInicioJornada = inicioJornada.format('YYYY-MM-DD')
      let horaInicioJornada = inicioJornada.format('HH:mm')

      // Fecha maxima actuacion (salida emergencia)
      let tiempoOperativo = planificacion ? planificacion.TIEMPO_OPERATIVO : parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'))
      // let fechaMaximaActuacion = moment(horaMovilizacion).add(tiempoOperativo, 'hours')
      let fechaMaximaActuacion = this.$date.parseDate(inicioJornada).add(tiempoOperativo, 'hours')

      // Tener en cuenta el itinere
      let itinere = horaLlegadaInc.diff(horaMovilizacion, 'minutes')

      let fechaSalidaInc = medio.SALIDA_INC ? this.$date.parseDate(medio.SALIDA_INC) : this.$date.now()// this.$date.parseDate(fechaMaximaActuacion).subtract(itinere, 'minutes')

      if (fechaSalidaInc < horaLlegadaInc) {
        fechaSalidaInc = horaLlegadaInc
      }
      // fechaMaximaActuacion = fechaMaximaActuacion.subtract(itinere * 2, 'minutes')
      let fechaLlegadaBase = this.$date.parseDate(fechaSalidaInc).add(itinere, 'minutes')

      // ZONA DATOS REALES

      // Fecha llegada base
      // let fechaLlegadaBase = moment(fechaMaximaActuacion).add(horaLlegadaInc.diff(horaMovilizacion, 'minutes'), 'minutes')
      if (medio.LLEGADA_BASE) {
        fechaLlegadaBase = this.$date.parseDate(medio.LLEGADA_BASE)
      }

      let tiempoDescanso = planificacion ? planificacion.TIEMPO_DESCANSO : parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO'))

      // Fecha fin descanso (siguiente reincorporacion)
      let fechaFinDescanso = medio.FIN_DESCANSO ? this.$date.parseDate(medio.FIN_DESCANSO) : this.$date.parseDate(fechaLlegadaBase).add(tiempoDescanso, 'hours') // 12

      // Desactivar botones si tiene mas actuaciones
      if (actuacion > 0) {
        for (let i = 0; i < this.mediosAsignados.length; i++) {
          let m = this.mediosAsignados[i]
          if (m.ID_MEDIO === medio.ID_MEDIO && m.ACTUACION === actuacion) {
            m.TIENE_MAS_ACTUACIONES = true
          }
        }
      }
      let data = {
        ID_MEDIO: medio.ID_MEDIO, // Para desplegar la tabla requiere tener un campo 'id'
        MEDIO: medio.MEDIO,
        ID_MEDIO_SECTOR: medio.ID_MEDIO_SECTOR,
        ACTUACION: actuacion + 1,
        TIPO: medio.TIPO,
        SECTOR: medio.SECTOR,
        NUM_COMPONENTES: medio.NUM_COMPONENTES,
        INICIO_JORNADA: fechaInicioJornada + ' ' + horaInicioJornada,
        TIEMPO_OPERATIVO: tiempoOperativo, // planificacion.TIEMPO_OPERATIVO,
        TIEMPO_DESCANSO: tiempoDescanso, // planificacion.TIEMPO_DESCANSO,
        FECHA_INCORPORACION: horaLlegadaInc.format('DD/MM/YYYY'),
        HORA_MOVILIZACION: horaMovilizacion,
        HORA_LLEGADA_INC: horaLlegadaInc,
        FECHA_SALIDA_INC: fechaSalidaInc,
        FECHA_MAX_ACTUACION: fechaMaximaActuacion,
        FECHA_LLEGADA_BASE: fechaLlegadaBase,
        FECHA_FIN_DESCANSO: fechaFinDescanso
      }
      this.mediosAsignados.push(data)
    },

    filtrarDatos () { // Filtra los grupos de planificacion
      let ids = this.medios.filter(t => this.filtrosSelected.includes(t.TIPO)).map(t => t.ID_MEDIO)

      this.mediosAsignados.forEach((m) => {
        if (ids.includes(m.ID_MEDIO)) {
          m.hide = false
        } else {
          m.hide = true
        }
      })

      // Ocultar los grupos que no se deben ver: vacios, que no cumplen el filtro
      if (this.timeline && this.timeline.groupsData) {
        this.timeline.groupsData._data.forEach((group) => {
          if (ids.includes(group.id)) {
            this.timeline.groupsData._data.update({ id: group.id, visible: true })
          } else this.timeline.groupsData._data.update({ id: group.id, visible: false })
        })
      }
    },

    /* getListaMedios () { // Obtiene la lista de medios
      this.medios = this.$store.getters.getMedios.filter(x => x.CATEGORIA !== 'Aéreo').sort((a, b) => {
        return a.MEDIO - b.MEDIO
      })
      this.mediosDialog = this.medios.filter(x => x.ESTADO === 0)
    }, */

    getMediosEmergencia () { // Devuelve la lista de los medios en cada sector
      let listaMediosEmergencia = []

      if (this.emergenciaSelected) {
        for (let i = 0; i < this.emergenciaSelected.SECTORES.length; i++) {
          let s = this.emergenciaSelected.SECTORES[i]

          for (let j = 0; j < s.MEDIOS.length; j++) {
            let m = s.MEDIOS[j]

            listaMediosEmergencia.push({ // TODO: mayuscula // TODO: en sector no está la lista de medios???
              sector: JSON.parse(JSON.stringify(s)),
              medio: JSON.parse(JSON.stringify(m))
            })
          }
        }

        listaMediosEmergencia = listaMediosEmergencia.sort((a, b) => { // Ordenar por fecha aviso para numerar actuaciones
          let fechaA = a.medio.FECHA_AVISO ? this.$date.parseDate(a.medio.FECHA_AVISO).valueOf() : this.$date.parseDate(a.medio.FECHA_AVISO_PLANIF).valueOf()
          let fechaB = b.medio.FECHA_AVISO ? this.$date.parseDate(b.medio.FECHA_AVISO).valueOf() : this.$date.parseDate(b.medio.FECHA_AVISO_PLANIF).valueOf()
          return fechaA - fechaB
        })
      }

      return listaMediosEmergencia
    },

    getNumeroActuaciones (idMedio) { // Devuelve el numero de actuaciones de un medio concreto
      return this.mediosAsignados.filter(x => x.ID_MEDIO === idMedio).length
    },

    // #region Dialogs Add/Edit Medio y Actuacion
    abrirDialogAddMedio () { // Muestra el panel de añadir un medio a la timeline
      this.showDialogAdd = true

      this.isEdit = false
      this.editedItem = {}
    },

    async addMedioEmergencia (data) { // Añade el medio a la lista y a la emergencia
      this.showDialogAdd = false

      try {
        // Actualizar inicio de jornada
        let medio = JSON.parse(JSON.stringify(this.medios.find(x => x.ID_MEDIO === data.ID_MEDIO)))
        medio.ESTADO = constants.estadosMedioEmergencia.ACTUANDO
        medio.HORA_ENTRADA = this.$date.parseDate(data.INICIO_JORNADA).format('HH:mm')
        await this.$store.dispatch('medio/updateMedio', medio)

        // Add medio a emergencia
        let mediosEmergencia = {
          ID_EMERGENCIA: this.emergenciaSelected.ID_EMERGENCIA,
          ID_SECTOR: data.SECTOR.ID_SECTOR,
          MEDIOS: []
        }

        let idMedioSector = this.$uuid.createUUID()
        let planificacion = {
          ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
          ID_MEDIO_SECTOR: idMedioSector,
          INICIO_JORNADA: data.INICIO_JORNADA,
          TIEMPO_OPERATIVO: data.TIEMPO_OPERATIVO,
          TIEMPO_DESCANSO: data.TIEMPO_DESCANSO
        }

        let medioSector = {
          ID_MEDIO_SECTOR: idMedioSector,
          ID_SECTOR: data.SECTOR.ID_SECTOR,
          ID_MEDIO: data.ID_MEDIO,
          FECHA_AVISO: data.HORA_MOVILIZACION,
          FECHA_LLEGADA: data.HORA_LLEGADA_INC,
          FECHA_AVISO_PLANIF: data.HORA_MOVILIZACION,
          FECHA_LLEGADA_PLANIF: data.HORA_LLEGADA_INC,
          NUM_COMPONENTES: data.NUM_COMPONENTES,
          PLANIFICACION: planificacion
        }

        // Comprobar si es a futuro o real
        let now = this.$date.now()
        if (data.HORA_MOVILIZACION > now) {
          medioSector.FECHA_AVISO = null
          medioSector.FECHA_LLEGADA = null
        }

        mediosEmergencia.MEDIOS.push(medioSector)
        this.$store.dispatch('emergencia/asignarMedios', mediosEmergencia)
      } catch (err) {
        this.$log.error(err)
      }
    },

    async editarMedioEmergencia (data) { // Edita un medio
      this.showDialogAdd = false

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        if (this.mediosAsignados[i].ID_MEDIO === data.ID_MEDIO && this.mediosAsignados[i].ACTUACION === data.ACTUACION) {
          try {
            // Actualizar inicio de jornada
            let medio = JSON.parse(JSON.stringify(this.medios.find(x => x.ID_MEDIO === data.ID_MEDIO)))
            medio.HORA_ENTRADA = this.$date.parseDate(data.INICIO_JORNADA).format('HH:mm')
            await this.$store.dispatch('medio/updateMedio', medio)
          } catch (err) {
            this.$log.error(err)
          }

          let oldSector = this.mediosAsignados[i].SECTOR.ID_SECTOR
          let prevMedio = JSON.parse(JSON.stringify(this.emergenciaSelected.SECTORES.find(x => x.ID_SECTOR === oldSector).MEDIOS.find(x => x.ID_MEDIO === data.ID_MEDIO)))

          prevMedio.FECHA_AVISO = data.HORA_MOVILIZACION
          prevMedio.FECHA_LLEGADA = data.HORA_LLEGADA_INC
          prevMedio.FECHA_AVISO_PLANIF = data.HORA_MOVILIZACION
          prevMedio.FECHA_LLEGADA_PLANIF = data.HORA_LLEGADA_INC
          prevMedio.NUM_COMPONENTES = data.NUM_COMPONENTES
          prevMedio.ID_SECTOR = data.SECTOR.ID_SECTOR
          prevMedio.ID_EMERGENCIA = this.emergenciaSelected.ID_EMERGENCIA

          // Comprobar si es a futuro o real
          let now = this.$date.now()
          if (data.HORA_MOVILIZACION > now) {
            prevMedio.FECHA_AVISO = null
            prevMedio.FECHA_LLEGADA = null
          }

          this.$store.dispatch('emergencia/editMedioEmergencia', prevMedio)

          let newPlanif = prevMedio.PLANIFICACION
          newPlanif.INICIO_JORNADA = data.INICIO_JORNADA
          newPlanif.TIEMPO_OPERATIVO = data.TIEMPO_OPERATIVO
          newPlanif.TIEMPO_DESCANSO = data.TIEMPO_DESCANSO
          let planificacion = {
            ID_EMERGENCIA: this.emergenciaSelected.ID_EMERGENCIA,
            PLANIFICACION: newPlanif
          }
          this.$store.dispatch('emergencia/editPlanificacionMedio', planificacion)
        }
      }
    },

    abrirDialogAddActuacion (data) {
      this.periodoAnterior = data
      this.showDialogActuacion = true

      this.isEdit = false
      this.editedItem = {}
    },

    abrirDialogEdit (isActuacion, item) { // Muestra el panel de edicion de medio / actuacion
      this.isEdit = true
      this.editedItem = Object.assign({}, item)

      if (isActuacion) {
        this.showDialogActuacion = true
        this.periodoAnterior = item
      } else {
        this.showDialogAdd = true
      }
    },

    addActuacionMedio (data) { // Añade una actuacion al medio
      this.showDialogActuacion = false

      // Desactivar + si es la 3act
      if (data.ACTUACION === 3) {
        data.MAX_ACTUACIONES = true
      }

      // Add medio a la emergencia
      let mediosEmergencia = {
        ID_EMERGENCIA: this.emergenciaSelected.ID_EMERGENCIA,
        ID_SECTOR: data.SECTOR.ID_SECTOR,
        MEDIOS: []
      }

      let planificacion = {
        ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
        ID_MEDIO_SECTOR: data.ID_MEDIO_SECTOR,
        INICIO_JORNADA: data.INICIO_JORNADA,
        TIEMPO_OPERATIVO: data.TIEMPO_OPERATIVO,
        TIEMPO_DESCANSO: data.TIEMPO_DESCANSO
      }

      let medioSector = {
        ID_MEDIO_SECTOR: data.ID_MEDIO_SECTOR,
        ID_SECTOR: data.SECTOR.ID_SECTOR,
        ID_MEDIO: data.ID_MEDIO,
        FECHA_AVISO: data.HORA_MOVILIZACION,
        FECHA_LLEGADA: data.HORA_LLEGADA_INC,
        FECHA_AVISO_PLANIF: data.HORA_MOVILIZACION,
        FECHA_LLEGADA_PLANIF: data.HORA_LLEGADA_INC,
        NUM_COMPONENTES: data.NUM_COMPONENTES,
        PLANIFICACION: planificacion
      }

      // Comprobar si es a futuro o real
      let now = this.$date.now()
      if (data.HORA_MOVILIZACION > now) {
        medioSector.FECHA_AVISO = null
        medioSector.FECHA_LLEGADA = null
      }

      mediosEmergencia.MEDIOS.push(medioSector)
      this.$store.dispatch('emergencia/asignarMedios', mediosEmergencia)
    },

    editarActuacionMedio (data) { // Edita una actuacion de un medio
      this.showDialogActuacion = false

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let medio = this.mediosAsignados[i]

        if (medio.ID_MEDIO === data.ID_MEDIO && medio.ACTUACION === data.ACTUACION) {
          let oldSector = this.mediosAsignados[i].SECTOR.ID_SECTOR

          this.mediosAsignados[i].FECHA_INCORPORACION = data.FECHA_INCORPORACION
          this.mediosAsignados[i].HORA_MOVILIZACION = data.HORA_MOVILIZACION
          this.mediosAsignados[i].HORA_LLEGADA_INC = data.HORA_LLEGADA_INC
          this.mediosAsignados[i].NUM_COMPONENTES = data.NUM_COMPONENTES
          this.mediosAsignados[i].SECTOR.ID_SECTOR = data.SECTOR.ID_SECTOR

          let prevMedio = this.emergenciaSelected.SECTORES.find(x => x.ID_SECTOR === oldSector).MEDIOS.find(x => x.ID_MEDIO_SECTOR === data.ID_MEDIO_SECTOR)
          prevMedio.FECHA_AVISO = data.HORA_MOVILIZACION
          prevMedio.FECHA_LLEGADA = data.HORA_LLEGADA_INC
          prevMedio.FECHA_AVISO_PLANIF = data.HORA_MOVILIZACION
          prevMedio.FECHA_LLEGADA_PLANIF = data.HORA_LLEGADA_INC
          prevMedio.NUM_COMPONENTES = data.NUM_COMPONENTES
          prevMedio.ID_SECTOR = data.SECTOR.ID_SECTOR
          prevMedio.ID_EMERGENCIA = this.emergenciaSelected.ID_EMERGENCIA

          // Comprobar si es a futuro o real
          let now = this.$date.now()
          if (data.HORA_MOVILIZACION > now) {
            prevMedio.FECHA_AVISO = null
            prevMedio.FECHA_LLEGADA = null
          }

          this.$store.dispatch('emergencia/editMedioEmergencia', prevMedio)

          let newPlanif = prevMedio.PLANIFICACION
          newPlanif.INICIO_JORNADA = data.INICIO_JORNADA
          newPlanif.TIEMPO_OPERATIVO = data.TIEMPO_OPERATIVO
          newPlanif.TIEMPO_DESCANSO = data.TIEMPO_DESCANSO
          let planificacion = {
            ID_EMERGENCIA: this.emergenciaSelected.ID_EMERGENCIA,
            PLANIFICACION: newPlanif
          }
          this.$store.dispatch('emergencia/editPlanificacionMedio', planificacion)
        }
      }
    },

    borrarMedioIncendio (data) { // Elimina el medio
      this.$root.$confirmDialog.open('¿Está seguro?', 'Eliminando actuación').then(result => {
        if (result) {
          /* for (let i = 0; i < self.mediosAsignados.length; i++) {
              let medio = self.mediosAsignados[i]
              if (medio.id === data.id && medio.ACTUACION === data.ACTUACION) {
                self.mediosAsignados.splice(i, 1)

                if (data.ACTUACION > 1) {
                  self.mediosAsignados.find(x => x.id === data.id && x.ACTUACION === data.ACTUACION - 1).TIENE_MAS_ACTUACIONES = false
                }
              }
              self.updateTimeLine()
            } */
          // let medios = self.getMediosIncendio()
          // let idMedioSector = ''
          let idMedioSector = data.ID_MEDIO_SECTOR
          // console.log(data)

          let medioUpdate = {
            ...data,
            ESTADO: constants.estadosMedioEmergencia.DISPONIBLE
          }

          this.$store.dispatch('medio/updateMedio', medioUpdate)

          this.mediosDialog = this.medios.filter(x => x.ESTADO === constants.estadosMedioEmergencia.DISPONIBLE)

          /* medios.forEach(function (m) {
              if (m.medio.ID_MEDIO === data.id && m.sector.ID_SECTOR === data.SECTOR.ID_SECTOR && moment(m.medio.FECHA_AVISO).isSame(data.HORA_MOVILIZACION)) {
                idMedioSector = m.medio.ID_MEDIO_SECTOR
              }
            }) */

          let medioDelete = {
            ID_EMERGENCIA: this.emergenciaSelected.ID_EMERGENCIA,
            ID_MEDIO: data.ID_MEDIO,
            ID_SECTOR: data.SECTOR.ID_SECTOR,
            ID_MEDIO_SECTOR: idMedioSector
          }
          this.$store.dispatch('emergencia/deleteMedioEmergencia', medioDelete)
        }
      })
    },
    // #endregion

    initTimeline () { // Inicializa la timeline
      if (!this.timeline) {
        let container = document.getElementById('timeline')
        this.timeline = new Timeline(container)
        this.timeline.setOptions(this.optionsTimeLine())
      }
    },

    updateTimeLine () { // Actualiza la timeline
      if (this.timeline) {
        this.setGroups(this.timeline)
        this.setItems(this.timeline)
      }
    },

    setGroups (timeline) { // Crear los grupos de la timeline y los asigna
      // var self = this
      let groups = []

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let t = this.mediosAsignados[i]

        // Crear cada grupo (medio)
        let initTime = null // Tiempo inicial, para ordenar
        if (t.FECHA_INCORPORACION) {
          initTime = this.$date.parseDate(t.HORA_MOVILIZACION)
        }

        // Controlar si ya esta asignado a otro incendio
        if (!groups.find(x => x.id === t.ID_MEDIO)) {
          groups.push({
            id: t.ID_MEDIO,
            content: '<span align="left">' + t.MEDIO + '</span>',
            initTime: initTime,
            className: 'medio'
          })
        }
      }

      let dataSetGroups = new DataSet(groups)
      timeline.setGroups(dataSetGroups)
    },

    setItems (timeline) { // Crea los items de fondo (dia-noche) y los añade a la timeline junto con los items de los medios
      if (this.emergenciaSelected) {
      // Get lista de items
        let items = this.getItems()

        // Items dia/noche
        let dia = this.$date.now().subtract(++this.numDiasPasado, 'days')
        let diasTotales = this.numDiasPasado + this.numDiasFuturo
        for (let i = 0; i < diasTotales; i++) {
          let prevSunset = this.$date.parseDate(getSunset(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, dia.toDate()))
          dia = dia.add(1, 'days')
          let sunrise = this.$date.parseDate(getSunrise(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, dia.toDate()))
          let sunset = this.$date.parseDate(getSunset(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, dia.toDate()))

          // Item background noche (antes)
          let item = {
            id: 'noche' + i,
            content: 'Noche',
            start: prevSunset,
            end: sunrise,
            type: 'background',
            className: 'background-noche'
          }
          items.push(item)

          // Item background dia
          item = {
            id: 'dia' + i,
            content: 'Día',
            start: sunrise,
            end: sunset,
            type: 'background',
            className: 'background-dia'
          }
          items.push(item)
        }

        items = PH.convertDatesToUNIX(items)

        let dataSetItems = new DataSet(items)
        timeline.setItems(dataSetItems)

        // Fit timeline
        if (!this.firstTimeFit) { // Solo lo hace la primera vez
          this.firstTimeFit = true
          this.fitTimeline()
        }
      }
    },

    getItems () { // Crear los items de los medios de la timeline
      let items = []
      let item
      let tiempo

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let t = this.mediosAsignados[i]

        // Pinta un fondo verde porque la linea de INICIO DE JORNADA no llega hasta arriba y abajo (no muestra el title)
        // let item = {
        //   id: this.$uuid.createUUID(),
        //   value: t.MEDIO,
        //   group: t.ID_MEDIO,
        //   content: '  ',
        //   type: 'background',
        //   start: t.INICIO_JORNADA,
        //   end: t.INICIO_JORNADA,
        //   className: 'demora',
        //   title: 'Inicio jornada'
        // }
        // items.push(item)

        // Linea de inicio de jornada (muestra el title)
        // let item = {
        //   id: this.$uuid.createUUID(),
        //   value: t.MEDIO,
        //   group: t.ID_MEDIO,
        //   content: '  ',
        //   type: 'range',
        //   start: t.INICIO_JORNADA,
        //   end: t.INICIO_JORNADA,
        //   style: 'border: solid 1px #43d400!important; border-top: solid 5px #43d400!important; border-bottom: solid 5px #43d400!important;',
        //   className: 'demora',
        //   title: 'Inicio jornada'
        // }
        // items.push(item)

        // TODO: Crea linea hasta arriba, lo comentado arriba da fallos random (Toda la barra de color)
        // this.createInicioJornada(t)

        if (t.HORA_MOVILIZACION) {
          // Ida a incendio
          tiempo = t.HORA_LLEGADA_INC ? t.HORA_LLEGADA_INC : this.$date.now()// t.HORA_LLEGADA_INC ? t.HORA_LLEGADA_INC.diff(t.HORA_MOVILIZACION, 'minutes') : this.$date.now().diff(t.HORA_MOVILIZACION, 'minutes')
          item = {
            id: this.$uuid.createUUID(),
            value: t.MEDIO,
            group: t.ID_MEDIO,
            content: 'Ida',
            start: t.HORA_MOVILIZACION,
            end: t.HORA_LLEGADA_INC ? t.HORA_LLEGADA_INC : this.$date.now(),
            className: 'transito',
            title: (tiempo.$H < 9 ? '0' : '') + tiempo.$H + ':' + (tiempo.$m < 9 ? '0' : '') + tiempo.$m + ':' + (tiempo.$s < 9 ? '0' : '') + tiempo.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
          }
          items.push(item)

          if (t.HORA_LLEGADA_INC) { // FECHA_MAX_ACTUACION
            // Incendio
            tiempo = t.FECHA_SALIDA_INC.diff(t.HORA_LLEGADA_INC, 'minutes') // FECHA_MAX_ACTUACION
            item = {
              id: this.$uuid.createUUID(),
              value: t.MEDIO,
              group: t.ID_MEDIO,
              content: 'Incendio',
              start: t.HORA_LLEGADA_INC,
              end: t.FECHA_SALIDA_INC ? t.FECHA_SALIDA_INC : this.$date.now(), // FECHA_MAX_ACTUACION
              className: 'incendio',
              title: (t.FECHA_SALIDA_INC.$H < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$H + ':' + (t.FECHA_SALIDA_INC.$m < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$m + ':' + (t.FECHA_SALIDA_INC.$s < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
            }
            items.push(item)

            if (t.FECHA_SALIDA_INC) {
              // Vuelta a base
              tiempo = t.FECHA_LLEGADA_BASE.diff(t.FECHA_SALIDA_INC, 'minutes') // FECHA_MAX_ACTUACION
              item = {
                id: this.$uuid.createUUID(),
                value: t.MEDIO,
                group: t.ID_MEDIO,
                content: 'Vuelta',
                start: t.FECHA_SALIDA_INC,
                end: t.FECHA_LLEGADA_BASE ? t.FECHA_LLEGADA_BASE : this.$date.now(), // FECHA_MAX_ACTUACION
                className: 'transito',
                title: (t.FECHA_LLEGADA_BASE.$H < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$H + ':' + (t.FECHA_LLEGADA_BASE.$m < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$m + ':' + (t.FECHA_LLEGADA_BASE.$s < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
              }
              items.push(item)

              // Descanso
              if (t.FECHA_LLEGADA_BASE) {
                tiempo = t.FECHA_FIN_DESCANSO.diff(t.FECHA_LLEGADA_BASE, 'minutes')
                item = {
                  id: this.$uuid.createUUID(),
                  value: t.MEDIO,
                  group: t.ID_MEDIO,
                  content: 'Descanso',
                  start: t.FECHA_LLEGADA_BASE,
                  end: t.FECHA_FIN_DESCANSO ? t.FECHA_FIN_DESCANSO : this.$date.now(),
                  className: 'descanso',
                  title: (t.FECHA_FIN_DESCANSO.$H < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$H + ':' + (t.FECHA_FIN_DESCANSO.$m < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$m + ':' + (t.FECHA_FIN_DESCANSO.$s < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
                }
                items.push(item)
              }
            } else {
              // items.pop() // Sacar los elementos de este periodo de este medio
              // items.pop()
            }
          }
        }

        if (this.getNumActuacionesMedio(t) === t.ACTUACION) {
          let t2 = Object.assign({}, t)
          let itemsPlanif = this.getPlanifItem(t2)

          for (let j = 0; j < itemsPlanif.length; j++) {
            items.push(itemsPlanif[j])
          }
        }
      }

      return items
    },

    getPlanifItem (t) {
      let items = []
      let item
      let tiempo

      // Horas de viaje (IDA - VUELTA)
      let horaLlegadaInc = t.HORA_LLEGADA_INC ? t.HORA_LLEGADA_INC.diff(t.HORA_MOVILIZACION, 'hours', true) : this.$date.now().diff(t.INICIO_JORNADA, 'hours', true)

      // Horas de incendio = TIEMPO_OPERATIVO - IDA*2
      let horasEmergencia = t.TIEMPO_OPERATIVO - 2 * horaLlegadaInc

      // Caso no incendio. (Va pa na)
      if (horasEmergencia < 0) {
        horasEmergencia = 0
        item = {
          id: this.$uuid.createUUID(),
          value: t.MEDIO,
          group: t.ID_MEDIO,
          content: 'No llega incendio',
          start: t.FECHA_FIN_DESCANSO > this.$date.now() ? t.FECHA_FIN_DESCANSO : this.$date.now(),
          end: t.FECHA_FIN_DESCANSO > this.$date.now() ? t.FECHA_FIN_DESCANSO.add(17, 'hours') : this.$date.now().add(17, 'hours'), // FECHA_MAX_ACTUACION
          className: 'demora',
          title: (t.FECHA_SALIDA_INC.$H < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$H + ':' + (t.FECHA_SALIDA_INC.$m < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$m + ':' + (t.FECHA_SALIDA_INC.$s < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
        }
        items.push(item)

        item = {
          id: this.$uuid.createUUID(),
          value: t.MEDIO,
          group: t.ID_MEDIO,
          content: 'Descanso',
          start: t.FECHA_FIN_DESCANSO > this.$date.now() ? t.FECHA_FIN_DESCANSO.add(17, 'hours') : this.$date.now().add(17, 'hours'),
          end: t.FECHA_FIN_DESCANSO > this.$date.now() ? t.FECHA_FIN_DESCANSO.add(17, 'hours').add(t.TIEMPO_DESCANSO, 'hours') : this.$date.now().add(17, 'hours').add(t.TIEMPO_DESCANSO, 'hours'),
          className: 'descanso',
          title: (t.FECHA_FIN_DESCANSO.$H < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$H + ':' + (t.FECHA_FIN_DESCANSO.$m < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$m + ':' + (t.FECHA_FIN_DESCANSO.$s < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
        }
        items.push(item)
        return items
      }

      t.INICIO_JORNADA = t.FECHA_FIN_DESCANSO > this.$date.now() ? t.FECHA_FIN_DESCANSO : this.$date.now()
      t.HORA_LLEGADA_INC = t.INICIO_JORNADA.add(horaLlegadaInc, 'hours')

      // Ida a incendio
      tiempo = t.HORA_LLEGADA_INC ? t.HORA_LLEGADA_INC : this.$date.now()
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Ida',
        start: t.INICIO_JORNADA,
        end: t.HORA_LLEGADA_INC,
        className: 'demora',
        title: (tiempo.$H < 9 ? '0' : '') + tiempo.$H + ':' + (tiempo.$m < 9 ? '0' : '') + tiempo.$m + ':' + (tiempo.$s < 9 ? '0' : '') + tiempo.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // Incendio
      tiempo = t.FECHA_SALIDA_INC.diff(t.HORA_LLEGADA_INC, 'minutes') // FECHA_MAX_ACTUACION

      // console.log(horasIncendio)
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Incendio',
        start: t.HORA_LLEGADA_INC,
        end: t.HORA_LLEGADA_INC.add(horasEmergencia, 'hours'), // FECHA_MAX_ACTUACION
        className: 'incendio',
        title: (t.FECHA_SALIDA_INC.$H < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$H + ':' + (t.FECHA_SALIDA_INC.$m < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$m + ':' + (t.FECHA_SALIDA_INC.$s < 9 ? '0' : '') + t.FECHA_SALIDA_INC.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // Vuelta a base
      tiempo = t.FECHA_LLEGADA_BASE.diff(t.FECHA_SALIDA_INC, 'minutes') // FECHA_MAX_ACTUACION
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Vuelta',
        start: t.HORA_LLEGADA_INC.add(horasEmergencia, 'hours'),
        end: t.HORA_LLEGADA_INC.add(horasEmergencia, 'hours').add(horaLlegadaInc, 'hours'), // FECHA_MAX_ACTUACION
        className: 'demora',
        title: (t.FECHA_LLEGADA_BASE.$H < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$H + ':' + (t.FECHA_LLEGADA_BASE.$m < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$m + ':' + (t.FECHA_LLEGADA_BASE.$s < 9 ? '0' : '') + t.FECHA_LLEGADA_BASE.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // Descanso
      tiempo = t.FECHA_FIN_DESCANSO.diff(t.FECHA_LLEGADA_BASE, 'minutes')
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Descanso',
        start: t.HORA_LLEGADA_INC.add(horasEmergencia, 'hours').add(horaLlegadaInc, 'hours'),
        end: t.HORA_LLEGADA_INC.add(horasEmergencia, 'hours').add(horaLlegadaInc, 'hours').add(t.TIEMPO_DESCANSO, 'hours'),
        className: 'descanso',
        title: (t.FECHA_FIN_DESCANSO.$H < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$H + ':' + (t.FECHA_FIN_DESCANSO.$m < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$m + ':' + (t.FECHA_FIN_DESCANSO.$s < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      return items
    },

    createInicioJornada (t) {
      let id = t.ID_MEDIO

      this.timeline.addCustomTime(new Date(t.INICIO_JORNADA), id, false)
      // this.timeline.setCustomTimeMarker(t.MEDIO, id, false)
    },

    getNumActuacionesMedio (t) {
      return this.mediosAsignados.filter(x => x.ID_MEDIO === t.ID_MEDIO).length
    },

    optionsTimeLine () {
      let orto = getSunrise(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, new Date())
      let ocaso = getSunset(this.emergenciaSelected.LATITUD, this.emergenciaSelected.LONGITUD, new Date())

      let minTimeLine = this.$date.parseDate(orto).subtract(this.numDiasPasado, 'days')
      let maxTimeLine = this.$date.parseDate(ocaso).add(this.numDiasFuturo, 'days')

      let initTimeLine = minTimeLine // this.$date.now().subtract(1, 'days')
      let endTimeLine = this.$date.now().add(12, 'hours')

      let options = {
        groupOrder: (a, b) => {
          return a.initTime < b.initTime ? -1 : 1
        },

        stack: false,
        showTooltips: true,
        tooltip: {
          followMouse: true,
          overflowMethod: 'cap'
        },
        orientation: 'top',
        zoomMin: 100000,
        maxHeight: 'calc(70vh - 100px)',
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        min: minTimeLine.valueOf(),
        max: maxTimeLine.valueOf(),
        start: initTimeLine.valueOf(),
        end: endTimeLine.valueOf()
        // editable: true,
        // stackSubgroups: false // TODO: Edit custom to guapo.
      }
      return options
    },

    fitTimeline () {
      if (this.mediosAsignados.length > 0) {
        this.timeline.fit()
      }
    },

    /* Filtros */
    fechahora (value) {
      return value ? this.$date.formatDate(value, 'DD/MM/YYYY HH:mm') : null
    },
    hhmm (value) {
      return value ? this.$date.formatDate(value, 'HH:mm') : null
    },
    horasmin (value) {
      return value ? Math.floor(value / 60) + 'h ' + value % 60 + 'min' : null
    },
    min (value) {
      let min = null
      if (value) {
        const hhmm = value.split(':')
        min = hhmm[0] * 60 + parseInt(hhmm[1])
      }
      return min
    }
  },

  beforeDestroy () {
    if (this.timeline) {
      this.timeline = null
    }

    clearTimeout(this.timeout)
    clearInterval(this.timer)
  },

  mounted () {
    this.init()
  }
}
</script>

<style scoped>
  @import '../../../node_modules/vis-timeline/dist/vis-timeline-graph2d.min.css'; /*TODO: Nose si hace algo*/

  /* .tabs__items{
    height: 63vh;
    overflow-y: auto;
  } */

  /* Colores TODO: repetido en MMTTT */
  ::v-deep .vis-item.transito  { background-color: #FFC400; }
  ::v-deep .vis-item.incendio  { background-color: #FF0000; }
  ::v-deep .vis-item.descanso { background-color: #FFFF00; }
  ::v-deep .vis-item.demora { background-color: #00AABB; }
  ::v-deep .vis-item.background-dia { background-color: rgba(170, 255, 248, 0.541); }
  ::v-deep .vis-item.background-noche { background-color: rgba(235, 170, 255, 0.329); }
  ::v-deep .vis-item.inicioJornadaLinea { background-color: #43d400; border-right: solid 3px #43d400;}

  ::v-deep .vis-timeline, .vis-bottom {
    background: white;
  }

  ::v-deep .vis-item.transito-plan  { background-color: rgba(255, 196, 0, 0.466); }
  ::v-deep .vis-item.incendio-plan  { background-color: rgba(255, 0, 0, 0.479); }
  ::v-deep .vis-item.descanso-plan { background-color: rgba(255, 255, 0, 0.479); }
  ::v-deep .vis-item.demora-plan { background-color: rgba(0, 171, 187, 0.521); }

</style>
