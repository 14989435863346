<template>
  <v-container id="incendioSelector" :class="{'mobile': $vuetify.breakpoint.xs}">
    <v-row dense>
      <v-col>
        <v-select
          v-model="selectedEmergencia"
          :items="emergenciasOrder"
          item-text="nombreEmergencia"
          item-value="ID_EMERGENCIA"
          label="Selecciona una emergencia"
          outlined
          dense
          @input="onSelectCoordinates"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    selectedEmergencia: null
  }),

  computed: {
    ...mapGetters('emergencia', ['emergencias']),

    emergenciasOrder () {
      return this.orderEmergencias()
    }
  },

  methods: {
    // Ordena los incendios según el estado y la fecha
    orderEmergencias () {
      let orderEmergencias = JSON.parse(JSON.stringify(this.emergencias.filter(emergencia => !emergencia.OCULTO)))
      orderEmergencias.sort((a, b) => {
        let aEstado = a.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE
        let bEstado = b.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return orderEmergencias.map(emergencia => ({
        ...emergencia,
        nombreEmergencia: emergencia.INCENDIO ? emergencia.INCENDIO.NOMBRE || emergencia.LOCALIDAD : emergencia.LOCALIDAD,
        latitudEmergencia: emergencia.LATITUD,
        longitudEmergencia: emergencia.LONGITUD
      }))
    },

    onSelectCoordinates () {
      const selected = this.emergenciasOrder.find(
        emergencia => emergencia.ID_EMERGENCIA === this.selectedEmergencia
      )

      if (selected) {
        const { latitudEmergencia, longitudEmergencia } = selected
        this.$emit('selectCoordinates', latitudEmergencia, longitudEmergencia)
      } else {
        console.warn('No se encontró el incendio seleccionado.')
      }
    },
    clearSelection () {
      this.selectedEmergencia = null
      this.$emit('selectCoordinates', null, null)
    }

  }
}
</script>
