<template>
  <div class="fill-height">
    <vx-left-menu :show="showLeftMenu" :idEmergenciaSelected="idEmergenciaSelected" @exit="hideLeftMenu" @marcoCaptura="marcoCaptura" />

    <!-- <v-content fill-height> -->
    <vx-map :showIncendiosCards="showIncendiosCards" :showButtonsMap="showButtonsMap" :showToolbar="showToolbar" :showWidget="showWidget" @selectEmergencia="selectEmergencia" />
    <!-- </v-content> -->

    <vx-marco-captura :show="showMarcoCaptura" :data="data" @exit="hideMarcoCaptura" />
  </div>
</template>

<script>
import Map from './Map'
import LeftMenu from './leftmenu/LeftMenu'
import MarcoCaptura from './MarcoCaptura'

export default {
  name: 'Map',

  components: {
    'vx-left-menu': LeftMenu,
    'vx-map': Map,
    'vx-marco-captura': MarcoCaptura
  },

  data: () => ({
    showLeftMenu: false,

    idEmergenciaSelected: null,
    showIncendiosCards: true,
    showButtonsMap: true,
    showToolbar: true,
    showWidget: true,
    drawer: true,
    incendio: {},

    showMarcoCaptura: false,
    data: {}
  }),

  methods: {
    selectEmergencia (idEmergencia) {
      this.showLeftMenu = true
      this.idEmergenciaSelected = idEmergencia
      this.showIncendiosCards = false
    },

    hideLeftMenu () {
      this.showLeftMenu = false
      this.idEmergenciaSelected = null
      this.showIncendiosCards = true
    },

    marcoCaptura (data) {
      this.showIncendiosCards = false
      this.showLeftMenu = false
      this.showButtonsMap = false
      this.showToolbar = false
      this.showWidget = false
      this.data = data
      this.showMarcoCaptura = true
    },

    hideMarcoCaptura () {
      this.showLeftMenu = true
      this.showButtonsMap = true
      this.showToolbar = true
      this.showWidget = true
      this.showMarcoCaptura = false
    }
  }
}
</script>

<style scoped>
</style>
