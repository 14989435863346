<template>
  <v-dialog v-model="show" persistent max-width="750px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="nombre" label="Nombre *" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="apellidos" label="Apellidos *" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="alias" label="Alias" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="dni" label="DNI" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="identificador" label="Identificador" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="login" label="Login" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="telefono" label="Teléfono" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="correo" label="Correo" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="dispositivoSelected" :items="dispositivos" item-text="CODIGO_EXTERNO" hint="ISSI" return-object persistent-hint clearable>
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title :style="{ color: getColor(data.item) }">
                        {{ data.item.CODIGO_EXTERNO }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="telefono2" label="Teléfono 2" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="correo2" label="Correo 2" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="dispositivoPosSelected" :items="dispositivosPos" item-text="CODIGO_EXTERNO" hint="ISSI_POS" :disabled="!dispositivoSelected" return-object persistent-hint clearable>
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title :style="{ color: getColor(data.item) }">
                        {{ data.item.CODIGO_EXTERNO }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tituloProfesionalSelected"
                  return-object
                  :items="tituloProfesionalItems()"
                  :item-text="(titulo) => {return titulo.TITULO_PROFESIONAL}"
                  item-title="TITULO_PROFESIONAL"
                  item-value="ID_TITULO_PROFESIONAL"
                  label="Título profesional *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="responsableSelected"
                  return-object
                  :items="responsableItems()"
                  :item-text="(responsable) => {return responsable.ORGANISMO}"
                  item-title="ORGANISMO"
                  item-value="ID_ORGANISMO"
                  label="Responsable *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tipoPersonalSelected"
                  return-object
                  :items="tipoPersonalItems()"
                  :item-text="(tipo) => {return tipo.PERSONAL_TIPO}"
                  item-title="PERSONAL_TIPO"
                  item-value="ID_PERSONAL_TIPO"
                  label="Tipo de personal *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="baseSelected"
                  return-object
                  :items="basesItems()"
                  :item-text="(base) => {return base.NOMBRE}"
                  item-title="NOMBRE"
                  item-value="ID_INFRAESTRUCTURA"
                  label="Base *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tiposSelected"
                  return-object
                  :items="tiposMediosItems()"
                  :item-text="(tipo) => {return tipo.TIPO}"
                  item-title="TIPO"
                  item-value="ID_TIPO"
                  label="Medios asociados"
                  closable-chips
                  multiple
                  clearable
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="fu" label="FU" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-textarea rows="1" v-model="descripcion" label="Descripción" outlined dense />
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="isFuncionario" label="Funcionario" outlined dense />
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="isDespachable" label="Despachable" outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <span v-show="showAvisoDispositivo" style="color:red">El dispositivo está en uso, si guarda se dará de baja del anterior dueño.</span>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar </v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inflate } from 'pdfmake/build/pdfmake'
import { mapGetters } from 'vuex'
import constants from '@/helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedPersonal: Object
  },
  data () {
    return {
      nombre: null,
      apellidos: null,
      alias: null,
      telefono: null,
      telefono2: null,
      dispositivoSelected: null,
      dispositivoPosSelected: null,
      dni: null,
      login: null,
      correo: null,
      correo2: null,
      identificador: null,
      tituloProfesionalSelected: null,
      responsableSelected: null,
      baseSelected: null,
      tipoPersonalSelected: null,
      fu: null,
      descripcion: null,
      isFuncionario: false,
      isDespachable: false,
      isValid: false,
      tiposSelected: []
    }
  },
  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),
    ...mapGetters('personalMedioTipo', [
      'personalMedioTipo'
    ]),
    ...mapGetters('infraestructura', [
      'infraestructuras', 'tipos'
    ]),
    ...mapGetters('tituloProfesional', [
      'titulosProfesionales'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),
    ...mapGetters('dispositivo', [
      'dispositivos'
    ]),
    ...mapGetters('personalTipo', [
      'tiposPersonal'
    ]),
    ...mapGetters('estadosMedio', [
      'estados'
    ]),

    showAvisoDispositivo () {
      return this.isDispositivoOwned(this.dispositivoSelected) || this.isDispositivoOwned(this.dispositivoPosSelected)
    },

    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },
    dispositivosPos () {
      return this.dispositivoSelected ? this.dispositivos.filter(x => this.dispositivoSelected.ID_GEO_DISPOSITIVO !== x.ID_GEO_DISPOSITIVO) : this.dispositivos
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.nombre = this.editedPersonal.NOMBRE
          this.apellidos = this.editedPersonal.APELLIDOS
          this.alias = this.editedPersonal.ALIAS
          this.telefono = this.editedPersonal.TELEFONO
          this.dispositivoSelected = this.editedPersonal.GEO_MEDIO_DISPOSITIVOS?.length
            ? this.dispositivos.find(x => x.ID_GEO_DISPOSITIVO === this.editedPersonal.GEO_MEDIO_DISPOSITIVOS[0]?.ID_GEO_DISPOSITIVO)
            : null

          this.dispositivoPosSelected = this.editedPersonal.GEO_MEDIO_DISPOSITIVOS?.length > 1
            ? this.dispositivos.find(x => x.ID_GEO_DISPOSITIVO === this.editedPersonal.GEO_MEDIO_DISPOSITIVOS[1]?.ID_GEO_DISPOSITIVO)
            : null
          this.identificador = this.editedPersonal.IDENTIFICADOR
          this.dni = this.editedPersonal.DNI
          this.login = this.editedPersonal.LOGIN
          this.telefono2 = this.editedPersonal.TELEFONO2
          this.correo = this.editedPersonal.CORREO
          this.correo2 = this.editedPersonal.CORREO2
          this.tituloProfesionalSelected = this.editedPersonal.TITULO_PROFESIONAL
          this.responsableSelected = this.editedPersonal.ORGANISMO
          this.baseSelected = this.editedPersonal.INFRAESTRUCTURA
          this.tipoPersonalSelected = this.editedPersonal.PERSONAL_TIPO
          this.fu = this.editedPersonal.FU
          this.descripcion = this.editedPersonal.DESCRIPCION
          this.isFuncionario = this.editedPersonal.FUNCIONARIO
          this.isDespachable = this.editedPersonal.DESPACHABLE
          this.tiposSelected = this.personalMedioTipo.filter(y => y.ID_PERSONAL === this.editedPersonal.ID_PERSONAL).map(x => x.MEDIO_TIPO)
        } else {
          this.isFuncionario = false
          this.isDespachable = false
        }
      }
    }

  },
  methods: {
    isDispositivoOwned (dispositivo) {
      if (!dispositivo || !dispositivo.GEO_MEDIO_DISPOSITIVOS) return false

      const isDispositivoInUse = dispositivo.GEO_MEDIO_DISPOSITIVOS.some(
        x => !x.BAJA && (x.ID_MEDIO || x.ID_PERSONAL)
      )
      const isDispositivoOwnByMedio = this.editedPersonal && dispositivo &&
    this.editedPersonal.GEO_MEDIO_DISPOSITIVOS?.some(y => y.ID_GEO_DISPOSITIVO === dispositivo.ID_GEO_DISPOSITIVO)

      return isDispositivoInUse && !isDispositivoOwnByMedio
    },
    getColor (item) {
      if (this.isDispositivoOwned(item)) {
        return '#e5be01'
      }
      return 'black'
    },
    tituloProfesionalItems () {
      let titulosProf = JSON.parse(JSON.stringify(this.titulosProfesionales))
      titulosProf = titulosProf.sort((a, b) => constants.collator.compare(a.TITULO_PROFESIONAL, b.TITULO_PROFESIONAL))
      return titulosProf
    },
    responsableItems () {
      let responsables = JSON.parse(JSON.stringify(this.organismos))
      responsables = responsables.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
      return responsables
    },
    basesItems () {
      // to do after fixed del fetch de infraestructuras
      // let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => this.isBase(infra))))
      let tiposBasesIds = this.tipos.filter(tipoInfra => this.isBase(tipoInfra)).map(tipoInfra => tipoInfra.ID_INFRAESTRUCTURA_TIPO)
      let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => tiposBasesIds.includes(infra.ID_INFRAESTRUCTURA_TIPO))))
      bases = bases.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
      return bases
    },
    tipoPersonalItems () {
      let tiposPersonales = JSON.parse(JSON.stringify(this.tiposPersonal))
      tiposPersonales = tiposPersonales.sort((a, b) => constants.collator.compare(a.PERSONAL_TIPO, b.PERSONAL_TIPO))
      return tiposPersonales
    },
    tiposMediosItems () {
      let tiposMedioItems = JSON.parse(JSON.stringify(this.tiposMedio))
      tiposMedioItems = tiposMedioItems.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
      return tiposMedioItems
    },
    isBase (infraestructuraTipo) {
      let infraTipo = infraestructuraTipo.NOMBRE
      if (infraTipo === 'Centro Comarcal' || infraTipo === 'Consejeria' || infraTipo === 'Base Medios Helitransportados' || infraTipo === 'Base Medios Terrestres' ||
        infraTipo === 'Sede' || infraTipo === 'Oficina') {
        return true
      }
      return false
    },
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },
    aceptar () {
      let personal = this.isEdit ? JSON.parse(JSON.stringify(this.editedPersonal)) : {}
      if (!this.isEdit) {
        personal.ID_PERSONAL = this.$uuid.createUUID()
        let activo = this.estados.find(estado => estado.MEDIO_CLASE.NOMBRE === 'PERSONAL' && estado.NOMBRE === 'ACTIVO')
        personal.ID_MEDIO_ESTADO = activo.ID_MEDIO_ESTADO
        personal.MEDIO_ESTADO = activo
        personal.ACTIVO = true
        personal.BORRADO = false
      }

      personal.NOMBRE = this.nombre
      personal.APELLIDOS = this.apellidos
      personal.ALIAS = this.alias
      personal.TELEFONO = this.telefono
      personal.IDENTIFICADOR = this.identificador
      personal.DNI = this.dni
      personal.LOGIN = this.login
      personal.TELEFONO2 = this.telefono2
      personal.CORREO = this.correo
      personal.CORREO2 = this.correo2
      personal.TITULO_PROFESIONAL = this.tituloProfesionalSelected
      personal.ID_TITULO_PROFESIONAL = this.tituloProfesionalSelected.ID_TITULO_PROFESIONAL
      personal.ORGANISMO = this.responsableSelected
      personal.ID_ORGANISMO = this.responsableSelected.ID_ORGANISMO
      personal.INFRAESTRUCTURA = this.baseSelected
      personal.ID_INFRAESTRUCTURA = this.baseSelected.ID_INFRAESTRUCTURA
      personal.PERSONAL_TIPO = this.tipoPersonalSelected
      personal.ID_PERSONAL_TIPO = this.tipoPersonalSelected.ID_PERSONAL_TIPO
      personal.FU = this.fu
      personal.DESCRIPCION = this.descripcion
      personal.FUNCIONARIO = this.isFuncionario
      personal.DESPACHABLE = this.isDespachable

      // Seteamos el array de dispositivos para evitar referencias undefined
      if (!personal.GEO_MEDIO_DISPOSITIVOS) {
        personal.GEO_MEDIO_DISPOSITIVOS = []
      }

      // Si se ha rellenado el ISSI se lo asignamos en la posicion 0
      if (this.dispositivoSelected) {
        personal.GEO_MEDIO_DISPOSITIVOS[0] = {
          ...personal.GEO_MEDIO_DISPOSITIVOS[0],
          ID_GEO_DISPOSITIVO: this.dispositivoSelected.ID_GEO_DISPOSITIVO
        }
        personal.GEO_MEDIO_DISPOSITIVOS[0].GEO_DISPOSITIVO = {
          ...personal.GEO_MEDIO_DISPOSITIVOS[0].GEO_DISPOSITIVO,
          CODIGO_EXTERNO: this.dispositivoSelected.CODIGO_EXTERNO,
          ID_GEO_DISPOSITIVO: this.dispositivoSelected.ID_GEO_DISPOSITIVO
        }
      } else {
        personal.GEO_MEDIO_DISPOSITIVOS[0] = null
      }

      // Si se ha rellenado el ISSI Pos se lo asignamos en la posicion 1
      if (this.dispositivoPosSelected) {
        personal.GEO_MEDIO_DISPOSITIVOS[1] = {
          ...personal.GEO_MEDIO_DISPOSITIVOS[1],
          ID_GEO_DISPOSITIVO: this.dispositivoPosSelected.ID_GEO_DISPOSITIVO
        }
        personal.GEO_MEDIO_DISPOSITIVOS[1].GEO_DISPOSITIVO = {
          ...personal.GEO_MEDIO_DISPOSITIVOS[1].GEO_DISPOSITIVO,
          CODIGO_EXTERNO: this.dispositivoPosSelected.CODIGO_EXTERNO,
          ID_GEO_DISPOSITIVO: this.dispositivoPosSelected.ID_GEO_DISPOSITIVO
        }
      } else {
        personal.GEO_MEDIO_DISPOSITIVOS[1] = null
      }

      // Si se ha rellenado el ISSI pero no ISSI Pos, se cambia el ISSI Pos por el ISSI
      if (personal.GEO_MEDIO_DISPOSITIVOS[1] && !personal.GEO_MEDIO_DISPOSITIVOS[0]) {
        personal.GEO_MEDIO_DISPOSITIVOS[0] = personal.GEO_MEDIO_DISPOSITIVOS[1]
        personal.GEO_MEDIO_DISPOSITIVOS[1] = null
      }

      personal.TIPOS_MEDIOS = [...this.tiposSelected].map(tipo => ({
        ID_PERSONAL_MEDIO_TIPO: tipo.ID_PERSONAL_MEDIO_TIPO || this.$uuid.createUUID(),
        ID_PERSONAL: personal.ID_PERSONAL,
        ID_MEDIO_TIPO: tipo.ID_MEDIO_TIPO
      }))
      if (this.isEdit) {
        this.$emit('editPersonal', personal)
      } else {
        this.$emit('addPersonal', personal)
      }

      this.$refs.form.reset()
    }
  }
}
</script>

<style>

</style>
