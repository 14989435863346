<template>
  <v-container fluid class="pa-0 ma-0" width="100%">
    <v-row class="pa-0 ma-0">
      <v-col cols="2" class="pa-1 ma-0">
        <v-row class="pa-0 ma-0">
          <v-card tile height="100%" width="100%">
            <v-toolbar color="primaryLight" dark dense>
              <v-toolbar-title>Filtros</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0" cols="6">
                    <VueCtkDateTimePicker
                      v-model="fechaSelected" no-value-to-custom-elem only-date
                      color="#00759e" button-now-translation="Ahora"
                      format="DD/MM/YYYY"
                    >
                      <v-text-field v-model="fechaSelected" label="Fecha" readonly />
                    </VueCtkDateTimePicker>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="search"
                      label="Buscar personal"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row style="height: calc(50vh - 260px); overflow-y:auto;display: block;" />
              </v-container>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="pa-0 ma-0 mt-4">
          <vx-turno />
        </v-row>
      </v-col>
      <v-col cols="10" class="pa-1 ma-0">
        <v-card tile height="100%">
          <v-card-text class="pa-0 ma-0">
            <v-container class="pa-0 ma-0" style="background-color: #ffd587;" fluid>
              <v-row class="pa-0 ma-0" style="width: calc(100% - 10px);">
                <v-col cols="2" class="header-main">
                  <b>Personal</b>
                </v-col>
                <v-col cols="2" class="current-day">
                  <b>{{ fechaSelected }} Medio</b>
                </v-col>
                <v-col cols="1" class="current-day">
                  <b>Turno</b>
                </v-col>
                <v-col cols="2" class="current-day">
                  <b>Hora entrada / salida</b>
                </v-col>
                <v-col cols="2" class="next-day">
                  <b>{{ $date.parseDate(fechaSelected, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY') }} Medio</b>
                </v-col>
                <v-col cols="1" class="next-day">
                  <b>Turno</b>
                </v-col>
                <v-col cols="2" class="next-day">
                  <b>Hora entrada / salida</b>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pa-0 ma-0" style="height: calc(100vh - 175px); overflow-y:auto;overflow-x: hidden;" fluid>
              <v-row v-for="personal in listadoGuardiasFiltrado" :key="personal.ID_PERSONAL" class="pa-0 ma-0 personal-item" dense align="center">
                <v-col cols="2" class="text-body-2 text-no-wrap text-truncate personal-item">
                  {{ personal.NOMBRE_COMPLETO }}
                </v-col>
                <v-col cols="2" class="current-day-light">
                  <v-autocomplete :disabled="personal.MEDIO !== null" class="guardia-select text-body-2" v-model="personal.MEDIO_PERSONAL_CURRENT_DATE.ID_MEDIO" :items="filterByMedio(personal.MEDIO_PERSONAL_CURRENT_DATE, false)" clearable label="Sin asignar" item-text="MEDIO" item-value="ID_MEDIO" no-data-text="No hay medios asociados a esta persona" dense outlined single-line autocomplete hide-details @input="updateMedioOptionsCurrentDate()" @change="saveDate(personal.MEDIO_PERSONAL_CURRENT_DATE, false)" />
                </v-col>
                <v-col cols="1" class="current-day-light">
                  <v-autocomplete class="guardia-select text-body-2" v-model="personal.MEDIO_PERSONAL_CURRENT_DATE.ID_TURNO" :items="turnos" item-text="NOMBRE" item-value="ID_TURNO" dense outlined single-line autocomplete hide-details @change="saveDate(personal.MEDIO_PERSONAL_CURRENT_DATE, false)" />
                </v-col>
                <v-col cols="2" class="current-day-light">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0" align="center">
                      <v-col class="pa-0 ma-0 " cols="10">
                        <v-text-field disabled v-model="personal.MEDIO_PERSONAL_CURRENT_DATE.FECHA_HORA_ENTRADA_SALIDA" dense outlined single-line autocomplete hide-details />
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="red" @click="remove(personal.MEDIO_PERSONAL_CURRENT_DATE)" v-if="personal.MEDIO_PERSONAL_CURRENT_DATE.state === 'update'">mdi-delete</v-icon>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="black" @click="extend(personal.MEDIO_PERSONAL_CURRENT_DATE)" v-if="personal.MEDIO_PERSONAL_CURRENT_DATE.state === 'update'">mdi-arrow-expand-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="2" class="next-day-light">
                  <v-autocomplete :disabled="personal.MEDIO !== null" class="guardia-select text-body-2" v-model="personal.MEDIO_PERSONAL_NEXT_DATE.ID_MEDIO" :items="filterByMedio(personal.MEDIO_PERSONAL_NEXT_DATE, true)" clearable label="Sin asignar" item-text="MEDIO" item-value="ID_MEDIO" no-data-text="No hay medios asociados a esta persona" dense outlined single-line autocomplete hide-details @input="updateMedioOptionsNextDate()" @change="saveDate(personal.MEDIO_PERSONAL_NEXT_DATE, true)" />
                </v-col>
                <v-col cols="1" class="next-day-light">
                  <v-autocomplete class="guardia-select text-body-2" v-model="personal.MEDIO_PERSONAL_NEXT_DATE.ID_TURNO" :items="turnos" item-text="NOMBRE" item-value="ID_TURNO" dense outlined single-line autocomplete hide-details @change="saveDate(personal.MEDIO_PERSONAL_NEXT_DATE, true)" />
                </v-col>
                <v-col cols="2" class="next-day-light">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0" align="center">
                      <v-col class="pa-0 ma-0 " cols="10">
                        <v-text-field disabled v-model="personal.MEDIO_PERSONAL_NEXT_DATE.FECHA_HORA_ENTRADA_SALIDA" dense outlined single-line autocomplete hide-details />
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="red" @click="remove(personal.MEDIO_PERSONAL_NEXT_DATE)" v-if="personal.MEDIO_PERSONAL_NEXT_DATE.state === 'update'">mdi-delete</v-icon>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="black" @click="extend(personal.MEDIO_PERSONAL_NEXT_DATE)" v-if="personal.MEDIO_PERSONAL_NEXT_DATE.state === 'update'">mdi-arrow-expand-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <vx-dialog-extend-guardia
      :show="showExtend"
      :medioPersonalSelected="medioPersonalSelected"
      :isGuardiaPersonal="true"
      @cancelar="cancelExtendGuardia"
      @accept="acceptExtendGuardia"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Turnos from './Turnos.vue'
import DialogExtendGuardia from './DialogExtendGuardia.vue'

export default {
  components: {
    'vx-turno': Turnos,
    'vx-dialog-extend-guardia': DialogExtendGuardia
  },
  data () {
    return {
      fechaSelected: null,
      search: '',
      nuevoPersonal: { nombre: '', apellidos: '' },
      listadoGuardias: [],
      tiposMedioAll: [],
      tiposMedioFilter: [],
      mediosCopyCurrentDate: [],
      mediosCopyNextDate: [],
      medioPersonalSelected: null,
      showExtend: false
    }
  },
  watch: {
    fechaSelected () {
      this.updateUI()
    },
    medios () {
      if (this.mediosCopyCurrentDate.length === 0) {
        let medios = [...this.$store.getters['medio/medios']]
        // Solo es posible añadir medios habilitados y activos
        medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)
        let collator = new Intl.Collator('es', { sensitivity: 'base' })
        medios.sort((a, b) => collator.compare(a.MEDIO, b.MEDIO))
        this.mediosCopyCurrentDate = [...medios]
        this.mediosCopyNextDate = [...medios]
      }

      if (this.listadoGuardias.length === 0) {
        this.updateUI()
      }
    },
    medioPersonal: {
      handler () {
        this.updateUI()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('medio', [
      'medios', 'tiposMedio'
    ]),
    ...mapGetters('medioPersonal', [
      'turnos'
    ]),
    ...mapGetters('personalMedioTipo', [
      'personalMedioTipo'
    ]),
    ...mapGetters('personal', [
      'personales'
    ]),
    ...mapGetters('medioPersonal', [
      'medioPersonal'
    ]),
    listadoGuardiasFiltrado () {
      if (!this.search) {
        return this.listadoGuardias
      }
      return this.listadoGuardias.filter(personal =>
        personal.NOMBRE_COMPLETO.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  methods: {
    ...mapActions('medioPersonal', ['addMedioPersonal', 'removeMedioPersonal', 'getMediosPersonalesByDate']),

    async updateUI () {
      this.listadoGuardias = []
      const personales = this.personales.filter(personal => personal.ACTIVO === true)

      let medioPersonalCurrentDate = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY'))
      let medioPersonalNextDate = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY').add(1, 'days'))

      personales.forEach(personal => {
        let matchCurrentDate = medioPersonalCurrentDate.find(x => x.ID_PERSONAL === personal.ID_PERSONAL && !x.BORRADO)

        // personal.NOMBRE_COMPLETO = personal.NOMBRE + ' ' + personal.APELLIDOS

        personal.NOMBRE_COMPLETO = personal.APELLIDOS + ', ' + personal.NOMBRE // <- por si queremos que se muestren los apellidos primero

        // Añadir el apellido para ordenar
        personal.APELLIDO = personal.APELLIDOS

        if (matchCurrentDate) {
          personal.MEDIO_PERSONAL_CURRENT_DATE = {
            ID_MEDIO_PERSONAL: matchCurrentDate.ID_MEDIO_PERSONAL,
            ID_PERSONAL: matchCurrentDate.ID_PERSONAL,
            ID_MEDIO: matchCurrentDate.ID_MEDIO,
            FECHA: matchCurrentDate.FECHA,
            FECHA_ENTRADA: matchCurrentDate.FECHA_ENTRADA,
            FECHA_SALIDA: matchCurrentDate.FECHA_SALIDA,
            ID_TURNO: matchCurrentDate.ID_TURNO,
            FECHA_HORA_ENTRADA_SALIDA: this.getHoraEntradaSalidaFromDB(matchCurrentDate),
            state: 'update'
          }
        } else {
          personal.MEDIO_PERSONAL_CURRENT_DATE = {
            ID_PERSONAL: personal.ID_PERSONAL,
            ID_MEDIO: personal.MEDIO ? personal.MEDIO.ID_MEDIO : null, // Los personales asociados a un personal permanentemente tendran un ID_MEDIO aquí, si no, vendrá a null.
            FECHA: null,
            ID_TURNO: this.turnos.length === 1 ? this.turnos[0].ID_TURNO : null,
            FECHA_HORA_ENTRADA_SALIDA: this.turnos.length === 1 ? this.getHoraEntradaSalidaFromTurno(this.turnos[0].ID_TURNO) : '',
            state: 'insert'
          }
        }

        let matchNextDate = medioPersonalNextDate.find(x => x.ID_PERSONAL === personal.ID_PERSONAL && !x.BORRADO)
        if (matchNextDate) {
          personal.MEDIO_PERSONAL_NEXT_DATE = {
            ID_MEDIO_PERSONAL: matchNextDate.ID_MEDIO_PERSONAL,
            ID_PERSONAL: matchNextDate.ID_PERSONAL,
            ID_MEDIO: matchNextDate.ID_MEDIO,
            FECHA: matchNextDate.FECHA,
            FECHA_ENTRADA: matchNextDate.FECHA_ENTRADA,
            FECHA_SALIDA: matchNextDate.FECHA_SALIDA,
            ID_TURNO: matchNextDate.ID_TURNO,
            FECHA_HORA_ENTRADA_SALIDA: this.getHoraEntradaSalidaFromDB(matchNextDate),
            state: 'update'
          }
        } else {
          personal.MEDIO_PERSONAL_NEXT_DATE = {
            ID_PERSONAL: personal.ID_PERSONAL,
            ID_MEDIO: personal.MEDIO ? personal.MEDIO.ID_MEDIO : null, // Los personales asociados a un personal permanentemente tendran un ID_MEDIO aquí, si no, vendrá a null.
            FECHA: null,
            ID_TURNO: this.turnos.length === 1 ? this.turnos[0].ID_TURNO : null,
            FECHA_HORA_ENTRADA_SALIDA: this.turnos.length === 1 ? this.getHoraEntradaSalidaFromTurno(this.turnos[0].ID_TURNO) : '',
            state: 'insert'
          }
        }
        this.listadoGuardias.push(personal)
      })

      let collator = new Intl.Collator('es', { sensitivity: 'base' })
      this.listadoGuardias.sort((a, b) => collator.compare(a.APELLIDO, b.APELLIDO))

      this.updateMedioOptionsCurrentDate()
      this.updateMedioOptionsNextDate()
    },

    async saveDate (item, next) {
      const turnoSelected = this.turnos.find(x => x.ID_TURNO === item.ID_TURNO)
      let dateBaseMoment = this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY').startOf('day')
      dateBaseMoment = next ? dateBaseMoment.add(1, 'days') : dateBaseMoment

      const dateBaseFormatted = dateBaseMoment.format('DD/MM/YYYY')
      if (turnoSelected && item.ID_MEDIO) {
        item.ID_MEDIO_PERSONAL = item.ID_MEDIO_PERSONAL ? item.ID_MEDIO_PERSONAL : this.$uuid.createUUID()
        item.BORRADO = 0
        item.FECHA = dateBaseMoment
        item.FECHA_ENTRADA = this.$date.parseDate(dateBaseFormatted + ' ' + turnoSelected.HORA_ENTRADA)
        if (turnoSelected.HORA_SALIDA <= turnoSelected.HORA_ENTRADA) {
          let nextDay = dateBaseMoment.add(1, 'days').format('DD/MM/YYYY')
          item.FECHA_SALIDA = this.$date.parseDate(nextDay + ' ' + turnoSelected.HORA_SALIDA)
        } else {
          item.FECHA_SALIDA = this.$date.parseDate(dateBaseFormatted + ' ' + turnoSelected.HORA_SALIDA)
        }
        await this.addMedioPersonal([item])
        this.updateUI()
      }
    },

    async remove (MEDIO_PERSONAL) {
      await this.removeMedioPersonal(MEDIO_PERSONAL.ID_MEDIO_PERSONAL)
      MEDIO_PERSONAL.state = 'delete'
      this.updateUI()
    },
    async extend (MEDIO_PERSONAL) {
      this.medioPersonalSelected = MEDIO_PERSONAL
      this.showExtend = true
      this.updateUI()
    },
    cancelExtendGuardia () {
      this.medioPersonalSelected = null
      this.showExtend = false
    },
    async acceptExtendGuardia (mediosPersonales) {
      await this.addMedioPersonal(mediosPersonales)
      this.cancelExtendGuardia()
      this.updateUI()
    },

    filterByMedio (medioPersonal, next) {
      let itemsMedio = next ? this.mediosCopyNextDate : this.mediosCopyCurrentDate
      let itemPersTipo = this.personalMedioTipo.filter(x => (x.ID_PERSONAL === medioPersonal.ID_PERSONAL))
      return itemsMedio.filter(medio => itemPersTipo.some(x => x.ID_MEDIO_TIPO === medio.ID_MEDIO_TIPO))
    },

    updateMedioOptionsCurrentDate () {
      this.mediosCopyCurrentDate = this.mediosCopyCurrentDate.map(medio =>
        ({
          ID_MEDIO: medio.ID_MEDIO,
          MEDIO: medio.MEDIO,
          ID_MEDIO_TIPO: medio.ID_MEDIO_TIPO,
          // ID_PERSONAL: medio.ID_PERSONAL,
          disabled: this.listadoGuardiasFiltrado.find(x => x.MEDIO_PERSONAL_CURRENT_DATE.ID_MEDIO === medio.ID_MEDIO) !== undefined // this.selectedValues.includes(option)
        })

      )
    },
    updateMedioOptionsNextDate () {
      this.mediosCopyNextDate = this.mediosCopyNextDate.map(medio => ({
        ID_MEDIO: medio.ID_MEDIO,
        MEDIO: medio.MEDIO,
        ID_MEDIO_TIPO: medio.ID_MEDIO_TIPO,
        // ID_PERSONAL: medio.ID_PERSONAL,
        disabled: this.listadoGuardiasFiltrado.find(x => x.MEDIO_PERSONAL_NEXT_DATE.ID_MEDIO === medio.ID_MEDIO) !== undefined // this.selectedValues.includes(option)
      })
      )
    },
    getHoraEntradaSalidaFromDB (entry) {
      let day = this.$date.parseDate(entry.FECHA_ENTRADA).format('DD/MM/YYYY')
      let horaEntrada = this.$date.parseDate(entry.FECHA_ENTRADA).format('HH:mm')
      let horaSalida = this.$date.parseDate(entry.FECHA_SALIDA).format('HH:mm')
      return `${day} ${horaEntrada} - ${horaSalida}`
    },
    getHoraEntradaSalidaFromTurno (ID_TURNO) {
      const turnoSelected = this.turnos.find(x => x.ID_TURNO === ID_TURNO)
      let datetime = ''
      if (turnoSelected !== undefined) {
        let day = this.fechaSelected
        datetime = `${day} ${turnoSelected.HORA_ENTRADA} - ${turnoSelected.HORA_SALIDA}`
      }
      return datetime
    }
  },
  mounted () {
    this.showExtend = false
    this.fechaSelected = this.$date.now().startOf('day').format('DD/MM/YYYY')
    let medios = [...this.medios]
    // Solo es posible añadir medios habilitados y activos
    medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)
    let collator = new Intl.Collator('es', { sensitivity: 'base' })
    medios.sort((a, b) => collator.compare(a.MEDIO, b.MEDIO))
    this.mediosCopyCurrentDate = [...medios]
    this.mediosCopyNextDate = [...medios]
  }
}
</script>

<style scoped>
.guardia-select .v-input__control {
  width: 100%
}
>>>.v-list-item--disabled{
  color: #ff9c9c !important
}
>>>.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #00b116 !important
}

.header-main {
  background: var(--v-primaryLight-base);
  color: #FFFFFF;;
}
.personal-item {
  background-color: #e2f8ff
}
.current-day{
  background: #a7d8a7
}
.next-day{
  background: #ffd587
}
.current-day-light{
  background: #e7ffe7
}
.next-day-light{
  background: #fff3de
}
</style>
